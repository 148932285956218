import { View } from 'backbone.marionette';
import ResultChart from './result_chart';
import ProcessChart from './process_chart';
import recordTemplate from '../../../templates/student/exam_charts/panel.pug';

export default View.extend({
  tagName  : 'div',
  className: 'panel panel-default asm-exam-chart-panel',
  template : recordTemplate,

  ui: {
    resultChart : '.js-exam-result-chart',
    processChart: '.js-exam-process-chart'
  },

  onRender() {
    this.renderResultChart();
    this.renderProcessChart();
  },

  renderResultChart() {
    const $el = this.ui.resultChart;
    if (!this.ui.resultChart[0]) return;

    (new ResultChart({ el: $el })).render();
  },

  renderProcessChart() {
    const $el = this.ui.processChart;
    if (!this.ui.processChart[0]) return;

    (new ProcessChart({ el: $el, model: this.model })).render();
  }
});
