import { AppRouter }  from 'backbone.marionette';
import RestController from '../../../controllers/rest';
import View  from '../../../views/manage/duplication_minds';

const Controller =  RestController.extend({
  show() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/duplication/minds/:id': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
