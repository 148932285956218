// flashメッセージを通知する仕組み
// 画面遷移ではflashタグ、Ajax通信ではX-Flash-Messagesヘッダで受け渡すことが前提
import $ from 'jquery';
import _ from 'underscore';
import Utils from '../utils';

export default {
  ready() {
    this.bindAjax();
    this.notifyBody();
  },

  // Ajax通信の通知を表示する
  bindAjax() {
    $(document).ajaxComplete((event, xhr) => {
      const flash = $.parseJSON(xhr.getResponseHeader('X-Flash-Messages'));
      if (_.isEmpty(flash)) return;

      _.each(flash, (values) => {
        const type = values[0];
        const message = window.decodeURIComponent(values[1]);
        Utils.Notify.notify(type, message);
      });
    });
  },

  // 現在(body)の通知を表示する
  notifyBody() {
    _.each($('flash'), (el) => {
      const $el = $(el);
      Utils.Notify.notify($el.data('type'), $el.data('message'));
    });
  }
};
