var pug = require("!../../../../../../../../../shared/node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, Object, hasRatyTeacher, labels) {const nl2br = require('../../../../utils/nl2br').default;
const languages = I18n.t('frontend.backbone.templates.student.assessments');
if (hasRatyTeacher)
{
pug_html = pug_html + "\u003Cdiv class=\"asm-teacher-sign\"\u003E\u003Ci class=\"fa fa-lg fa-user\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = languages.raty_teacher) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ctable class=\"table table-bordered table-striped asm-assessment-rubrics asm-responsive-table\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth\u003E" + (pug.escape(null == (pug_interp = nl2br(languages.study_target)) ? "" : pug_interp)) + "\u003C\u002Fth\u003E\u003Cth class=\"asm-selector\"\u003E" + (pug.escape(null == (pug_interp = languages.assessment) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
// iterate Object.keys(labels).sort().reverse()
;(function(){
  var $$obj = Object.keys(labels).sort().reverse();
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var raty = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth class=\"asm-rubric\"\u003E" + (pug.escape(null == (pug_interp = labels[raty]) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var raty = $$obj[pug_index0];
pug_html = pug_html + "\u003Cth class=\"asm-rubric\"\u003E" + (pug.escape(null == (pug_interp = labels[raty]) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"Object" in locals_for_with?locals_for_with.Object:typeof Object!=="undefined"?Object:undefined,"hasRatyTeacher" in locals_for_with?locals_for_with.hasRatyTeacher:typeof hasRatyTeacher!=="undefined"?hasRatyTeacher:undefined,"labels" in locals_for_with?locals_for_with.labels:typeof labels!=="undefined"?labels:undefined));;return pug_html;};
module.exports = template;