import { View } from 'backbone.marionette';
import $ from 'jquery';
import Utils from '../../../../utils';

export default View.extend({
  template: false,
  t       : Utils.I18n.bind('views.shared.duplication'),

  ui: {
    openModalBtn: '.js-open-modal-for-input-year'
  },

  events: {
    'click @ui.openModalBtn': 'onClickOpenModalBtn'
  },

  onClickOpenModalBtn() {
    const url = this.ui.openModalBtn.data('url');
    Utils.Modal.prompt(
      this.t('.modal.title'),
      (year) => {
        if (year === null) return;
        $.ajax({ type: 'POST', data: { year: year }, url: url, dataType: 'json' })
            .done((data) => { window.location = data.url; });
      },
      { counter: 4, noEnter: true }
    );
  }
});
