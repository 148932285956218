import { View } from 'backbone.marionette';
import Mixins   from '../../../mixins';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns()
    });
  },

  columns() {
    return [
      {
        class: 'num text-right',
        data : 'num',
        width: 130
      },
      {
        class: 'status text-center',
        data : 'status',
        width: 50
      },
      {
        class: 'message',
        data : 'message'
      }
    ];
  }
}));
