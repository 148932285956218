import { View } from 'backbone.marionette';
import $ from 'jquery';
import _ from 'underscore';
import Utils from '../../../../utils';
import Mixins from '../../../mixins';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  mixins  : [
    Mixins.SearchByRadioFilter
  ],

  ui: {
    studentsArea  : '#js-students-area',
    attachmentArea: '#js-attachment-area',
    formConditions: '#js-form-conditions',
    searchType    : '#js-search-type input',
    searchFields  : '#js-search-conditions select',
    form          : 'form'
  },

  events: {
    'change @ui.searchType'  : 'loadStudents',
    'change @ui.searchFields': 'loadStudents',
    'submit @ui.form'        : 'onSubmitForm'
  },

  onRender() {
    const status = this.ui.attachmentArea.data('status');
    this.delayReloadAttachmentBy(status);
    this.loadStudents();

    this.$('.js-datepicker').datepicker({
      language : I18n.locale,
      autoclose: true,
      startDate: '-1m',
      endDate  : '+1m'
    });
  },

  // JOB待ちまたはJOB実行中ならば5秒後に読み込み
  delayReloadAttachmentBy(status) {
    if (status === 'waiting' || status === 'running') {
      setTimeout(_.bind(this.loadAttachment, this), 5000);
    }
  },

  loadStudents() {
    const $area = this.ui.studentsArea;
    const url = $area.data('url');
    const queryParams = this.ui.form.find(':input[name^=report_attachment]').serialize();

    $area.block();
    $.ajax({ url: `${url}?${queryParams}` })
     .done((data) => {
       $area.html(data.html);
       $area.unblock();

       // 0件の場合は生成ボタンを押せないようにする
       const studentCount = $area.find('.js-student-count').data('count');
       this.ui.attachmentArea.find('button').prop('disabled', studentCount === '0');
     });
  },

  loadAttachment() {
    const url = this.ui.attachmentArea.data('url');

    $.ajax({ url })
     .done((data) => {
       this.ui.attachmentArea.html(data.html);
       Utils.Radio.bind(this.ui.form);
       this.delayReloadAttachmentBy(data.status);
     });
  },

  onSubmitForm(e) {
    e.preventDefault();

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${this.ui.form.attr('action')}.json`, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      if (data.error_message) {
        Utils.Modal.alert(data.error_message);
      } else {
        this.ui.attachmentArea.html(data.html);
        Utils.Radio.bind(this.ui.form);
        this.delayReloadAttachmentBy(data.status);
      }
    };
    xhr.send(this.ui.form.serialize());
  }
}));
