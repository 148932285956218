import Index  from './syllabuses/index';
import New    from './syllabuses/new';
import Edit   from './syllabuses/edit';
import Show   from './syllabuses/show';
import Export from './syllabuses/export';
import Import from './syllabuses/import';
import Copy   from './syllabuses/copied_contents/index';

export default {
  Index,
  New,
  Edit,
  Show,
  Export,
  Import,
  Copy
};
