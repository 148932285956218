import { View } from 'backbone.marionette';
import _s from 'underscore.string';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    toggleDeadBtn: '#js-toggle-dead',
    deadComments : '.panel[dead]',
    commentsArea : '#js-comments-area'
  },

  events: {
    'click @ui.toggleDeadBtn': 'onToggleDead'
  },

  initialize(options) {
    this.targetableType = options.targetableType;
    this.targetableID = options.targetableID;
  },

  onRender() {
    this.scrollToBottom();
    this.syncOwner();
  },

  // 最新コメントである一番下までスクロールする
  scrollToBottom() {
    this.ui.commentsArea.animate({
      scrollTop: this.ui.commentsArea[0].scrollHeight
    }, 500, 'swing');
  },

  // 親画面は閲覧のみなので、削除済みや操作ボタンを削除した内容を反映させる
  syncOwner() {
    if (!window.opener) return;

    const openername = `${_s.classify(this.targetableType)}Comments`;
    if (!Utils.Opener.isOpened(openername)) return;

    const openerAreaID = `#js-${this.targetableType}-${this.targetableID}-comments-area`;
    const comments = this.ui.commentsArea.clone();
    comments.find('.panel[dead]').remove();
    comments.find('.btn').remove();

    Utils.Opener.get(openername).$(openerAreaID).html(comments.html());
  },

  onToggleDead() {
    this.ui.deadComments.toggleClass('revival');
  }
});
