import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils  from '../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.configs.edit'),

  ui: {
    gpTypeField : '#config_gp_type',
    gpTypeFields: '.js-gp-type-fields'
  },

  events: {
    'change @ui.gpTypeField': 'onChangeGpType'
  },

  onRender() {},

  onChangeGpType() {
    this.ui.gpTypeFields.addClass('hidden');
    this.ui.gpTypeFields.filter(`.${this.ui.gpTypeField.val()}`).removeClass('hidden');
  }
}));
