import { View } from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    ownTr: 'tr.js-feedback-own'
  },

  initialize(options) {
    this.surveyId = options.surveyId;
  },

  onRender() {
    this.syncOwner();
  },

  // 親画面に入力結果を反映させる
  syncOwner() {
    if (!window.opener) return;

    const openerName = 'LectureSurvey';
    if (!Utils.Opener.isOpened(openerName)) return;

    Utils.Opener.get(openerName)
      .$(`table#survey${this.surveyId}-feedbacks`)
      .find('tr.js-feedback-own')
      .html(this.ui.ownTr.html());
  }
});
