import $ from 'jquery';
import _ from 'underscore';
import 'select2';
import 'select2/dist/js/i18n/ja';

const defaultOptions = {
  theme   : 'bootstrap',
  language: I18n.locale
};

/*
 * Safari で Select2 を閉じたときに画面が勝手に下方にスクロールする問題を修正する。
 *
 * 参考: When selecting an element on Safari on MacOSMojave and iOS 12.1, page scrolls to bottom
 * https://github.com/select2/select2/issues/5427
 */
const applyMonkeyPatchForSafari = (select2) => {
  const self = select2.dropdown;

  if (!self._hideDropdown) throw new Error('関数 _hideDropdown は存在しません。');
  if (self._hideDropdown.monkeyPatched) return;

  self._hideDropdown = _(self._hideDropdown).wrap((origFn) => {
    if ($(document.activeElement).closest(self.$dropdownContainer).length > 0) {
      $(document.activeElement).blur();
    }

    return origFn.call(self);
  });

  self._hideDropdown.monkeyPatched = true;
};

export default {
  bind($el, options = {}) {
    const $select = $el.select2(_.extend(defaultOptions, options));
    applyMonkeyPatchForSafari($el.data('select2'));

    if (options.sortable && $select.attr('multiple')) {
      // ドラッグ&ドロップでソート順を入れ替える
      $select.next('.select2').find('ul.select2-selection__rendered')
        .sortable({
          containment: 'parent',
          stop(_e, ui) {
            ui.item.parent().children('[title]').each((_index, element) => {
              const title = $(element).attr('title');
              const original = $(`option:contains(${title})`, $select).first();
              original.detach();
              $select.append(original);
            });
            $select.change();
          }
        });
    }
  },

  ajax($el, options = {}) {
    $el.each((i, el) => { this._ajax($(el), options); });
  },

  _ajax($el, options) {
    this.bind($el, $.extend(true, {
      width: '100%',
      ajax : {
        url     : $el.data('url'),
        dataType: 'json',
        delay   : 500,
        cache   : true,
        data(params) {
          return {
            data: params.term,
            page: params.page || 1
          };
        },
        processResults(data, params) {
          params.page = params.page || 1; // eslint-disable-line no-param-reassign

          return {
            results   : data.results,
            pagination: {
              more: (params.page * 25) < data.total_count
            }
          };
        }
      }
    }, options));
  }
};
