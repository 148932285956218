import _ from 'underscore';
import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#js-grades-process-table',
  template: false,

  onRender() {
    this._removeUnusedColumns();
  },

  // 描画済みの summary テーブルから使われてない列を削除する
  _removeUnusedColumns() {
    _.each(['credit', 'gpa', 'ranking'], (type) => {
      _.each(['term', 'total', 'year'], (range) => {
        this._removeUnusedColumn(type, range);
      });
    });
  },

  _removeUnusedColumn(type, range) {
    const $columns       = this.$el.find(`td.${type}.${range}`);
    const $smallHeader   = this.$el.find(`th.${type}.${range}`);
    const $largeHeader   = this.$el.find(`th.${type}[colspan]`);
    const currentColspan = $largeHeader.attr('colspan');

    if (_($columns).every(el => { return el.innerText.trim() === ''; })) {
      $columns.remove();
      $smallHeader.remove();
      $largeHeader.attr('colspan', currentColspan - $smallHeader.length);
    }
  }
});
