import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.lr_licenses.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[1, 'asc']]
    });
  },

  columns() {
    return [
      {
        class: 'type',
        data : 'type',
        width: 60
      },
      {
        class: 'code',
        data : 'code',
        width: 80
      },
      {
        class   : 'name',
        data    : 'name',
        sortable: false
      },
      {
        class   : 'short_name',
        data    : 'short_name',
        sortable: false
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 50,
        render  : (value) => {
          return Utils.Render.editButton(this, value, '_self');
        }
      }
    ];
  }
}));
