import _ from 'underscore';
import { View } from 'backbone.marionette';
import Sortable from 'sortablejs';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.LocaleSwitch
  ],

  ui: {
    nodeList: '.js-node-list'
  },

  events: {
    'cocoon:after-insert @ui.nodeList': 'onAfterInsertNode',
    'cocoon:after-remove @ui.nodeList': 'onAfterRemoveNode'
  },

  initialize() {
    this.bindUIElements();
    this._setNos();
    this._applySortable();
  },

  onAfterInsertNode() {
    this.bindUIElements();
    this._setNos();
  },

  onAfterRemoveNode() {
    this._setNos();
  },

  _setNos() {
    const $rows = this.ui.nodeList.find('tr.nested-fields:visible');

    _($rows).each((row, i) => {
      const $row = this.$(row);
      $row.find('.js-hidden-no').val(i + 1);
    });
  },

  _applySortable() {
    if (this.ui.nodeList.length > 0) {
      Sortable.create(this.ui.nodeList.get(0), {
        handle  : 'th.js-no',
        onUpdate: () => {
          this._setNos();
        }
      });
    }
  }
});
