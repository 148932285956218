import { View } from 'backbone.marionette';
import MapView from './map';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.student.maps.index'),

  ui: {
    selectpicker  : '.selectpicker',
    checkbox      : ':checkbox',
    creditCheckbox: '.js-result-checkbox :checkbox',
    help          : '.js-help'
  },

  events: {
    'change @ui.selectpicker'  : 'changeMap',
    'change @ui.creditCheckbox': 'onChangeResultCheckbox',
    'click @ui.help'           : 'onClickHelp'
  },

  onRender() {
    (this.mapView = new MapView()).render();

    this.ui.selectpicker.selectpicker();
    this.changeMap();
  },

  changeMap() {
    const mapId = this.ui.selectpicker.val();
    if (!mapId) return;
    this.mapView.load(mapId);
    this.ui.checkbox.prop('checked', true);
  },

  onChangeResultCheckbox(e) {
    const checkbox = e.currentTarget;
    this.mapView.toggleCellsByResult(checkbox.value, checkbox.checked);
  },

  onClickHelp(e) {
    e.preventDefault();

    const introSteps = [
      [this.t('.help.credit_checkbox'), '.js-result-checkbox']
    ];
    if (this.$('.js-map-cell:visible').length > 0) {
      const localePath = Utils.Device.isPC() ? '.help.cell_pc' : '.help.cell_sp';
      introSteps.push([this.t(localePath), '.js-map-cell:visible']);
    }

    Utils.Introjs.play(introSteps, { showBullets: false });
  }
});
