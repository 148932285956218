import _ from 'underscore';
import { View } from 'backbone.marionette';
import Sortable from 'sortablejs';
import autosize from 'autosize';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#contents',
  template: false,

  ui: {
    localeBtn   : '.js-locale-btn',
    localeFields: '.js-locale-field',
    statusField : '.js-status-field',
    nodes       : '.js-sortable-nodes'
  },

  events: {
    'change @ui.localeBtn'  : 'onChangeLocale',
    'change @ui.statusField': 'onChangeStatus'
  },

  behaviors: [
    Behaviors.ScrollToError
  ],

  onRender() {
    this.bindUIElements();
    this._initSortable();
  },

  onChangeLocale() {
    this.ui.localeFields.toggleClass('hidden');
    autosize(this.ui.localeFields.find('textarea'));
  },

  onChangeStatus(e) {
    const $field = this.$(e.currentTarget);
    if (!$field.val()) return;

    $field.parents('td.js-status')
          .removeClass('status-disabled')
          .removeClass('status-authorized')
          .removeClass('status-enabled')
          .addClass(`status-${$field.val()}`);
  },

  _initSortable() {
    this._setNos();
    Sortable.create(this.ui.nodes.get(0), {
      handle  : 'th',
      onUpdate: () => {
        this._setNos();
      }
    });
  },

  _setNos() {
    _(this.ui.nodes.find('tr')).each((row, i) => {
      const $row = this.$(row);
      $row.find('.js-hidden-no').val(i + 1);
    });
  }
});
