import { View } from 'backbone.marionette';
import BarChart from './show/bar_chart';
import GpGpaChart from './show/gp_gpa_chart';
import GpGpaData from './show/gp_gpa_data';
import GpGpaTable from './show/gp_gpa_table';
import RatyData from './show/raty_data';
import RatyGpChart from './show/raty_gp_chart';
import RatyGpTable from './show/raty_gp_table';
import RatyGpaChart from './show/raty_gpa_chart';
import RatyGpaTable from './show/raty_gpa_table';
import ResourceTable from './show/resource_table';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    targetNumberIcon: '.asm-target-no-icon'
  },

  onRender() {
    this.renderStats();
    this.renderRaties();
    this.renderGpGpa();
  },

  renderStats() {
    const barChart = new BarChart().render();
    const resourceTable = new ResourceTable().render();
    new RatyGpTable().render();
    new RatyGpaTable().render();

    // 平均・標準偏差をロードしたら、元データ表に値を描画する
    resourceTable.listenTo(barChart, 'load:complete', resourceTable.renderTable);
  },

  renderRaties() {
    const ratyData = new RatyData().render();
    const ratyGpChart = new RatyGpChart().render();
    const ratyGpaChart = new RatyGpaChart().render();

    // チャートの色を凡例に反映する
    this.paintIcon(ratyGpChart.chartConfig.color);
    this.paintIcon(ratyGpaChart.chartConfig.color);

    // 分布チャートのポイントをクリックすると元データ表を検索する
    ratyData.listenTo(ratyGpChart, 'elementClick', ratyData.search);
    ratyData.listenTo(ratyGpaChart, 'elementClick', ratyData.search);
  },

  renderGpGpa() {
    new GpGpaTable().render();
    const gpGpaData = new GpGpaData().render();
    const gpGpaChart = new GpGpaChart().render();

    // 分布チャートのポイントをクリックすると元データ表を検索する
    gpGpaData.listenTo(gpGpaChart, 'elementClick', gpGpaData.search);
  },

  paintIcon(colors) {
    this.ui.targetNumberIcon.each((i, element) => {
      this.$el.find(element).css('color', colors[i]);
    });
  }
});
