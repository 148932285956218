import $ from 'jquery';

export default {
  isPC() {
    return navigator.userAgent.search(/iPad|iPhone|Android/) === -1;
  },
  isPcSize() {
    return $(window).width() >= 768;
  },
  isSmartSize() {
    return $(window).width() <= 767;
  },
  isTabletSize() {
    return $(window).width() >= 479 && $(window).width() <= 767;
  },
  isMobileSize() {
    return $(window).width() <= 478;
  }
};
