import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.lr.skill_answers.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'asc']]
    });
  },

  columns() {
    return [{
      data : 'display_code',
      class: 'code',
      width: 80
    }, {
      data    : 'name',
      class   : 'name',
      sortable: false
    }, {
      data : 'grade',
      class: 'grade text-right',
      width: 35
    }, {
      data : 'belong',
      class: 'belong'
    }, {
      data : 'status',
      class: 'status text-center',
      width: 50
    }, {
      class   : 'schedule',
      data    : 'schedule',
      sortable: false
    }, {
      class: 'inputted_at',
      data : 'inputted_at',
      width: 75
    }, {
      class: 'answer-rate',
      data : 'answer_rate',
      width: 70
    }, {
      class   : 'text-center',
      data    : 'show_path',
      sortable: false,
      width   : 50,
      render  : (value) => {
        return Utils.Render.showButton(this, value, '_blank');
      }
    }];
  }
}));
