import _ from 'underscore';
import Notify from '../utils/notify';

// 子ウィンドウから親ウィンドウのように
// ウィンドウをまたいでイベントを発火させたい際に使う
export default {
  set(name, object) {
    window.openerObjects = window.openerObjects || [];
    window.openerObjects[name] = object;
  },

  get(name) {
    if (window.gon.env === 'development' && _.isUndefined(window.opener.openerObjects)) {
      Notify.notify('devalert', 'OpenerUndefinedError: you need to set opener.');
    }
    return window.opener.openerObjects[name];
  },

  isOpened(name) {
    return !!window.opener.openerObjects[name];
  }
};
