import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.report_remarks.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableRemoveRows,
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  ui: {
    reportField    : 'select.js-report-field',
    csvBtn         : '#js-csv',
    removeBtn      : 'a.js-remove-btn',
    requiredTooltip: '.js-form-ransack div[data-toggle="tooltip"]'
  },

  events: {
    'change @ui.reportField': 'onChangeReportField'
  },

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc']]
    });
    Utils.Opener.set('ReportRemarkDatatable', this);
  },

  columns() {
    return [
      {
        data : 'display_code',
        class: 'code',
        width: 80
      }, {
        data    : 'name',
        class   : 'name',
        sortable: false
      }, {
        data    : 'grade',
        class   : 'grade text-right',
        sortable: false,
        width   : 30
      }, {
        data    : 'status',
        class   : 'status text-center',
        sortable: false,
        width   : 45
      }, {
        data    : 'report',
        class   : 'report',
        sortable: false
      }, {
        data    : 'title',
        class   : 'title',
        sortable: false
      }, {
        data    : 'body',
        class   : 'body',
        sortable: false
      }, {
        data    : 'sort_no',
        class   : 'sort_no text-right',
        sortable: false,
        width   : 20
      }
    ];
  },

  onChangeReportField() {
    this.reload();
    const reportId = this.ui.reportField.val();

    if (reportId) {
      this.ui.csvBtn.attr('href', this.ui.csvBtn.data('url').replace(':report_id', reportId));
      this.ui.csvBtn.attr('disabled', false);
      this.ui.removeBtn.attr('href', this.ui.removeBtn.data('url').replace(':report_id', reportId));
      this.ui.removeBtn.attr('disabled', false);
      this.ui.requiredTooltip.tooltip('disable');
    } else {
      this.ui.csvBtn.attr('href', '#');
      this.ui.csvBtn.attr('disabled', true);
      this.ui.removeBtn.attr('href', '#');
      this.ui.removeBtn.attr('disabled', true);
      this.ui.requiredTooltip.tooltip('enable');
    }
  }
}));
