import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/report_preview';
import AttachmentView from '../../views/shared/report/attachment';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  pdf() {
    (new AttachmentView()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/report_templates/:report_template_id/previews'                : 'index',
    ':tenant/manage/report_templates/:report_template_id/previews/:preview_id/pdf': 'pdf'
  },

  initialize() {
    this.controller = new Controller();
  }
});
