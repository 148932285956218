import { Object as MarionetteObject } from 'backbone.marionette';
import StepSchedule               from './manage/step_schedule';
import MindTargetSchedule         from './manage/mind_target_schedule';
import PlanTargetSchedule         from './manage/plan_target_schedule';
import GenericTargetSchedule      from './manage/generic_target_schedule';
import ActivityTag                from './manage/activity_tag';
import Allotment                  from './manage/allotment';
import SupportMemoCategory        from './manage/support_memo_category';
import Authority                  from './manage/authority';
import AuthorityChildren          from './manage/authority_children';
import Charge                     from './manage/charge';
import Export                     from './manage/export';
import Importer                   from './manage/importer';
import ImportHistory              from './manage/import_history';
import Map                        from './manage/map';
import Survey                     from './manage/survey';
import Mapping                    from './manage/mapping';
import App                        from './manage/app';
import Mind                       from './manage/mind';
import MindTarget                 from './manage/mind_target';
import Plan                       from './manage/plan';
import PlanTarget                 from './manage/plan_target';
import Generic                    from './manage/generic';
import GenericItem                from './manage/generic_item';
import GenericSkill               from './manage/generic_skill';
import GenericTarget              from './manage/generic_target';
import User                       from './manage/user';
import Exam                       from './manage/exam';
import ExamItem                   from './manage/exam_item';
import ExamResult                 from './manage/exam_result';
import ExamGroup                  from './manage/exam_group';
import Document                   from './manage/document';
import DocumentStudent            from './manage/document_student';
import Ip                         from './manage/ip';
import SyllabusTemplate           from './manage/syllabus_template';
import Syllabus                   from './manage/syllabus';
import SyllabusExportPdf          from './manage/syllabus/export/pdf';
import SyllabusExportCsv          from './manage/syllabus/export/csv';
import SyllabusImportCsvs         from './manage/syllabus/import/csvs';
import SyllabusChargeItem         from './manage/syllabus_charge_item';
import SyllabusContent            from './manage/syllabus_content';
import SyllabusLecture            from './manage/syllabus_lecture';
import SyllabusLectureCopiedSync  from './manage/syllabus_lectures/copied_sync';
import SyllabusSchedule           from './manage/syllabus_schedule';
import Config                     from './manage/config';
import LrSkill                    from './manage/lr_skill';
import LrSkillSet                 from './manage/lr_skill_set';
import LrSkillTargetSchedule      from './manage/lr_skill_target_schedule';
import LrLicense                  from './manage/lr_license';
import LrReviewExport             from './manage/lr_review_export';
import LrSkillExport              from './manage/lr_skill_export';
import ReportAttachment           from './manage/report_attachment';
import ReportTemplate             from './manage/report_template';
import ReportPreview              from './manage/report_preview';
import ReportStudentText          from './manage/report_student_text';
import ReportMentorText           from './manage/report_mentor_text';
import ReportGradesRemark         from './manage/report_grades_remark';
import ReportPdfRemark            from './manage/report_pdf_remark';
import ReportRemark               from './manage/report_remark';
import DuplicationPlan            from './manage/duplication/plans';
import DuplicationGeneric         from './manage/duplication/generics';
import DuplicationMap             from './manage/duplication/maps';
import DuplicationMind            from './manage/duplication/minds';
import CopyTransfer               from './manage/copy_transfer';

export default MarionetteObject.extend({
  initialize() {
    this.StepSchedule              = new StepSchedule();
    this.MindTargetSchedule        = new MindTargetSchedule();
    this.PlanTargetSchedule        = new PlanTargetSchedule();
    this.GenericTargetSchedule     = new GenericTargetSchedule();
    this.ActivityTag               = new ActivityTag();
    this.Allotment                 = new Allotment();
    this.SupportMemoCategory       = new SupportMemoCategory();
    this.Authority                 = new Authority();
    this.AuthorityChildren         = new AuthorityChildren();
    this.Charge                    = new Charge();
    this.Export                    = new Export();
    this.Importer                  = new Importer();
    this.ImportHistory             = new ImportHistory();
    this.Map                       = new Map();
    this.Survey                    = new Survey();
    this.Mapping                   = new Mapping();
    this.App                       = new App();
    this.Mind                      = new Mind();
    this.MindTarget                = new MindTarget();
    this.Plan                      = new Plan();
    this.PlanTarget                = new PlanTarget();
    this.Generic                   = new Generic();
    this.GenericItem               = new GenericItem();
    this.GenericSkill              = new GenericSkill();
    this.GenericTarget             = new GenericTarget();
    this.User                      = new User();
    this.Exam                      = new Exam();
    this.ExamItem                  = new ExamItem();
    this.ExamResult                = new ExamResult();
    this.ExamGroup                 = new ExamGroup();
    this.Document                  = new Document();
    this.DocumentStudent           = new DocumentStudent();
    this.Ip                        = new Ip();
    this.SyllabusTemplate          = new SyllabusTemplate();
    this.Syllabus                  = new Syllabus();
    this.SyllabusExportPdf         = new SyllabusExportPdf();
    this.SyllabusExportCsv         = new SyllabusExportCsv();
    this.SyllabusImportCsvs        = new SyllabusImportCsvs();
    this.SyllabusChargeItem        = new SyllabusChargeItem();
    this.SyllabusContent           = new SyllabusContent();
    this.SyllabusLecture           = new SyllabusLecture();
    this.SyllabusLectureCopiedSync = new SyllabusLectureCopiedSync();
    this.SyllabusSchedule          = new SyllabusSchedule();
    this.Config                    = new Config();
    this.LrSkill                   = new LrSkill();
    this.LrSkillSet                = new LrSkillSet();
    this.LrSkillTargetSchedule     = new LrSkillTargetSchedule();
    this.LrLicense                 = new LrLicense();
    this.LrReviewExport            = new LrReviewExport();
    this.LrSkillExport             = new LrSkillExport();
    this.ReportAttachment          = new ReportAttachment();
    this.ReportTemplate            = new ReportTemplate();
    this.ReportPreview             = new ReportPreview();
    this.ReportStudentText         = new ReportStudentText();
    this.ReportMentorText          = new ReportMentorText();
    this.ReportGradesRemark        = new ReportGradesRemark();
    this.ReportPdfRemark           = new ReportPdfRemark();
    this.ReportRemark              = new ReportRemark();
    this.DuplicationPlan           = new DuplicationPlan();
    this.DuplicationGeneric        = new DuplicationGeneric();
    this.DuplicationMap            = new DuplicationMap();
    this.DuplicationMind           = new DuplicationMind();
    this.CopyTransfer              = new CopyTransfer();
  }
});
