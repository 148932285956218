import { Object as MarionetteObject } from 'backbone.marionette';
import Syllabus from './open/syllabus';
import Survey   from './open/survey';

export default MarionetteObject.extend({
  initialize() {
    this.Syllabus = new Syllabus();
    this.Survey = new Survey();
  }
});
