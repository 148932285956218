import { View } from 'backbone.marionette';
import Mixins from '../../../../mixins';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  mixins  : [
    Mixins.SearchByRadioFilter
  ]
}));
