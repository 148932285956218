import 'backbone-relational';
import { RelationalModel } from 'backbone';

export default RelationalModel.extend({
  defaults: {
    assessment_id  : null,
    study_target_id: null,
    raty           : null,
    raty_max       : null
  }
});
