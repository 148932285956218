import $ from 'jquery';
import { View } from 'backbone.marionette';

export default View.extend({
  template: false,

  ui: {
    basicCocoon          : '.js-basic-cocoon_body',
    basicEntryType       : '.js-basic-select-entry_type',
    basicMaxSettingNumber: '.js-basic-max-setting_number'
  },

  events: {
    'cocoon:after-insert @ui.basicCocoon': 'onBasicAfterInsert',
    'cocoon:after-remove @ui.basicCocoon': 'onBasicAfterRemove'
  },

  onBasicAfterInsert() {
    this._setSettingType();
    this._setSettingNumber();
    this._setEntryType();
    this._enableDeleteBtn();
    this.render();
  },

  onBasicAfterRemove() {
    this._refreshSettingNumber();
    this.render();
  },

  _setSettingType() {
    const $lastField = this.ui.basicCocoon.find('.nested-fields').last();
    const $targetValue = $lastField.find('.js-setting_type');
    $targetValue.val(this.options.settingType);
  },

  _setSettingNumber() {
    const $lastField = this.ui.basicCocoon.find('.nested-fields').last();
    $lastField.addClass('js-added-basic-field');
    this._refreshSettingNumber();
  },

  _refreshSettingNumber() {
    this.ui.basicCocoon.find('.js-added-basic-field').each((i, el) => {
      const $field = $(el);
      const $targetText = $field.find('.js-setting_number_text');
      const $targetValue = $field.find('.js-setting_number');
      const nextSettingNumber = Number(this.ui.basicMaxSettingNumber.attr('data-value')) + (i + 1);
      $targetText.text(nextSettingNumber);
      $targetValue.val(nextSettingNumber);
    });
  },

  _setEntryType() {
    const $lastField = this.ui.basicCocoon.find('.nested-fields').last();
    const $targetText = $lastField.find('.js-entry_type_text');
    const $targetValue = $lastField.find('.js-entry_type');
    const entryType = this.ui.basicEntryType.selectpicker('val');
    $targetText.text(this.ui.basicEntryType.find(':selected').text());
    $targetValue.val(entryType);

    switch (entryType) {
      case 'text':
        this._showInputName();
        this._showInputOnly();
        break;
      case 'select_single':
        this._showInputName();
        this._showInputOnly();
        this._showSelectOnly();
        break;
      case 'select_multiple':
        this._showInputName();
        this._showInputOnly();
        this._showSelectOnly();
        break;
      case 'label_only':
        this._showInputName();
        break;
      default:
        break;
    }
  },

  _showInputName() {
    const $lastField = this.ui.basicCocoon.find('.nested-fields').last();
    const $target = $lastField.find('.js-name-group');
    $target.show();
  },

  _showInputOnly() {
    const $lastField = this.ui.basicCocoon.find('.nested-fields').last();
    const $target = $lastField.find('.js-input-only');
    $target.show();
  },

  _showSelectOnly() {
    const $lastField = this.ui.basicCocoon.find('.nested-fields').last();
    const $target = $lastField.find('.js-select-only');
    $target.show();
  },

  _enableDeleteBtn() {
    const $lastField = this.ui.basicCocoon.find('.nested-fields').last();
    const $target = $lastField.find('.js-delete-btn');
    $target.removeAttr('disabled');
  }
});
