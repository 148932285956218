import { View } from 'backbone.marionette';
import ListView      from './list';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    (new ListView()).render();
  }
});
