// BlockUIのbootstrap＆多言語化設定
import _ from 'underscore';
import $ from 'jquery';

export default {
  defaultOptions: {
    css: {
      backgroundColor: '#fff',
      border         : '2px',
      padding        : '10px 15px',
      'border-radius': '5px',
      'box-shadow'   : 'rgba(0, 0, 0, 0.5) 2px 2px 4px 2px',
      opacity        : 0.9,
      cursor         : 'wait'
    },
    overlayCSS: {
      backgroundColor: '#333',
      opacity        : 0.3,
      cursor         : 'wait'
    }
  },

  ready() {
    const message = I18n.t('frontend.extension.blockUI.message');
    const options = _.extend(this.defaultOptions, {
      message: `<span><i class="fa fa-spinner fa-spin"></i> ${message}</span>`
    });
    _.extend($.blockUI.defaults, options);
  }
};
