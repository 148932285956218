import $ from 'jquery';
import _ from 'underscore';
import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    runner: '#js-runner'
  },

  onRender() {
    const status = this.ui.runner.data('status');
    this.delayReloadRunnerBy(status);
  },

  // JOB待ちまたはJOB実行中ならば5秒後に読み込み
  delayReloadRunnerBy(status) {
    if (status === 'waiting' || status === 'running') {
      setTimeout(_.bind(this.loadRunner, this), 5000);
    }
  },

  loadRunner() {
    const url = this.ui.runner.data('url');

    $.ajax({ url })
     .done((data) => {
       this.ui.runner.html(data.html);
       this.delayReloadRunnerBy(data.status);
     });
  }
});
