import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils  from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.exam_results.edit'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableRemoveRows,
    {
      behaviorClass: Behaviors.DataTableResizer,
      offsetBottom : 20
    }
  ],

  ui: {
    uploadField: '#js-upload'
  },

  onRender() {
    this.bindFileInput();
    this.initDataTables({
      columns: this.columns(),
      order  : [[3, 'desc']]
    });
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['csv']
    });
  },

  columns() {
    return [
      {
        class: 'student_code',
        data : 'student_code',
        width: 100
      },
      {
        class: 'display_code',
        data : 'student_display_code',
        width: 100
      },
      {
        class: 'student_name',
        data : 'student_name'
      },
      {
        class: 'exam_day',
        data : 'exam_day',
        width: 70
      },
      {
        class: 'item_no',
        data : 'exam_item_no',
        width: 30
      },
      {
        class: 'item_name',
        data : 'exam_name'
      },
      {
        class: 'score',
        data : 'score',
        width: 40
      },
      {
        class: 'max_score',
        data : 'max_score',
        width: 60
      }
    ];
  }
}));
