import { View } from 'backbone.marionette';
import SettingTable from './setting_table';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    (new SettingTable()).render();
  }
});
