import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.report_templates.new'),

  ui: {
    reviseStartField: '.js-revise-start-field',
    reviseEndField  : '.js-revise-end-field',
    notice          : '.js-notice'
  },

  events: {
    'keyup @ui.reviseStartField' : 'onChangeReviseField',
    'change @ui.reviseStartField': 'onChangeReviseField',
    'keyup @ui.reviseEndField'   : 'onChangeReviseField',
    'change @ui.reviseEndField'  : 'onChangeReviseField'
  },

  // リアルタイムで情報や警告を出す
  onChangeReviseField() {
    this.cleanValidateError();
    const minYear = parseInt(this.ui.reviseStartField.attr('min'), 10);
    const maxYear = parseInt(this.ui.reviseStartField.attr('max'), 10);
    const oldStartYear = parseInt(this.ui.notice.data('start-curriculum-year'), 10);
    const oldEndYear = parseInt(this.ui.notice.data('end-curriculum-year'), 10);
    const reviseStartYear = parseInt(this.ui.reviseStartField.val(), 10);
    const reviseEndYear = parseInt(this.ui.reviseEndField.val(), 10);

    if (isNaN(reviseStartYear) || (reviseStartYear < minYear) || (reviseStartYear > maxYear)) {
      this.alertNotice(this.t('.alert_start_range', { minYear, maxYear }));
      return;
    }

    if (isNaN(reviseEndYear) || (reviseStartYear > reviseEndYear)) {
      this.alertNotice(this.t('.alert_end_range'));
      return;
    }

    if (reviseStartYear > oldEndYear) {
      this.infoNotice(this.t('.notice_copy', {
        reviseStartYear, reviseEndYear, oldStartYear, oldEndYear
      }));
    } else {
      const closeYear = reviseStartYear - 1;
      this.infoNotice(this.t('.notice_revise', {
        reviseStartYear, reviseEndYear, closeYear, oldStartYear, oldEndYear
      }));
    }
  },

  alertNotice(message) {
    this.ui.notice
        .removeClass('alert-info').addClass('alert-danger')
        .html(message).removeClass('hidden');
  },

  infoNotice(message) {
    this.ui.notice
        .removeClass('alert-danger').addClass('alert-info')
        .html(message).removeClass('hidden');
  },

  // リアルタイムで情報や警告を出す際に
  // Raisのvalidateエラーが残っていると相反する情報になるため消す
  cleanValidateError() {
    const reviseFormGroup = this.ui.reviseStartField.parents('.form-group');
    reviseFormGroup.removeClass('has-error');
    reviseFormGroup.find('span.help-block').addClass('hidden');
  }
}));
