import 'backbone-relational';
import { RelationalModel, HasMany } from 'backbone';
import AssessmentItem from './assessment_item';

export default RelationalModel.extend({
  defaults: {
    indicator: 'other'
  },

  relations: [{
    type        : HasMany,
    key         : 'assessment_items',
    relatedModel: AssessmentItem
  }]
});
