import $ from 'jquery';

const radioSelector = 'input[type="radio"]:not(.hidden)';
const keyEventTarget = 'span.radio-icon';
const clickEventTarget = 'span.radio-icon:not(label > span.radio-icon)';
const spaceKeyCode = 32;

export default {
  bind(selector = 'body') {
    $(selector).find(radioSelector).after('<span class="radio-icon" tabindex="0">');
    $(document).off('click.radio');
    $(document).off('keydown.radio');
    $(document).on('click.radio', clickEventTarget, this.onClick);
    $(document).on('keydown.radio', keyEventTarget, this.onKeydown);
  },

  onClick(e) {
    $(e.target).prev(radioSelector).trigger('click');
  },

  // スペースキーでチェックを入れる
  onKeydown(e) {
    if (e.keyCode === spaceKeyCode) {
      $(e.target).trigger('click');
      return false;
    }
    return true;
  }
};
