import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/copy_transfer';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {},

  show() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/copy_transfers'    : 'index',
    ':tenant/manage/copy_transfers/new': 'new',
    ':tenant/manage/copy_transfers/:id': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
