import d3 from 'd3';
import $ from 'jquery';
import _ from 'underscore';

export default {
  // NVD3 のチャートで凡例のアイコン部分に色を付ける。
  colorIcons($icons, colors = d3.scale.category20().range()) {
    _($icons).each((icon, i) => {
      $(icon).css({ color: colors[i] });
    });
  }
};
