import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils  from '../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  ui: {
    uploadField: '#js-upload'
  },

  onRender() {
    this.bindFileInput();
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: [this.ui.uploadField.data('format')]
    });
  }
}));
