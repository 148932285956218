import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View from '../../views/manage/activity_tag';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/activity_tags': 'index'
  },

  initialize() {
    this.controller = new Controller();
  }
});
