import $ from 'jquery';
import _ from 'underscore';
import View from '../../student/achieves/process_chart';
import Mixins from '../../../mixins';

export default Mixins.cocktail(View.extend({
  chartConfig: {
    stacked     : true,
    showControls: false,
    duration    : 0
  },

  mixins: [
    Mixins.colorIcons
  ],

  onRender() {
    const url = this.$el.data('url');
    if (!url) return;

    this.$el.block();
    this.colorIcons(this.$('.js-axis-ability > i'));
    $.get(url)
     .done(_.bind(this.renderChart, this))
     .always(() => {
       this.$el.unblock();
     });
  }
}));
