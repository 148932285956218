import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View from '../../views/manage/lr_review_export';
import BtnWithLogsView from '../../views/manage/export/btn_with_logs';

const Controller = RestController.extend({
  index() {
    (new BtnWithLogsView()).render();
    (new View.Index()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/lr_review_exports': 'index'
  },

  initialize() {
    this.controller = new Controller();
  }
});
