import $ from 'jquery';
import { View } from 'backbone.marionette';
import Utils from '../../../../utils';
import Tabs from './_tabs';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    uploadField: '.js-upload'
  },

  onRender() {
    new Tabs({ el: this.el }).render();
    this._showErrorMessage();
    this._bindFileInput();
    this._reloadParent();
  },

  _showErrorMessage() {
    const $el = $('flash');
    if ($el.data('type') === 'error') {
      const $div = $('<div>').addClass('alert alert-danger').html($el.data('message'));
      $('.js-basic-validation-alert-area').append($div);
    }
  },

  _bindFileInput() {
    this.ui.uploadField.each((_i, el) => {
      Utils.FileInput.bind(this.$(`#${el.id}`), {
        allowedFileExtensions: ['xlsx'],
        showUpload           : false
      });
    });

    this.ui.uploadField.on('filebatchselected', (event) => {
      $(event.target).parents('form').submit();
    });
  },

  _reloadParent() {
    Utils.Opener.get('SyllabusTemplateDatatable').reload();
  }
});
