import $ from 'jquery';
import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,
  ui      : {
    csvRegion: '.js-csv-region'
  },
  regions: {
    csv: '@ui.csvRegion'
  },

  onRender() {
    const url = this.ui.csvRegion.data('path');
    const intervalID = setInterval(() => {
      $.getJSON(url).then((json) => {
        if (json.status !== 'creating') clearInterval(intervalID);

        const childView = new View({ el: $('<div>').html(json.html) });
        this.showChildView('csv', childView);
      });
    }, 1000);
  }
});
