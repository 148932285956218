import { View, CompositeView } from 'backbone.marionette';
import rubricsTemplate from '../../../templates/student/assessments/rubrics.pug';
import rubricTemplate  from '../../../templates/student/assessments/rubric.pug';

const RubricView = View.extend({
  tagName  : 'tr',
  className: 'asm-assessment-rubric',
  template : rubricTemplate,

  ui: {
    cell: 'td.asm-rubric'
  },

  onRender() {
    this.renderCell();
    this.ui.cell.addClass('disabled');
  },

  renderCell() {
    this.ui.cell.removeClass('selected');
    this.currentCell().addClass('selected');
  },

  currentCell() {
    const raty = this.model.get('raty');
    return this.$el.find(`td.asm-rubric[data-raty=${raty}]`);
  }
});

export default CompositeView.extend({
  template          : rubricsTemplate,
  childView         : RubricView,
  childViewContainer: 'tbody',

  initialize(options) {
    this.labels = options.labels;
  },

  serializeData() {
    return { labels: this.labels, hasRatyTeacher: this.hasRatyTeacher() };
  },

  hasRatyTeacher() {
    const test = (model) => { return model.get('raty_teacher') > 0; };
    return this.collection.any(test);
  }
});
