import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.authorities.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      paginate: false,
      info    : false,
      columns : this.columns()
    });
  },

  columns() {
    return [
      {
        class: 'role',
        data : 'role',
        width: 60
      },
      {
        class: 'code',
        data : 'code',
        width: 100
      },
      {
        class: 'name',
        data : 'name'
      },
      {
        class   : 'allotments text-right',
        data    : 'allotments',
        sortable: false,
        width   : 35
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (val, type, row) => {
          if (row.allotments > 0) return '';  // 権限割当があると削除不可
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
