import Window from './window';

// jsで大きなポップアップ画面を作るのは実装が複雑になりリサイズ対応等が手間になる
// 本案件では基本的に別画面として作成し別ウィンドウを開く形でポップアップ挙動を実現する
// またレスポンシブに作るため大きさや位置の数値指定は不可とする
export default {
  open(el) {
    // 半分幅の通常ポップアップ、ただしそれで困る場合は同じ幅とする
    const width = (window.innerWidth > 600) ? (window.innerWidth / 2) : window.innerWidth;
    const height = window.innerHeight;

    // 右上寄せ
    const top = window.screenTop;
    const left = (window.screenLeft + window.innerWidth) - width;

    const options = `width=${width},height=${height},top=${top},left=${left}`;
    Window.open(el.href, el.target || '_blank', options);
  },

  // 半分だとスマホデザインになりやすいため裏スペースの確保よりPCデザインが重要な場合に使う
  openShrink(el) {
    // 横長め高さ2/3の縮小ポップアップ、ただしそれで困る場合は同じ幅とする
    const width = (window.innerWidth > 600) ? ((window.innerWidth * 2) / 3) : window.innerWidth;
    const height = (window.innerHeight > 300) ? ((window.innerHeight * 2) / 3) : window.innerHeight;

    // 右上寄せ
    const top = window.screenTop;
    const left = (window.screenLeft + window.innerWidth) - width;

    const options = `width=${width},height=${height},top=${top},left=${left}`;
    Window.open(el.href, el.target || '_blank', options);
  }
};
