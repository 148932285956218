import $ from 'jquery';
import _ from 'underscore';
import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    attachmentArea: '.js-attachment-area'
  },

  onRender() {
    const status = this.ui.attachmentArea.data('status');
    this.delayReloadAttachmentBy(status);
  },

  // JOB待ちまたはJOB実行中ならば5秒後に読み込み
  delayReloadAttachmentBy(status) {
    if (status === 'waiting' || status === 'running') {
      setTimeout(_.bind(this.loadAttachment, this), 5000);
    }
  },

  loadAttachment() {
    const url = this.ui.attachmentArea.data('url');

    $.ajax({ url })
     .done((data) => {
       this.ui.attachmentArea.html(data.html);
       this.delayReloadAttachmentBy(data.status);
     });
  }
});
