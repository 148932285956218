import $              from 'jquery';
import _              from 'underscore';
import { View }       from 'backbone.marionette';
import Utils          from '../../../../utils';
import Behaviors      from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.student.activities.index'),

  behaviors: [
    Behaviors.CommentPhrase
  ],

  ui: {
    select2     : '.js-select2',
    searchForm  : 'form',
    searchButton: 'button[type="submit"]',
    resetButton : '.js-reset-btn',
    deleteButton: '.js-delete-button'
  },

  regions: {
    activities: '.js-activities-region'
  },

  events: {
    'click @ui.searchButton'       : 'onClickSearch',
    'click @ui.resetButton'        : 'onClickReset',
    'click .paginate_button a'     : 'onClickPage',
    'ajax:success @ui.deleteButton': 'onAjaxSuccessDelete'
  },

  currentSearchParams: {},

  onRender() {
    this.$('.js-datepicker')
      .datepicker({ language: I18n.locale, autoclose: true });
    this.bindSelect2();
    this.ui.searchButton.prop('disabled', false);
    this.ui.resetButton.prop('disabled', false);
    this.fetchActivities({ params: { page: 1 } });
    Utils.Opener.set('ActivityDecoratorComments', this);
  },

  onClickSearch(e) {
    e.preventDefault();
    e.stopPropagation();

    this.updateCurrentSearchParams();
    this.fetchActivities({ params: { page: 1 } });
  },

  onClickReset() {
    this.ui.searchForm.get(0).reset();
    this.bindSelect2();

    this.updateCurrentSearchParams();
    this.fetchActivities({ params: { page: 1 } });
  },

  onClickPage(e) {
    e.preventDefault();

    const url = $(e.currentTarget).prop('href');
    this.fetchActivities({ url });
  },

  onAjaxSuccessDelete() {
    this.fetchActivities({ params: { page: this.getCurrentPage() } });
  },

  getCurrentPage() {
    return Number(this.$('.paginate_button.active > a').text());
  },

  bindSelect2() {
    if (this.ui.select2.data('select2')) this.ui.select2.select2('destroy');

    Utils.Select2.bind(this.ui.select2, {
      width      : '100%',
      placeholder: `<${this.t('.tag')}>`
    });
  },

  fetchActivities({ url: url = null, params: additionalParams = {} }) {
    const params =
      _.extend({}, this.currentSearchParams, additionalParams);

    this.$('.js-activities-region').block();

    $.ajax({
      url     : url || this.ui.searchForm.prop('action'),
      data    : params,
      dataType: 'json'
    })
      .done(({ html }) => {
        /*
         * View の element はルート要素が 1 つである必要がある。
         * サーバ側でそれを意識したくないので、ここで div 要素でラップする。
         */
        const view = new View({ el: $(`<div>${html}</div>`) });

        this.showChildView('activities', view);
        this.$('.js-activities-region').unblock();
      });
  },

  updateCurrentSearchParams() {
    this.currentSearchParams = Utils.Form.serializeObject(this.ui.searchForm);
  }
});
