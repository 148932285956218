import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabus_templates.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc']]
    });
    Utils.Opener.set('SyllabusTemplateDatatable', this);
  },

  columns() {
    return [
      {
        class: 'name',
        data : 'name',
        width: 300
      },
      {
        class: 'note',
        data : 'note',
        width: 200
      },
      {
        class   : 'used_count',
        data    : 'used_count',
        width   : 50,
        sortable: false
      },
      {
        class: 'created_at',
        data : 'created_at',
        width: 40
      },
      {
        class: 'updated_at',
        data : 'updated_at',
        width: 40
      },
      {
        class   : 'detail text-center',
        data    : 'show_path',
        sortable: false,
        width   : 30,
        render  : (val) => {
          return Utils.Render.showButton(this, val, val);
        }
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 30,
        render  : (val) => {
          return Utils.Render.editButton(this, val, val);
        }
      },
      {
        class   : 'copy text-center',
        data    : 'copy_path',
        sortable: false,
        width   : 30,
        render  : (val) => {
          return Utils.Render.linkButton(this.t('.copy'), val, '_self', 'btn-warning', 'fa-copy');
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 30,
        render  : (val, _type, row) => {
          if (row.used_count !== '0') return '';
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
