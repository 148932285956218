import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View           from '../../views/student/support_memos';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/support_memos': 'index'
  },

  initialize() {
    this.controller = new Controller();
  }
});
