var pug = require("!../../../../../../../../../shared/node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, after_scheduled, answerable, assessable, edit_path, mark, name, open_lecture_surveys_path, open_syllabus_path, period, replacement, stage, surveyable) {const languages = I18n.t('frontend.backbone.templates.student.assessments');
pug_html = pug_html + ("\u003Ctable class=\"panel-heading table table-bordered\"\u003E\u003Ctr\u003E\u003Ctd class=\"asm-record__checker\"\u003E\u003Cspan" + (pug.attr("class", pug.classes(["fa-stack",stage], [false,true]), false, true)) + "\u003E\u003Ci class=\"fa fa-circle fa-stack-2x\"\u003E\u003C\u002Fi\u003E\u003Ci class=\"fa fa-check fa-stack-1x fa-inverse\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Ftd\u003E\u003Ctd\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)));
if (answerable > 0 && edit_path) {
{
pug_html = pug_html + "\u003Ca" + (" class=\"btn btn-xs btn-primary pull-right\""+pug.attr("href", edit_path, true, true)) + "\u003E\u003Ci class=\"fa fa-edit\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = languages.edit) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
}
pug_html = pug_html + "\u003C\u002Ftd\u003E\u003C\u002Ftr\u003E\u003C\u002Ftable\u003E\u003Cdiv class=\"panel-body\"\u003E";
if (replacement) {
{
pug_html = pug_html + "\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = replacement) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Chr\u003E";
}
}
pug_html = pug_html + "\u003Cdiv class=\"asm-record__info\"\u003E";
if (surveyable) {
{
pug_html = pug_html + "\u003Ci class=\"fa fa-pencil surveyable\"\u003E" + (pug.escape(null == (pug_interp = languages.surveyable) ? "" : pug_interp)) + "\u003C\u002Fi\u003E";
}
}
if (open_lecture_surveys_path) {
{
if (after_scheduled) {
{
pug_html = pug_html + "\u003Ca" + (" class=\"btn btn-xs btn-default\""+pug.attr("href", open_lecture_surveys_path, true, true)+pug.attr("target", open_lecture_surveys_path, true, true)) + "\u003E\u003Ci class=\"fa fa-pie-chart\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = languages.lecture_survey) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
} else {
{
pug_html = pug_html + "\u003Ca" + (" class=\"btn btn-xs btn-default\""+pug.attr("disabled", true, true, true)+" data-toggle=\"tooltip\""+pug.attr("title", languages.not_after_scheduled, true, true)) + "\u003E\u003Ci class=\"fa fa-pie-chart\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = languages.lecture_survey) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
}
}
}
if (open_syllabus_path) {
{
pug_html = pug_html + "\u003Ca" + (" class=\"btn btn-xs btn-default\""+pug.attr("href", open_syllabus_path, true, true)+pug.attr("target", open_syllabus_path, true, true)) + "\u003E\u003Ci class=\"fa fa-graduation-cap\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = languages.syllabus) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"asm-record__registration\"\u003E" + (pug.escape(null == (pug_interp = period) ? "" : pug_interp)) + (pug.escape(null == (pug_interp = ' / ') ? "" : pug_interp)) + (pug.escape(null == (pug_interp = mark) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Chr\u003E";
if (assessable <= 0) {
{
pug_html = pug_html + "\u003Ci class=\"fa fa-star-half-o\"\u003E" + (pug.escape(null == (pug_interp = languages.out_of_assessment) ? "" : pug_interp)) + "\u003C\u002Fi\u003E";
}
}
pug_html = pug_html + "\u003Cdiv class=\"asm-assessment-forms\"\u003E\u003Cdiv class=\"each-region\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"after_scheduled" in locals_for_with?locals_for_with.after_scheduled:typeof after_scheduled!=="undefined"?after_scheduled:undefined,"answerable" in locals_for_with?locals_for_with.answerable:typeof answerable!=="undefined"?answerable:undefined,"assessable" in locals_for_with?locals_for_with.assessable:typeof assessable!=="undefined"?assessable:undefined,"edit_path" in locals_for_with?locals_for_with.edit_path:typeof edit_path!=="undefined"?edit_path:undefined,"mark" in locals_for_with?locals_for_with.mark:typeof mark!=="undefined"?mark:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"open_lecture_surveys_path" in locals_for_with?locals_for_with.open_lecture_surveys_path:typeof open_lecture_surveys_path!=="undefined"?open_lecture_surveys_path:undefined,"open_syllabus_path" in locals_for_with?locals_for_with.open_syllabus_path:typeof open_syllabus_path!=="undefined"?open_syllabus_path:undefined,"period" in locals_for_with?locals_for_with.period:typeof period!=="undefined"?period:undefined,"replacement" in locals_for_with?locals_for_with.replacement:typeof replacement!=="undefined"?replacement:undefined,"stage" in locals_for_with?locals_for_with.stage:typeof stage!=="undefined"?stage:undefined,"surveyable" in locals_for_with?locals_for_with.surveyable:typeof surveyable!=="undefined"?surveyable:undefined));;return pug_html;};
module.exports = template;