import $     from 'jquery';
import Utils from '../../../../utils';
import View  from '../reviews/index';

export default View.extend({
  renderGroup(group, rows) {
    const summaryPath = rows.data().pluck('summary')[0];
    const commentBtn = Utils.Render.popupButton(this, summaryPath, summaryPath);

    return $('<tr>').append($('<td>').attr('colspan', 5).append(group))
                    .append($('<td>').addClass('comment').append(commentBtn));
  },

  // データは学生ベースだが見た目はカルテベースにする必要があるため
  // group だけ実カラムであり、残りは見た目のカラム(data: null)である
  columns() {
    return [{
      class  : 'group',
      data   : 'group',
      visible: false
    }, {
      class   : 'subject',
      data    : null,
      width   : 150,
      sortable: false
    }, {
      class   : 'taken_step',
      data    : null,
      width   : 40,
      sortable: false
    }, {
      class   : 'credit',
      data    : null,
      width   : 40,
      sortable: false
    }, {
      class   : 'result_mark',
      data    : null,
      width   : 60,
      sortable: false
    }, {
      class   : 'lr_review',
      data    : null,
      sortable: false
    }, {
      class   : 'comment',
      data    : null,
      width   : 55,
      sortable: false
    }];
  }
});
