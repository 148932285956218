import { View } from 'backbone.marionette';
import jade from 'jade';

export default View.extend({
  template: jade.compile('!= html'),

  modelEvents: {
    change: 'render',
    sync  : 'onSync'
  },

  ui: {
    entryTermSelect: '.js-entry-term-select',
    entryTerm      : '.js-entry-term',
    tooltips       : "[data-toggle='tooltip']"
  },

  events: {
    'change @ui.entryTermSelect': 'onChangeEntryTermSelect'
  },

  initialize() {
    this.on('loadItem', this.onLoadItem);
  },

  onRender() {
    this._toggleEntryTerm();
    this.ui.tooltips.tooltip();
  },

  onChangeEntryTermSelect() {
    this._toggleEntryTerm();
  },

  onLoadItem() {
    if (!this.$el[0]) return;
    this.$el.block();
    this.model.fetch({
      url: this.$el.data('url')
    });
  },

  onSync() {
    this.$el.unblock();
  },

  _toggleEntryTerm() {
    this.ui.entryTerm.hide();
    this.$(`.js-${this.ui.entryTermSelect.val()}`).show();
  }
});
