import View from '../../../shared/process_bar_chart';

export default View.extend({
  el      : '.js-generic-process-chart',
  template: false,
  ui      : {
    svg: 'svg'
  },

  chartConfig: {
    duration: 0
  },

  onRender() {
    const data = this.$el.data('chart-data');
    if (!data) return;
    this.renderChart(data, this.ui.svg);
  }
});
