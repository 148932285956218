import { View } from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    row: '.js-row'
  },

  onRender() {
    this._updateOpenerRow();
  },

  _updateOpenerRow() {
    Utils.Opener.get('ReportMentorTextDatatable').updateRow(this.ui.row.data('row'));
  }
});
