import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/exam';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {
    (new View.New().render());
  },

  edit() {
    (new View.Edit().render());
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/exams'         : 'index',
    ':tenant/manage/exams/new'     : 'new',
    ':tenant/manage/exams/:id/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
