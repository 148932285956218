import { Object as MarionetteObject } from 'backbone.marionette';
import Achieve  from './analysis/achieve';
import Map      from './analysis/map';
import Generic  from './analysis/generic';
import Mind     from './analysis/mind';
import Download from './analysis/download';

export default MarionetteObject.extend({
  initialize() {
    this.Achieve = new Achieve();
    this.Map = new Map();
    this.Generic = new Generic();
    this.Mind = new Mind();
    this.Download = new Download();
  }
});
