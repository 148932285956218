import Index from './minds/index';
import Form from './minds/form';
import Show  from './minds/show';

export default {
  Index: Index,
  Edit : Form,
  New  : Form,
  Show : Show
};
