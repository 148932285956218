// 一定高さ以上の文字列を「続きを読む」リンクで閉じる処理へと拡張する
// --例--------------------------------------------------------------
// pre.js-readmore(data-height=75 data-less=:false)
// ------------------------------------------------------------------
import $ from 'jquery';
import 'readmore-js';

export default {
  ready() {
    const languages = I18n.t('frontend.extension.readmore');
    const moreLink = `<a href="#"><i class="fa fa-plus-square-o"></i> ${languages.more}</a>`;
    const lessLink = `<a href="#"><i class="fa fa-minus-square-o"></i> ${languages.less}</a>`;

    $('.js-readmore').each((_i, el) => {
      const $el = $(el);
      const more = $el.data('more') === false ? null : moreLink;
      const less = $el.data('less') === false ? null : lessLink;

      $el.readmore({
        speed          : $el.data('speed') || 200,
        collapsedHeight: $el.data('height') || 200,
        moreLink       : more,
        lessLink       : less
      });
    });
  }
};
