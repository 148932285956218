import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/student/plan';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  },

  edit() {
    (new View.Edit()).render();
  },

  show() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/plans'         : 'index',
    ':tenant/students/:student_id/plans/:id/edit': 'edit',
    ':tenant/students/:student_id/plans/:id'     : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
