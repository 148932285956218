import { View } from 'backbone.marionette';
import _ from 'underscore';
import Chart from '../achieves/chart';

export default View.extend({
  el      : '.js-achieve-result-chart',
  template: false,

  ui: {
    mapbarTab     : '#js-mapbar a',
    mapbarTabFirst: '#js-mapbar a:first',
    scoreArea     : '.asm-achieve-score',
    levelArea     : '.asm-achieve-level'
  },

  onRender() {
    this.bindUIElements();
    this.data = { assessment_summary_id: this.$el.data('asid') };
    this.scoreChart = (new Chart({ el: this.ui.scoreArea }).render());
    this.levelChart = (new Chart({ el: this.ui.levelArea }).render());
    this.ui.mapbarTab.on('shown.bs.tab', _.bind(this._loadChart, this));
    this.ui.mapbarTabFirst.tab('show');
  },

  // レーダーチャートはシート別に出力するのでシートIDを条件に追加する
  _loadChart() {
    const sheetId = this.$el.find('#js-mapbar li.active a').data('id');
    const data = _.extend({ sheet_id: sheetId }, this.data);
    if (this.scoreChart) this.scoreChart.load(data);
    if (this.levelChart) this.levelChart.load(data);
  }
});
