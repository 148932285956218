import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/report_student_text';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/report_student_texts': 'index'
  },

  initialize() {
    this.controller = new Controller();
  }
});
