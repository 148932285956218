import Index from './syllabus_templates/index';
import New from './syllabus_templates/new';
import Edit from './syllabus_templates/edit';
import Show from './syllabus_templates/show';

export default {
  Index: Index,
  New  : New,
  Edit : Edit,
  Show : Show
};
