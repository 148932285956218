import $ from 'jquery';
import { Behavior } from 'backbone.marionette';
import Utils from '../../utils';

// 内容固定の督促通知ボタン
// 前提
// reload,appendSearchParamsWithoutPagingToUrl関数を持つDatatableを持つViewに適用する
// 入力状況のフィルターと通知ボタンに js クラスを適用している
// 通知ボタンのdata値として url, enabledValue, enabledName を設定している
export default Behavior.extend({
  t: Utils.I18n.bind('behaviors.remind_notify'),

  ui: {
    remindFilter: '.js-remind-filter',
    remindBtn   : '.js-remind-btn'
  },

  events: {
    'click @ui.remindBtn': 'onClickRemindBtn'
  },

  onClickRemindBtn(event) {
    event.preventDefault();

    const enabledValue = String(this.ui.remindBtn.data('enabledValue'));
    this.ui.remindFilter.selectpicker('val', enabledValue);

    this.view.reload(() => {
      const url = this.view.appendSearchParamsWithoutPagingToUrl(this.ui.remindBtn.data('url'));
      this._confirmReminded(url);
    });
  },

  _confirmReminded(url) {
    const enabledName =  String(this.ui.remindBtn.data('enabledName'));

    $.ajax({ url })
      .then(({ total_count: count }) => {
        if (count === 0) {
          Utils.Modal.alert(this.t('.alert.no_students',  { enabledName }));
          return;
        }

        Utils.Modal.confirm(
          this.t('.confirm.notify_students', { count, enabledName }),
          (flg) => {
            if (!flg) return;
            this._notifyReminder(url);
          }
        );
      });
  },

  _notifyReminder(url) {
    $.ajax({
      url,
      method: 'POST'
    }).then(() => {
      Utils.Notify.notify('notice', this.t('.notice.notified_reminder'));
    });
  }
});
