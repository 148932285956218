import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View           from '../../views/open/syllabus';
import ExportPdfView  from '../../views/manage/syllabuses/export/pdf';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },
  export() {
    (new ExportPdfView.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/open/:locale/syllabuses'                    : 'index',
    ':tenant/open/:locale/syllabuses/:syllabus_id/export': 'export'
  },

  initialize() {
    this.controller = new Controller();
  }
});
