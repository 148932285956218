import $ from 'jquery';
import { Behavior } from 'backbone.marionette';

const CLOSE = 'closed';

// 親要素をクリックすると、子要素がすべて[閉じる/開く]ような振る舞いを提供するモジュール
// HTML の階層上は親子関係を持っておらず data-attribute に情報を持たせている前提で動く
export default Behavior.extend({
  ui: {
    accordionParent  : '.js-accordion[data-accordion-id]',
    accordionChildren: '.js-accordion[data-accordion-parent-id]'
  },

  onRender() {
    this.ui.accordionParent.prepend('<span class="accordion-icon"></span>');
    this.$('span.accordion-icon').click((e) => { return this.accordion(e); });
  },

  accordion(e) {
    const parent = $(e.currentTarget.parentElement);
    const nextStatus = parent.hasClass(CLOSE) ? null : CLOSE;
    this._accordionTo(nextStatus, parent);
  },

  // 引数で status [OPEN/CLOSE] を受け取り parent を [開く/閉じる]
  _accordionTo(status, parent) {
    const children = this._accordionChildren(parent);
    if (children.length === 0) { return; }
    if (status === CLOSE) {
      parent.addClass(CLOSE);
      children.fadeOut();
    } else {
      parent.removeClass(CLOSE);
      children.fadeIn();
    }
    children.each((_, child) => { this._accordionTo(status, $(child)); });
  },

  _accordionChildren(parent) {
    const id = parent.data('accordion-id');
    const children = this.ui.accordionChildren;
    return children.filter(`[data-accordion-parent-id=${id}]`);
  }
});
