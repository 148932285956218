import $ from 'jquery';
import _ from 'underscore';
import bootbox from 'bootbox';
import autosize from 'autosize';
import textCounter from './text_counter';

export default {
  confirm(message, callback, options = {}) {
    const languages = I18n.t('frontend.utils.modal.confirm');
    const defaultOptions = _.extend(this.baseOptions(message, callback), {
      title: `
        <span class='text-warning'>
          <i class='fa fa-warning fa-fw'></i>${languages.title}
        </span>
      `,
      buttons: {
        cancel: {
          label    : languages.cancel_label,
          className: 'btn-warning pull-left'
        },
        confirm: {
          label    : languages.ok_label,
          className: 'btn-primary'
        }
      }
    });
    const $modal = bootbox.confirm(_.extend(defaultOptions, options));
    this.bindCenter($modal);
    return $modal;
  },

  alert(message, callback, options = {}) {
    const languages = I18n.t('frontend.utils.modal.alert');
    const alertMessage = message || languages.default_message;
    const defaultOptions = _.extend(this.baseOptions(alertMessage, callback), {
      title: `
        <span class='text-danger'>
          <i class='fa fa-warning fa-fw'></i>${languages.title}
        </span>
      `,
      buttons: {
        ok: {
          label    : languages.ok_label,
          className: 'btn btn-primary'
        }
      }
    });
    const $modal = bootbox.alert(_.extend(defaultOptions, options));
    this.bindCenter($modal);
    return $modal;
  },

  info(message, callback, options = {}) {
    const languages = I18n.t('frontend.utils.modal.info');
    const defaultOptions = _.extend(this.baseOptions(message, callback), {
      title: `
        <span class='text-info'>
          <i class='fa fa-info-circle fa-fw'></i>${languages.title}
        </span>
      `,
      buttons: {
        ok: {
          label    : languages.ok_label,
          className: 'btn btn-primary'
        }
      }
    });
    const $modal = bootbox.alert(_.extend(defaultOptions, options));
    this.bindCenter($modal);
    return $modal;
  },

  prompt(message, callback, options = {}) {
    const languages = I18n.t('frontend.utils.modal.prompt');
    const defaultOptions = _.extend(this.baseOptions(message, callback), {
      title: `
        <span class='text-warning'>
          <i class='fa fa-warning fa-fw'></i>${languages.title}
        </span>
      `,
      buttons: {
        cancel: {
          label    : languages.cancel_label,
          className: 'btn-warning pull-left'
        },
        confirm: {
          label    : languages.ok_label,
          className: 'btn-primary'
        }
      },
      show     : false,
      inputType: 'textarea'
    });
    const $modal = bootbox.prompt(_.extend(defaultOptions, options));
    $modal.find('.bootbox-form').before(`
      <div style='padding-bottom: 12px;'>
        ${defaultOptions.message}
      </div>
    `
    );
    autosize($modal.find('textarea'));

    // デフォルトテキスト
    if (options.defaultText) {
      $modal.find('textarea').val(options.defaultText);
    }

    // ・`{ counter: true }` : 文字数カウント表示追加
    // ・`{ counter: 数字 }` : 文字数カウント + 上限表示を追加
    if (options.counter) {
      const limit = _.isBoolean(options.counter) ? null : options.counter;
      textCounter($modal.find('textarea'), limit);
    }

    // 改行禁止
    if (options.noEnter) {
      $modal.find('textarea').on('keydown.utilsModal', (e) => {
        if (e.key === 'Enter') {
          return false;
        }
        return true;
      });
    }

    $modal.modal('show');
    this.bindCenter($modal);
    return $modal;
  },

  baseOptions(message, callback) {
    return {
      message    : this.resultMessage(message),
      closeButton: false,
      animate    : false,
      callback   : callback
    };
  },

  resultMessage(message) {
    const msg = _.isArray(message) ? message.join('<br>') : message;
    return msg.replace(/\n|\r|\r\n/g, '<br>');
  },

  bindCenter(modal) {
    this.centerModal(modal);
    $(window).off('resize.bootbox').on('resize.bootbox', () => {
      _.each($('.bootbox.modal:visible'), () => {
        this.centerModal(modal);
      });
    });
  },

  // モーダルウィンドウを上下中央に表示する。
  // (参考) Vertically centering Bootstrap modal window
  // http://stackoverflow.com/questions/18053408/vertically-centering-bootstrap-modal-window
  centerModal(modal) {
    const $modal = $(modal);
    const $dialog = $modal.find('.modal-dialog');
    const $header = $('nav.navbar-fixed-top');
    const offset = (($(window).height() - $header.height()) - $dialog.height()) / 2;
    $dialog.css('margin-top', $header.height() + offset);
    return $modal;
  }
};
