import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View           from '../../views/student/activity';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {
    (new View.Edit()).render();
  },

  show() {
    (new View.Show()).render();
  },

  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/activities'         : 'index',
    ':tenant/students/:student_id/activities/new'     : 'new',
    ':tenant/students/:student_id/activities/:id'     : 'show',
    ':tenant/students/:student_id/activities/:id/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
