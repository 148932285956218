// jquery-ujsのdata-confirmオプションで出る確認ダイアログを差し替える
import $ from 'jquery';
import Utils from '../utils';

export default {
  ready() {
    $.rails.allowAction = (link) => {
      if (!link.attr('data-confirm')) return true;
      if (link.attr('data-close')) return true;
      this.showConfirmDialog(link);
      return false;
    };
  },

  confirmed(link) {
    link.attr('data-close', true);
    link.trigger('click.rails');
    link.removeAttr('data-close');
  },

  showConfirmDialog(link) {
    const message = link.attr('data-confirm');
    Utils.Modal.confirm(message, (result) => {
      if (result) this.confirmed(link);
    });
  }
};
