import Sortable from 'sortablejs';
import _ from 'underscore';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    rubricNode      : '.js-rubric-node',
    rubricNodeInsert: '.js-rubric-node-insert',
    rubricNodeList  : '.js-rubric-node-list'
  },

  events: {
    'cocoon:after-insert @ui.rubricNodeInsert': 'onAfterInsertRubric',
    'cocoon:after-remove @ui.rubricNodeList'  : 'onAfterRemoveRubric'
  },

  onRender() {
    this._applyRubricSortable();
  },

  onAfterInsertRubric() {
    this.bindUIElements();
    this._applyRubricSortable();
    _(this.ui.rubricNodeList).each((element) => {
      this._setRaties(this.$(element));
    });
  },

  onAfterRemoveRubric() {
    _(this.ui.rubricNodeList).each((element) => {
      this._setRaties(this.$(element));
    });
  },

  _setRaties($nodeList) {
    const $rows = $nodeList.find('tr.js-rubric-node:visible');
    const max = $rows.length;

    _($rows).each((row, i) => {
      const $row = this.$(row);
      $row.find('.js-rubric-raty-hidden').val(max - i);
      $row.find('.js-rubric-raty').html(`${max - i}`);
    });
  },

  _applyRubricSortable() {
    _(this.ui.rubricNodeList).each((element) => {
      Sortable.create(element, {
        handle  : 'th.raty',
        onUpdate: (e) => {
          this._setRaties(this.$(e.item).parents('.js-rubric-node-list'));
        }
      });
    });
  }
});
