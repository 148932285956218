import _ from 'underscore';
import Utils from '../../utils';

const defaultI18nScope = 'frontend.utils.datatables.language';
const defaultOptions = {
  serverSide  : true,
  processing  : true,
  searching   : false,
  lengthChange: false,
  pageLength  : 100,
  ordering    : true,
  pagingType  : 'simple_numbers',
  language    : I18n.t(defaultI18nScope)
};

export default {
  ui: {
    dataTables: '.js-dataTables',
    search    : '.js-form-ransack',
    // :visible は visibility: hidden も含んでしまう。
    selectpick: '.js-form-ransack select:visible:not([style*="visibility: hidden"])',
    resetBtn  : '.js-reset-btn'
  },

  events: {
    'submit @ui.search'  : 'onSearch',
    'click  @ui.resetBtn': 'onReset'
  },

  // scrollYを大きく指定する理由は以下を共存させたいため
  //  1. Behaviors.DataTableResizer が scrollY に数値を必要とする
  //  2. スクロールなし(undefined or 描画行以上の高さ)で使いたい場合がある
  //     （1000pxを越えるデータ量なら流石にスクロールすべき）
  initDataTables(options = {}) {
    const initOptions = _.defaults(_.defaults(options, defaultOptions), {
      scrollY: 1000,
      ajax   : {
        url : this.ui.dataTables.data('url') || this.ui.search.attr('action'),
        data: _.bind(this.buildParams, this)
      }
    });
    this.ui.dataTables.removeClass('hidden');
    this.dataTable = this.ui.dataTables.dataTable(initOptions).api();
    this.dataTable.on('xhr.dt', (e, settings, json, xhr) => {
      if ([400, 413, 414].includes(xhr.status)) {
        // 検索条件の文字数が多いなど（特に空白区切りの複数検索の個数で発生）
        // ユーザーの操作でRailsによるJSONエラーではなくサーバーレベルのエラーになる可能性がある
        // それらは javascript で解釈可能なJSONエラーではないため、何も動かないように見えてしまうので、ここでエラーを表示する
        // 400 Bad Request
        // 413 Payload Too Large
        // 414 URI Too Long
        // そもそもフロントエンド側で制約して、そのようなRequestを投げないことが理想だが
        // 番号文字数にもよるが番号空白区切り500件くらいの検索なら動き、普段遣いに問題がないためエラー処理で対応する
        // @TODO そもそもURL上限になる検索を発行できないようにする（その際でも制約に漏れがあるかもしれないので、この処理は残すこと）
        Utils.Modal.alert(I18n.t(`${defaultI18nScope}.search_error`, { text: xhr.statusText }));
        return true;
      }
      return false;
    });
  },

  onRender() {
    this.ui.selectpick.selectpicker();
  },

  onSearch(e) {
    e.preventDefault();
    this.reload();
  },

  onReset(e) {
    e.preventDefault();
    this.ui.search[0].reset();
    this.ui.selectpick.selectpicker('refresh');
    this.reload();
  },

  reload(callback = null) {
    if (_.isFunction(callback)) {
      this.dataTable.ajax.reload(callback);
    } else {
      this.dataTable.ajax.reload();
    }
  },

  /*
   * 以下の対応のため、reload の Promise 版を用意する。
   * [#9955] Safari で シラバス管理画面のPDF一括ダウンロードボタンを押すとポップアップブロックされる
   * https://redmine.manabi-labo.co.jp/issues/9955
   */
  reloadAsync() {
    return Promise.resolve(this.reload());
  },

  getSearchParams() {
    return Utils.Form.serializeObject(this.ui.search);
  },

  /*
   * DataTables のソート情報をもとに
   * [
   *   { column: 'fiscal_year',  dir: 'desc' },
   *   { column: 'subject.code', dir: 'asc' },
   *   { column: 'open_term', dir: 'asc' }
   * ]
   * という形式の配列を返す。
   * column には DataTables のカラム情報の data の値を使うが
   * columnName が指定されていればそちらの値を優先する。
   */
  getOrderParams() {
    const columnNames =
      _(this.dataTable.settings().init().columns)
        .map((obj) => { return obj.columnName || obj.data; });

    return _.chain(this.dataTable.order())
      .map((order) => {
        const [index, dir] = order;
        const column = columnNames[index];
        return { column, dir };
      })
      .value();
  },

  // ajax 通信のとき、dataTables 用のソート/検索パラメータを渡さない。
  // その代わりに ransack 用のパラメータをフォームから作って渡すこと。
  // もし、そのまま dataTables 用パラメータを渡してしまうと
  // クエリ文字列が2000文字を超えてブラウザやWebサーバのエラーが起きる。
  buildParams(data) {
    const params = _.omit(data, 'search');
    params.columns = _.map(params.columns, (column) => {
      return _.omit(column, 'name', 'searchable', 'orderable', 'search');
    });
    return _.extend(params, this.getSearchParams());
  },

  // ajax.params() にはページング情報やカラム情報など含まれておりソート情報もDatatables用の構造である
  // Datatable の処理としては正しいが他の処理に同検索条件として流用する際に不便で邪魔であるため
  // それらを調整した条件を抽出してURLに付与する関数を用意する
  appendSearchParamsWithoutPagingToUrl(url) {
    const searchParams = _.omit(
      this.dataTable.ajax.params(),
      'columns', 'order', 'draw', 'length', 'start'
    );
    const orderParams =
      _.chain(this.getOrderParams())
        .map((order, i) => {
          return [
            [`order[${i}][column]`, order.column],
            [`order[${i}][dir]`, order.dir]
          ];
        })
        .flatten(true) // true を指定することで 1 段階だけ flatten する。
        .object()
        .value();
    return Utils.Url.buildUrl(url, _.extend(searchParams, orderParams));
  }
};
