import { View } from 'backbone.marionette';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.SortableRubric,
    Behaviors.LocaleSwitch
  ],

  ui: {
    nodeInsert: '.js-rubric-node-insert'
  },

  events: {
    'cocoon:after-insert @ui.nodeInsert': 'onAfterInsert'
  },

  onAfterInsert() {
    this.bindUIElements();
  }
});
