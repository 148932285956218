import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';

export default Mixins.cocktail(View.extend({
  el      : 'body',
  template: false,

  ui: {
    badge: '.js-untreated-badge',
    alert: '.js-untreated-alert'
  },

  refresh(untreated) {
    this.ui.badge.toggleClass('hidden', !untreated);
    this.ui.alert.toggleClass('hidden', !untreated);
  }
}));
