// intro.js を簡易的に使用するためのラッパー
//------------------------------------------------------------------------
//  const options = { showButtons: false, showBullets: false }
//  const stepArrays = [
//    [ メッセージ, 対象セレクタ, tooltipPosition ],
//    [ メッセージ, 対象セレクタ, tooltipPosition ],
//       ・
//       ・
//  ]
//  Utils.Introjs.play(stepArrays, options);
//------------------------------------------------------------------------
import $ from 'jquery';
import _ from 'underscore';
import IntroJs from 'intro.js';

export default {
  play(stepArrays, options = {}) {
    const labels = I18n.t('frontend.utils.introjs.labels');
    const defaultOptions = {
      skipLabel      : labels.skipLabel,
      nextLabel      : labels.nextLabel,
      prevLabel      : labels.prevLabel,
      doneLabel      : labels.doneLabel,
      showStepNumbers: false
    };

    this.intro = IntroJs.introJs();
    this.intro.setOptions(_({}).defaults(options, defaultOptions));
    this._start(stepArrays);
    return this.intro;
  },

  _start(stepArrays) {
    const stepObjects = stepArrays.map((stepArray) => {
      return this._buildStep(...stepArray);
    });
    this.intro.setOptions({ steps: stepObjects });
    this.intro.start();
  },

  _buildStep(language, selector, position) {
    return {
      intro  : language,
      element: $(selector)[0],
      position
    };
  },

  finish() {
    IntroJs.introJs().exit();
  }
};
