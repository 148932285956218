import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.charges.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns()
    });
  },

  columns() {
    return [
      {
        class: 'type',
        data : 'type',
        width: 60
      },
      {
        class: 'code',
        data : 'code',
        width: 100
      },
      {
        class: 'name',
        data : 'name'
      },
      {
        class   : 'belongs text-right',
        data    : 'belongs',
        sortable: false
      },
      {
        class   : 'students text-right',
        data    : 'students',
        sortable: false
      },
      {
        class   : 'lectures text-right',
        data    : 'lectures',
        sortable: false
      },
      {
        class   : 'details text-center',
        data    : 'show_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.showButton(this, val);
        }
      }
    ];
  }
}));
