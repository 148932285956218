import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View from '../../views/manage/plan_target';

const Controller = RestController.extend({
  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/plan_targets/:id/edit': 'edit',
    ':tenant/manage/plan_targets/:id'     : 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
