import { View } from 'backbone.marionette';
import Utils      from '../../../../utils';
import ShowRating from '../../_layouts/show_rating';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    uploadField : '#js-upload',
    ratingFields: '.js-rating-field'
  },

  onRender() {
    this.bindFileInput();
    this.bindRating();
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['csv']
    });
  },

  bindRating() {
    this.ui.ratingFields.each((_index, el) => {
      (new ShowRating({ el: `#${el.id}` })).render();
    });
  }
});
