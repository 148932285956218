import { View } from 'backbone.marionette';

export default View.extend({
  template: false,

  ui: {
    detailsCocoon   : '.js-details-cocoon_body',
    detailsEntryType: '.js-details-select-entry_type'
  },

  events: {
    'cocoon:after-insert @ui.detailsCocoon': 'onDetailsAfterInsert'
  },

  onDetailsAfterInsert() {
    this._setEntryType();
    this.render();
  },

  _setEntryType() {
    const $lastField = this.ui.detailsCocoon.find('.nested-fields').last();
    const $targetText = $lastField.find('.js-entry_type_text');
    const $targetValue = $lastField.find('.js-entry_type');
    $targetText.text(this.ui.detailsEntryType.find(':selected').text());
    $targetValue.val(this.ui.detailsEntryType.selectpicker('val'));
  }
});
