import $ from 'jquery';
import 'jquery-ujs';
import 'jquery-ui/sortable'; // eslint-disable-line
import 'jquery.cookie';
import 'bootstrap-sass';
import 'bootstrap-select';
import 'bootstrap-fileinput';
import 'bootstrap-star-rating';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.ja';
import 'touchclick';
import 'block-ui';
import Backbone  from 'backbone';
import Extension from './extension';
import Sync      from './backbone/sync';
import App       from './backbone/app';

require('datatables.net-bs')(window, $);
require('datatables.net-scroller')(window, $);
require('datatables.net-select')(window, $);
require('datatables.net-rowgroup')(window, $);

const app = window.app = new App();

$(document).ready(() => {
  Backbone.$ = $;
  Backbone.sync = Sync;
  Extension.ready();
  app.start();
});

// chromeのBackbone-Debuggerと関連付け
if (window.__backboneAgent) window.__backboneAgent.handleBackbone(Backbone);
