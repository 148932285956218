import _ from 'underscore';
import { View } from 'backbone.marionette';
import jade from 'jade';

export default View.extend({
  template: jade.compile('!= html'),

  modelEvents: {
    change: 'render',
    sync  : 'onSync'
  },

  load() {
    if (!this.$el[0]) return;
    this.$el.block();
    this.model.fetch({
      url: this.$el.data('url')
    });
  },

  onSync() {
    this.$el.unblock();
  },

  // 与えられた html 文字列を tbody に描画する
  // @note html 描画と列削除の操作によるちらつきを抑えるため show, hide を行う
  attachElContent(html) {
    const pbody = this.$el.find('.panel-body');
    pbody.hide();
    pbody.html(html);
    this._removeUnusedColumns();
    pbody.show();
    return this;
  },

  // 描画済みの summary テーブルから使われてない列を削除する
  // @note 描画前の html は jquery による要素の探索ができないため描画後を前提とする
  _removeUnusedColumns() {
    _.each(['credit', 'gpa', 'rank'], (type) => {
      _.each(['term', 'total', 'year'], (range) => {
        this._removeUnusedColumn(type, range);
      });
    });
  },

  _removeUnusedColumn(type, range) {
    const $columns       = this.$el.find(`td.${type}.${range}`);
    const $smallHeader   = this.$el.find(`th.${type}.${range}`);
    const $largeHeader   = this.$el.find(`th.${type}[colspan]`);
    const currentColspan = $largeHeader.attr('colspan');

    if (_($columns).every(el => { return el.innerText.trim() === ''; })) {
      $columns.remove();
      $smallHeader.remove();
      $largeHeader.attr('colspan', currentColspan - $smallHeader.length);
    }
  }
});
