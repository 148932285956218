import { View } from 'backbone.marionette';
import ContentsTab from './contents_tab';
import CoverTab from './cover_tab';
import LogoTab from './logo_tab';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    (new ContentsTab()).render();
    (new CoverTab()).render();
    (new LogoTab()).render();
  }
});
