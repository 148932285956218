import { Object as MarionetteObject } from 'backbone.marionette';
import Achieve           from './student/achieve';
import Assessment        from './student/assessment';
import Dashboard         from './student/dashboard';
import Comment           from './student/comment';
import Map               from './student/map';
import Mind              from './student/mind';
import MindChart         from './student/mind_chart';
import PlanComment       from './student/plan_comment';
import PlanDream         from './student/plan_dream';
import Plan              from './student/plan';
import Generic           from './student/generic';
import GenericChart      from './student/generic_chart';
import ExamChart         from './student/exam_chart';
import Document          from './student/document';
import Activity          from './student/activity';
import SupportMemo       from './student/support_memo';
import TargetComment     from './student/target_comment';
import SummaryLrReview   from './student/summary_lr_review';
import LrSkill           from './student/lr_skill';
import Report            from './student/report';
import ReportText        from './student/report_text';

export default MarionetteObject.extend({
  initialize() {
    this.Achieve           = new Achieve();
    this.Assessment        = new Assessment();
    this.Comment           = new Comment();
    this.Dashboard         = new Dashboard();
    this.Map               = new Map();
    this.Mind              = new Mind();
    this.MindChart         = new MindChart();
    this.PlanComment       = new PlanComment();
    this.PlanDream         = new PlanDream();
    this.Plan              = new Plan();
    this.Generic           = new Generic();
    this.GenericChart      = new GenericChart();
    this.ExamChart         = new ExamChart();
    this.Document          = new Document();
    this.Activity          = new Activity();
    this.SupportMemo       = new SupportMemo();
    this.TargetComment     = new TargetComment();
    this.SummaryLrReview   = new SummaryLrReview();
    this.LrSkill           = new LrSkill();
    this.Report            = new Report();
    this.ReportText        = new ReportText();
  }
});
