// sidebarの制御
// ただし記録するだけで反映は行わなず(jsでの反映は挙動が遅れる)html側でクラスに付与すること
// --例--------------------------------------------------------------
// nav.asm-sidebar.collapse(class="#{cookies['ui-sidebar'] || :in}")
// main.with-asm-sidebar(class=cookies['ui-main'])
// ------------------------------------------------------------------
import $ from 'jquery';

export default {
  options: {
    path   : '/',
    expires: 10 * 365
  },

  ready() {
    this.bindCollapse();
    this.bindToggle();
    this.bindCurrent();
    this.bindCacheParams();
  },

  // 開閉状態の保存イベントを定義
  bindCollapse() {
    $(document).on('shown.bs.collapse', 'nav.asm-sidebar', () => {
      $('main').removeClass('with-asm-sidebar-collapsed');
      $.cookie('ui-sidebar', 'in', this.options);
      $.cookie('ui-main', '', this.options);
    });

    $(document).on('hide.bs.collapse', 'nav.asm-sidebar', () => {
      $('main').addClass('with-asm-sidebar-collapsed');
      $.cookie('ui-sidebar', '', this.options);
      $.cookie('ui-main', 'with-asm-sidebar-collapsed', this.options);
    });
  },

  // 開閉ボタンのイベント
  bindToggle() {
    $(document).on('click', 'button#js-header-toggle', () => {
      $('nav.asm-sidebar').toggleClass('mobile-shown');
      // サイドバーの裏に隠れた画面がスクロールしないようにする。
      $('body').toggleClass('disable-scroll');
    });
  },

  // sidebarはキャッシュするため変動する要素を描画してはいけない
  // javascript側で現在の機能をハイライトする
  bindCurrent() {
    if (!window.gon.target_app) return;
    const key = `-${window.gon.target_app}-`;
    $(`li[data-tree*='${key}']`).addClass('current');
  },

  // sidebarはキャッシュするため変動する値を描画してはいけない
  // javascript側でパラメータを入れ、cssの待ち状態を解除する
  bindCacheParams() {
    $('nav.asm-sidebar a[href]').each((_i, link) => {
      const $link = $(link);
      const url =
        $link.attr('href')
             .replace(':student_id', gon.target_entity_id)
             .replace(':target_code', gon.target_entity_id); // 旧キャッシュ考慮
      $link.attr('href', url);
    });
    $('.asm-sidebar-menu').removeClass('wating');
  }
};
