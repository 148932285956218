import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/mentor/lecture_feedback';

const Controller = RestController.extend({
  edit(_tenant, _lectureId, surveyId) {
    (new View.Edit({ surveyId: surveyId })).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/mentor/lectures/:lecture_id/surveys/:survey_id/feedback/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
