import $ from 'jquery';
import { View } from 'backbone.marionette';

// 画面をリロード・リダイレクト・バリデーションエラー等の遷移が
// 発生した場合に前回表示していたタブの位置を維持する処理
export default View.extend({
  template: false,

  ui: {
    form   : '.js-form',
    formTab: '.js-form-tab'
  },

  events: {
    'submit @ui.form'                  : 'onSubmitForm',
    'click @ui.formTab'                : 'onClickFormTab',
    'shown.bs.tab @ui.formTab > li > a': 'onShownTab'
  },

  onRender() {
    this._switchTab();
  },

  submittedFlg: false,
  onSubmitForm(e) {
    if (this.submittedFlg === false) {
      this.submittedFlg = true;
      e.preventDefault();
      this._setAnchor();
      this.$(e.target).submit();
    }
  },

  _setAnchor() {
    const hash = window.location.hash;
    const id = hash.substr(1);
    const anchor = $('<input>').attr({ type: 'hidden', name: 'anchor', value: id });
    this.ui.form.append(anchor);

    // バリデーションエラー表示考慮
    this.ui.form.attr('action', (_, path) => { return `${path}#${id}`; });
  },

  onClickFormTab(e) {
    e.preventDefault();
    $(e.target).tab('show');
    this._scrollTop();
  },

  onShownTab(e) {
    const id = $(e.target).attr('href').substr(1);
    window.location.hash = id;
  },

  _switchTab() {
    const hash = window.location.hash;
    this.ui.formTab.find(`a[href="${hash}"]`).tab('show');
    this._scrollTop();
  },

  // 画面下部へスクロールがずれるので調整
  _scrollTop() {
    $(window).scrollTop(0);
  }
});
