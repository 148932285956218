import $ from 'jquery';
import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View from '../../views/manage/syllabus_schedule';

const Controller = RestController.extend({
  index() {
    /*
     * 一括更新でバリデーションに失敗した場合は
     * edit の画面ながら URL が index のものになる。
     */
    if ($('main').hasClass('update_all')) {
      this.edit();
      return;
    }

    (new View.Index()).render();
  },

  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/syllabus_schedules'           : 'index',
    ':tenant/manage/syllabus_schedules/:id(/edit)': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
