import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/student/report';

const Controller = RestController.extend({
  show() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/reports/:id': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
