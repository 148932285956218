import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import UntreatedView from './untreated';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.copy_transfers.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableTooltipCell
  ],

  onRender() {
    this.UntreatedView = (new UntreatedView()).render();

    this.initDataTables({
      columns: this.columns(),
      rowId  : 'code',
      order  : [[0, 'desc']]
    });

    Utils.Opener.set('CopyTransferDatatable', this);
  },

  columns() {
    return [{
      data : 'code',
      class: 'code',
      width: 100
    }, {
      data : 'display_code',
      class: 'display_code',
      width: 100
    }, {
      data    : 'name',
      class   : 'name',
      sortable: false
    }, {
      data    : 'old_code',
      class   : 'code',
      sortable: false,
      width   : 100
    }, {
      data    : 'belong',
      class   : 'belong',
      sortable: false
    }, {
      data    : 'status',
      class   : 'status text-center',
      sortable: false,
      width   : 80
    }, {
      class   : 'entry text-center',
      data    : 'new_path',
      sortable: false,
      width   : 80,
      render  : (val) => {
        if (!val) return '';

        return Utils.Render.linkButton(this.t('.new'), val, val, 'btn-primary', 'fa-external-link');
      }
    }];
  },

  updateRow(row) {
    this.dataTable.row(`#${row.code}`).data(row);
  },

  refreshUntreated(untreated) {
    this.UntreatedView.refresh(untreated);
  }
}));
