import $ from 'jquery';
import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : 'main.index',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabus_lectures.index'),

  mixins: [Mixins.DataTables],

  behaviors: [Behaviors.DataTableResizer],

  ui: {
    select2                   : '.js-select2',
    unlinkedHint              : '.js-unlinked-hint',
    errorMessage              : '.js-error-message',
    targetTab                 : '#js-app-tabs li.active',
    unlinkedHintDeplicateCodes: '.js-deplicate-lecture-names-link',
    linkedLecture             : '.js-linked-lecture',
    copiedSync                : '.js-copied-sync',
    fiscalYear                : '.js-fiscal_year'
  },

  events: {
    'click @ui.unlinkedHint'              : 'onClickUnlinkedHint',
    'click @ui.unlinkedHintDeplicateCodes': 'onClickUnlinkedHintDeplicateCodes',
    'click @ui.copiedSync'                : 'onClickCopiedSync'
  },

  onRender() {
    Utils.Select2.ajax(this.ui.select2, { allowClear: true });
    this.initDataTables({
      preDrawCallback: () => {
        this.bindUIElements();

        // 検索直後にポップアップを非表示
        this.ui.unlinkedHint.popover('hide');

        // スクロール後にポップアップを非表示
        $('.dataTables_scrollBody').off('scroll.unlinked_hint_popover');
        $('.dataTables_scrollBody').on('scroll.unlinked_hint_popover', () => {
          this.ui.unlinkedHint.popover('hide');
        });
      },
      rowId  : 'edit_path',
      columns: this.columns(),
      order  : [[0, 'desc'], [2, 'asc'], [1, 'asc']]
    });
    Utils.Opener.set('SyllabusLectureDatatable', this);
  },

  onReset() {
    this.ui.select2.val(null).trigger('change');
  },

  onClickUnlinkedHint(e) {
    const $target = $(e.currentTarget);
    if ($target.attr('data-shown')) {
      $target.popover('toggle');
      return;
    }

    const id = `popover-${$target.data('uniqKey')}`;
    $target.popover({
      trigger  : 'manual',
      placement: 'top',
      html     : true,
      container: this.el,
      content  : this.popoverContent(id)
    });
    $target.popover('show');

    $.get($target.data('url')).done(data => {
      $target.attr('data-shown', true);
      $(`#${id}`).html(data.text).on('mouseleave', () => {
        // カーソルがpopoverから離れたら自動で非表示
        $target.trigger('click');
      });
      $target.popover('show');
      this.bindUIElements();
    });
  },

  popoverContent(id) {
    return $('<div>')
      .attr('id', id)
      .html('<i class="fa fa-spinner fa-spin"></i>');
  },

  onClickUnlinkedHintDeplicateCodes(e) {
    const $target = $(e.currentTarget);
    this.ui.linkedLecture.val($target.data('codes'));
    this.reload();
  },

  onClickCopiedSync(e) {
    const $target = this.$(e.currentTarget);

    this.reload(() => {
      const fiscalYear = this.ui.fiscalYear.selectpicker('val');
      if (fiscalYear === '') {
        Utils.Modal.alert(this.t('.select_fiscal_year'));
        return;
      }

      const creationUrl = this.appendSearchParamsWithoutPagingToUrl($target.data('creationPath'));

      Utils.Modal.confirm(
        this.t('.update_syllabuses_sync', {
          target_fiscal_year: fiscalYear
        }),
        (flg) => {
          if (!flg) return;

          window.open(creationUrl, '_blank');
        }
      );
    });
  },

  columns() {
    return [
      {
        class: 'fiscal_year',
        data : 'fiscal_year',
        width: 80
      },
      {
        class: 'open_terms',
        data : 'open_terms',
        width: 80
      },
      {
        class: 'subject',
        data : 'subject',
        width: 200
      },
      {
        class   : 'variable_code',
        data    : 'variable_code',
        sortable: false,
        width   : 80
      },
      {
        class   : 'editors',
        data    : 'editors',
        sortable: false
      },
      {
        class   : 'instructors',
        data    : 'instructors',
        sortable: false
      },
      {
        class   : 'sync_status text-center',
        data    : 'sync_status',
        width   : 80,
        sortable: false
      },
      {
        class   : 'sync_lecture',
        data    : 'sync_lecture',
        width   : 200,
        sortable: false
      },
      {
        class   : 'text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 40,
        render  : (val, type, row) => {
          if (row.lecture_sync === 'disabled') return '';
          return Utils.Render.editButton(this, val, val);
        }
      }
    ];
  },

  updateRow(row) {
    this.dataTable.row(`#${row.edit_path}`).data(row);
  },

  updateError(message, tab) {
    this.ui.errorMessage.html(message);
    this.ui.targetTab.html(tab);
  }
})
);
