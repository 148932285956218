import $ from 'jquery';
import _ from 'underscore';
import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.mentor.comments.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  ui: {
    guidance_by: '#guidance_by',
    stateFiled : '#by_state'
  },

  events: {
    'change @ui.guidance_by': 'reload'
  },

  onRender() {
    this.initDataTables({
      columns : this.columns(),
      sortable: false,
      order   : [[0, 'desc']]
    });
    this.dataTable.on('draw.dt.bindUnread', _.bind(this.bindUnread, this));
  },

  // コメント閲覧は別タブ（学生画面の該当記事閲覧）になるため js で既読の反映が必要
  bindUnread() {
    // 既読コメントの閲覧中は反映不要
    if (this.ui.stateFiled.val() === 'READ') return;

    // ロード後の未読結果に応じて未読通知のONOFFを行う
    $('.js-comment-unread').toggleClass('hidden', this.dataTable.data().count() === 0);

    // Cell上のボタンなのでロードの度にイベントを貼り直す必要がある
    this.ui.dataTables.find('a').on('click', () => {
      // 閲覧（既読化処理）後に、既読反映のため再読込
      setTimeout(_.bind(this.reload, this), 3000);
    });
  },

  columns() {
    return [
      {
        class: 'commented_at',
        data : 'commented_at',
        width: 45
      },
      {
        class   : 'sender',
        data    : 'sender',
        sortable: false,
        width   : 120
      },
      {
        class   : 'text',
        data    : 'text',
        sortable: false
      },
      {
        class   : 'receiver',
        data    : 'receiver',
        sortable: false,
        width   : 120
      },
      {
        class   : 'name',
        data    : 'name',
        sortable: false,
        width   : 200
      },
      {
        class   : 'path',
        data    : 'path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.showButton(this, val, val);
        }
      }
    ];
  }
}));
