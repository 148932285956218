import _ from 'underscore';

export default {
  // $form.serializeArray() の結果をオブジェクト化する。
  serializeObject($form) {
    const object = {};

    _($form.serializeArray()).each((item) => {
      if (_.isEmpty(item.value)) return;
      if (object[item.name]) {
        if (!_.isArray(object[item.name])) {
          object[item.name] = [object[item.name]];
        }
        object[item.name].push(item.value);
      } else {
        object[item.name] = item.value;
      }
    });

    return object;
  }
};
