import Readmore    from './extension/readmore';
import Autosize    from './extension/autosize';
import Anchor      from './extension/anchor';
import FlashNotify from './extension/flash_notify';
import Sidebar     from './extension/sidebar';
import Rambulance  from './extension/rambulance';
import BlockUI     from './extension/blockui';
import Confirm     from './extension/confirm';
import UnloadAlert from './extension/unload_alert';
import IntroJS     from './extension/introjs';
import StarRating  from './extension/star_rating';
import Clipboard   from './extension/clipboard.js';
import PopupLink   from './extension/popup_link';
import EnterKey    from './extension/enter_key';
import Tooltip     from './extension/tooltip';
import Checkbox    from './extension/checkbox';
import Radio       from './extension/radio';
import Window      from './extension/window';

export default {
  Readmore,
  Autosize,
  Anchor,
  FlashNotify,
  Sidebar,
  Rambulance,
  BlockUI,
  Confirm,
  UnloadAlert,
  StarRating,
  Clipboard,
  PopupLink,
  EnterKey,
  Tooltip,
  Checkbox,
  Radio,
  Window,

  // 高さの変動系はスクロール位置を調整するAnchorの前に有効化すること
  ready() {
    Readmore.ready();
    Autosize.ready();
    FlashNotify.ready();
    Sidebar.ready();
    Anchor.ready();
    Rambulance.ready();
    BlockUI.ready();
    Confirm.ready();
    UnloadAlert.ready();
    IntroJS.ready();
    StarRating.ready();
    Clipboard.ready();
    PopupLink.ready();
    EnterKey.ready();
    Tooltip.ready();
    Checkbox.ready();
    Radio.ready();
    Window.ready();
  }
};
