import Index from './lr_skill_sets/index';
import Form  from './lr_skill_sets/form';
import Show  from './lr_skill_sets/show';

export default {
  Index: Index,
  Edit : Form,
  New  : Form,
  Show : Show
};
