import { View }  from 'backbone.marionette';
import Mixins    from '../../../mixins';
import Utils     from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.mentor.generics.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  ui: {
    guidance_by: '#guidance_by'
  },

  events: {
    'change @ui.guidance_by': 'reload'
  },

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc'], [2, 'desc']]
    });
    Utils.Opener.set('GenericTargetDatatable', this);
  },

  columns() {
    return [{
      class: 'answer_year text-center',
      data : 'answer_year',
      width: 90
    }, {
      class: 'curriculum_year',
      data : 'curriculum_year',
      width: 120
    }, {
      class: 'schedule',
      data : 'schedule',
      width: 70
    }, {
      class: 'belong',
      data : 'belong'
    }, {
      class   : 'last-evaluation-at',
      data    : 'last_evaluation_at',
      sortable: false,
      width   : 150
    }, {
      class   : 'evaluation-rate',
      data    : 'evaluation_rate',
      sortable: false,
      width   : 80,
      render  : (val) => {
        const percentage = Math.floor(val);
        if (percentage === 0) {
          return this.t('.zero');
        } else if (percentage < 100) {
          return this.t('.imperfect');
        }
        return this.t('.perfect');
      }
    }, {
      class   : 'edit-path',
      data    : 'edit_path',
      sortable: false,
      width   : 70,
      render  : (val) => {
        return Utils.Render.editButton(this, val, val);
      }
    }];
  }
}));
