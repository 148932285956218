import $ from 'jquery';
import _ from 'underscore';
import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#js-analysis-runner-area',
  template: false,

  onRender() {
    const status = this.$el.data('status');
    this.delayReloadRunnerBy(status);
  },

  // JOB待ちまたはJOB実行中ならば5秒後に読み込み
  delayReloadRunnerBy(status) {
    if (status === 'waiting' || status === 'running') {
      setTimeout(_.bind(this.loadRunner, this), 5000);
    }
  },

  loadRunner() {
    const url = this.$el.data('url');

    $.ajax({ url })
     .done((data) => {
       this.$el.html(data.html);
       this.delayReloadRunnerBy(data.status);
     });
  }
});
