// Cell上に Utils.Render.deleteButton で削除ボタンを描画していることが前提
// その押したボタンの行のデータ1件を削除する際の挙動
import _ from 'underscore';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    dataTables: '.js-dataTables'
  },

  onRender() {
    // Cell上のボタンなのでロードの度にイベントを貼り直す必要がある
    this.view.dataTable.on('draw.dt.bindLoad', _.bind(this.bindLoad, this));
  },

  // 削除処理(jquery.ujs)が成功時には反映のためリロードする
  bindLoad() {
    const $deleteCell = this.ui.dataTables.find('a[data-method="delete"]');
    $deleteCell.on('ajax:success', _.bind(this.view.reload, this.view));
  }
});
