import { Object as MarionetteObject } from 'backbone.marionette';
import History from './port/history';
import Belong  from './port/belong';
import Student from './port/student';
import Mentor  from './port/mentor';
import Subject from './port/subject';
import Lecture from './port/lecture';
import License from './port/license';
import DesiredLicense from './port/desired_license';
import Record  from './port/record';
import PastRecord from './port/past_record';
import StudyTarget from './port/study_target';
import Rubric from './port/rubric';
import ChargeStudent from './port/charge_student';
import ChargeBelong from './port/charge_belong';
import ChargeLecture from './port/charge_lecture';
import RecordSummary from './port/record_summary';
import PastRecordSummary from './port/past_record_summary';
import Email from './port/email';

export default MarionetteObject.extend({
  initialize() {
    this.History = new History();
    this.Belong  = new Belong();
    this.Student = new Student();
    this.Mentor  = new Mentor();
    this.Subject = new Subject();
    this.Lecture = new Lecture();
    this.License = new License();
    this.DesiredLicense = new DesiredLicense();
    this.Record  = new Record();
    this.PastRecord = new PastRecord();
    this.StudyTarget = new StudyTarget();
    this.Rubric = new Rubric();
    this.ChargeStudent = new ChargeStudent();
    this.ChargeBelong = new ChargeBelong();
    this.ChargeLecture = new ChargeLecture();
    this.RecordSummary = new RecordSummary();
    this.PastRecordSummary = new PastRecordSummary();
    this.Email = new Email();
  }
});
