import $ from 'jquery';
import _ from 'underscore';
import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import nl2br from '../../../../utils/nl2br';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabuses.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableDropAllow,
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow,
    Behaviors.SyllabusBasicFieldBuilder
  ],

  ui: {
    select2         : '.js-select2',
    updateStatuses  : '.js-update-statuses',
    pdfExportBtn    : '.js-pdf-export',
    csvExportBtn    : '.js-csv-export',
    fiscalYear      : '.js-fiscal_year',
    syllabusTemplate: 'select.js-syllabus-template',
    belong          : '.js-belong'
  },

  events: {
    'click @ui.updateStatuses': 'onClickUpdateStatuses',
    'click @ui.pdfExportBtn'  : 'onClickPdfExport',
    'click @ui.csvExportBtn'  : 'onClickCsvExport'
  },

  onRender() {
    Utils.Select2.ajax(this.ui.select2, { allowClear: true });
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc'], [2, 'asc'], [1, 'asc'], [3, 'asc']]
    });
    Utils.Opener.set('SyllabusDatatable', this);
  },

  onReset() {
    this.ui.select2.val(null).trigger('change');
  },

  onClickUpdateStatuses(e) {
    e.preventDefault();

    const $link = this.$(e.currentTarget);

    this.reload(() => {
      const url = this.appendSearchParamsWithoutPagingToUrl($link.prop('href'));

      $.ajax({ url })
        .then(({ total_count: count }) => {
          if (count === 0) {
            Utils.Modal.alert(
              this.t('.alert.no_syllabus', {
                status_from: $link.data('status-from')
              })
            );
            return;
          }

          Utils.Modal.confirm(
            this.t('.confirm.update_statuses', {
              count,
              status_from: $link.data('status-from'),
              status_to  : $link.data('status-to')
            }),
            (flg) => {
              if (!flg) return;

              $.ajax({
                url,
                method: 'PATCH'
              })
                .then(this.reload.bind(this));
            }
          );
        });
    });
  },

  onClickPdfExport() {
    if (!this._validate()) { return; }

    const path = this.ui.pdfExportBtn.data('path');
    this._request(path, 'get');
  },

  onClickCsvExport() {
    if (!this._validate()) { return; }

    const path = this.ui.csvExportBtn.data('path');
    this._request(path, 'post');
  },

  _validate() {
    const errorMessages = [];

    // 作成件数削減のため開講年度必須。
    if (this.ui.fiscalYear.val() === '') {
      errorMessages.push(this.t('.alert.required_fiscal_year'));
    }

    if (!_(errorMessages).isEmpty()) {
      Utils.Modal.alert(errorMessages.join('<br>'));
      return false;
    }

    return true;
  },

  _request(path, method) {
    this
      .reloadAsync()
      .then(() => {
        const url = this.appendSearchParamsWithoutPagingToUrl(path);
        const $a = $(
          '<a>',
          { href: url, target: '_blank', 'data-method': method }
        );

        $.rails.handleMethod($a);
      });
  },

  columns() {
    const menu = _.template(this.$('#menu').html())();

    return [
      {
        class: 'fiscal_year',
        data : 'fiscal_year',
        width: 80
      },
      {
        class: 'open_terms',
        data : 'open_terms',
        width: 80
      },
      {
        class     : 'subjects',
        data      : 'subjects',
        columnName: 'subjects.code',
        width     : 200
      },
      {
        class: 'variable_code',
        data : 'variable_code',
        width: 80
      },
      {
        class   : 'belong',
        data    : 'belong',
        width   : 250,
        sortable: false
      },
      {
        class   : 'editors',
        data    : 'editors',
        sortable: false
      },
      {
        class   : 'instructors',
        data    : 'instructors',
        sortable: false
      },
      {
        class: 'status text-center',
        data : 'status',
        width: 40
      },
      {
        class: 'inputted_at',
        data : 'inputted_at',
        width: 42
      },
      {
        class   : 'analysis text-center',
        data    : 'id',
        sortable: false,
        width   : 80,
        render  : (val, _type, row) => {
          const syllabus =
            [row.fiscal_year, row.open_terms, row.subjects, row.variable_code].join(', ');
          const message = nl2br(`${syllabus}\r${this.t('.confirm.delete')}`);
          return menu.replace(/:id/g, val).replace(':confirm', message);
        }
      }
    ];
  }
}));
