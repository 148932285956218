import { View } from 'backbone.marionette';
import Mixins   from '../../../mixins';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns()
    });
  },

  columns() {
    return [
      {
        class: 'code',
        data : 'display_code',
        width: 80
      },
      {
        class: 'name',
        data : 'name'
      },
      {
        class: 'grade text-right',
        data : 'grade',
        width: 50
      },
      {
        class: 'belong',
        data : 'belong'
      },
      {
        class: 'status text-center',
        data : 'status',
        width: 60
      },
      {
        // Datatableを基本検索と使い回すためカラムは必要だが
        // この教職員として閲覧という勘違い回避のため隠す(本人の権限で閲覧になる)
        class   : 'details text-center',
        data    : 'show_path',
        sortable: false,
        visible : false,
        width   : 80
      }
    ];
  }
}));
