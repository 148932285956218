import { Collection } from 'backbone';
import $ from 'jquery';
import Record from '../models/assessment';

export default Collection.extend({
  model: Record,

  url() {
    return $('.asm-assessment-list').data('url');
  }
});
