import Backbone from 'backbone';
import _ from 'underscore';

Backbone._sync = Backbone.sync;

export default function (method, model, options) {
  // 本来は全カラムのデータを階層なしで通信してしまうため
  // { id: 1, level: 5, item_no: 1, name: '◯◯を達成する力' }
  // のように参照用や表示用のカラムまで通信されてしまう
  //
  // 無駄が多く、IDはURLに含まれるので不要でありカラムを削りたく
  // Railsの params.require で使いやすいよう階層化したいので自由に加工できるようにする
  // { assessment: { level: 5 } }
  //
  // -- 使い方 ------------------------------------------------------------------
  // export default Model.extend({
  //   urlRoot: `/hoge/assessments/`,
  //
  //   toSyncJson() {
  //     return { assessment: { level: this.get('level') } };
  //   }
  // });
  // ---------------------------------------------------------------------------
  // 公式：https://github.com/jashkenas/backbone/blob/1.2.3/backbone.js#L1373
  if (!options.data && model && model.toSyncJson
      && (method === 'create' || method === 'update' || method === 'patch')) {
    _.extendOwn(options, {
      contentType: 'application/json',
      data       : JSON.stringify(options.attrs || model.toSyncJson(options))
    });
  }
  return Backbone._sync(method, model, options);
}
