import $ from 'jquery';
import _ from 'underscore';
import { View } from 'backbone.marionette';

const BtnWithLogsView = View.extend({
  template: false,

  onRender() {
    this.loadLogs();
  },

  // JOB待ちまたはJOB実行中ならば5秒後に読み込み
  delayReloadLogsBy(isExporting) {
    if (isExporting) {
      setTimeout(_.bind(this.loadLogs, this), 5000);
    }
  },

  loadLogs() {
    const url = this.$el.data('url');

    $.ajax({ url })
     .done((data) => {
       this.$el.html(data.html);
       this.delayReloadLogsBy(data.isExporting);
     });
  }
});

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    area: '.js-btn-with-log-area'
  },

  onRender() {
    this.ui.area.each((_index, el) => {
      (new BtnWithLogsView({ el: this.$(el) })).render();
    });
  }
});
