import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    form  : 'form',
    field : 'input#user_locale',
    button: 'button'
  },

  events: {
    'touchclick @ui.button': 'changeLocale'
  },

  changeLocale(e) {
    const locale = this.$el.find(e.currentTarget).data('locale');
    this.ui.field.val(locale);
    this.ui.form.block();
    this.ui.form.submit();
  }
});
