import generateRouter from '../generate_router';
import View from '../../views/manage/lr_skill_set';

const appRoutes = {
  ':tenant/manage/lr_skill_sets'         : 'index',
  ':tenant/manage/lr_skill_sets/new'     : 'new',
  ':tenant/manage/lr_skill_sets/:id/edit': 'edit',
  ':tenant/manage/lr_skill_sets/:id'     : 'show'
};

export default generateRouter(View, appRoutes);
