import $ from 'jquery';

const myDefaultWhiteList = $.fn.tooltip.Constructor.DEFAULTS.whiteList;
const tableDefaultTags = ['table', 'tbody', 'tr', 'th', 'td'];

export default {
  setTable(attributes) {
    this._addTableDefaultTags();

    Object.keys(attributes).forEach((tag) => {
      // 引き渡された tag の属性を設定。
      // ※tableDefaultTags で指定された HTML タグ のみ属性指定可能。
      //   属性は指定された内容をそのまま設定。（属性名のチェックは行っていない。）
      if (tag in myDefaultWhiteList) {
        myDefaultWhiteList[tag] = attributes[tag];
      }
    });
  },

  _addTableDefaultTags() {
    for (const tag of tableDefaultTags) {
      myDefaultWhiteList[tag] = [];
    }
  }
};
