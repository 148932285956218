// Railsのremove_button_tagで描画したタグが前提
// 検索対象を一括で削除する挙動
import $ from 'jquery';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    removeBtn: '.js-remove-btn'
  },

  events: {
    'click @ui.removeBtn'       : 'reload',
    'ajax:before @ui.removeBtn' : 'onBeforeRemove',
    'ajax:success @ui.removeBtn': 'reload'
  },

  // 削除通信に検索条件を追加する
  // 尚、data-*** はキャッシュされるので毎回更新するためにはremoveDataでの消去が必要
  onBeforeRemove() {
    this.ui.removeBtn.removeData('params');
    this.ui.removeBtn.data('params', $.param(this.view.getSearchParams()));
  },

  reload() {
    this.view.reload();
  }
});
