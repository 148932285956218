const ua = window.navigator.userAgent;

export default {
  isIE11() {
    return ua.indexOf('Trident/7') !== -1;
  },

  isEdge() {
    return ua.indexOf('Edge') !== -1;
  }
};
