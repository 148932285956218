import _ from 'underscore';
import { View } from 'backbone.marionette';
import Sortable from 'sortablejs';
import Utils from '../../../../utils';

export default View.extend({
  el      : 'table#js-settings',
  template: false,

  ui: {
    existingSelect2: '.js-select2',
    node           : '.js-node',
    nodeList       : '.js-node-list'
  },

  events: {
    'cocoon:after-insert @ui.node'    : 'onAfterInsertNode',
    'cocoon:after-remove @ui.nodeList': 'onAfterRemoveNode'
  },

  initialize() {
    this.bindUIElements();
    this._setNos();
    this._applySortable();
    Utils.Select2.ajax(this.ui.existingSelect2, { allowClear: true });
  },

  onAfterInsertNode(_event, $el) {
    this.render();
    this._setNos();
    Utils.Select2.ajax($el.find('.js-select2'), { allowClear: true });
  },

  onAfterRemoveNode() {
    this._setNos();
  },

  _setNos() {
    const $rows = this.ui.nodeList.find('tr.nested-fields:visible');

    _($rows).each((row, i) => {
      const $row = this.$(row);
      $row.find('.js-hidden-no').val(i + 1);
    });
  },

  _applySortable() {
    if (this.ui.nodeList.length > 0) {
      Sortable.create(this.ui.nodeList.get(0), {
        handle  : 'th.no',
        onUpdate: () => {
          this._setNos();
        }
      });
    }
  }
});
