import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.student.comments.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns : this.columns(),
      sortable: false,
      order   : [[0, 'desc']]
    });
  },

  columns() {
    return [
      {
        class: 'commented_at',
        data : 'commented_at',
        width: 45
      },
      {
        class   : 'sender',
        data    : 'sender',
        sortable: false,
        width   : 120
      },
      {
        class   : 'text',
        data    : 'text',
        sortable: false
      },
      {
        class   : 'name',
        data    : 'name',
        sortable: false,
        width   : 200
      },
      {
        class   : 'path',
        data    : 'path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.showButton(this, val);
        }
      }
    ];
  }
}));
