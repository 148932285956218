import $              from 'jquery';
import _              from 'underscore';
import { View }       from 'backbone.marionette';
import Utils          from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.student.support_memos.index'),

  regions: {
    supportMemos: '.js-support-memos-region'
  },

  ui: {
    select2     : '.js-select2',
    searchForm  : 'form',
    searchButton: 'button[type="submit"]',
    resetButton : '.js-reset-btn',
    deleteButton: '.js-delete-button'
  },

  events: {
    'click @ui.searchButton'       : 'onClickSearch',
    'click @ui.resetButton'        : 'onClickReset',
    'click .paginate_button a'     : 'onClickPage',
    'ajax:success @ui.deleteButton': 'onAjaxSuccessDelete'
  },

  onRender() {
    this._bindSelect2();
    this.ui.searchButton.trigger('click');
  },

  onClickSearch(e) {
    e.preventDefault();
    e.stopPropagation();
    this._searchMemos();
  },

  onClickReset() {
    this.ui.searchForm.get(0).reset();
    this.ui.select2.trigger('change');
    this._searchMemos();
  },

  onClickPage(e) {
    e.preventDefault();

    const url = $(e.currentTarget).prop('href');
    this._fetchSupportMemos(url);
  },

  onAjaxSuccessDelete() {
    const url = this.$('.paginate_button.active > a').prop('href');
    this._fetchSupportMemos(url);
  },

  _bindSelect2() {
    const title = this.ui.select2.attr('title');

    Utils.Select2.bind(this.ui.select2, {
      width      : '200px',
      allowClear : true,
      placeholder: `<${title}>`
    });
  },

  _searchMemos() {
    const url = this.ui.searchForm.prop('action');
    const params = Utils.Form.serializeObject(this.ui.searchForm);
    this._fetchSupportMemos(url, params, { page: 1 });
  },

  _fetchSupportMemos(url, params, additionalParams = {}) {
    const data = _.extend({}, params, additionalParams);

    this.$('.js-support-memos-region').block();

    $.ajax({
      url     : url,
      data    : data,
      dataType: 'json'
    })
      .done(({ html }) => {
        /*
         * View の element はルート要素が 1 つである必要がある。
         * サーバ側でそれを意識したくないので、ここで div 要素でラップする。
         */
        const view = new View({ el: $(`<div>${html}</div>`) });

        this.showChildView('supportMemos', view);
        this.$('.js-support-memos-region').unblock();
      });
  }
});
