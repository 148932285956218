import { View } from 'backbone.marionette';
import _ from 'underscore';
import d3 from 'd3';
import ResultChart from './result_chart';
import ProcessChart from './process_chart';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  ui: {
    resultArea: '.js-generic-result-area'
  },

  onRender() {
    this.renderResult();
    this.renderProcess();
  },

  renderResult() {
    if (!this.ui.resultArea[0]) return;

    this.ui.resultArea.block();
    d3.json(this.ui.resultArea.data('url'), (error, data) => {
      this.ui.resultArea.unblock();

      if (error !== null) Utils.Modal.alert(error);
      if (_(data).isEmpty()) return;

      this.ui.resultArea.html(data.html);
      (new ResultChart()).render();
    });
  },

  renderProcess() {
    (new ProcessChart()).render();
  }
}));
