// Behaviors.StickyTableResizer の同時適用想定
import $ from 'jquery';
import _ from 'underscore';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    mapbarTab  : '#js-mapbar a',
    mapbarFirst: '#js-mapbar a:first'
  },

  initialize(_options, view) {
    this.view = view;
  },

  // 切り替え部品ごと読み込むため都度登録が必要
  onRender() {
    this.bindUIElements();
    this.ui.mapbarTab.on('shown.bs.tab', _.bind(this.shownMap, this));
    this.ui.mapbarFirst.tab('show');
  },

  shownMap(e) {
    const $map = $($(e.currentTarget).attr('href')).find('.js-map');
    if (!$map[0]) return;

    this.initializeTypeMap($map);
    this.view.trigger('resizeStickyTable');
  },

  // スマホやPCでの視認性を考慮して高さの固定(スクロール化)は行わず
  // 画面全体のスクロールで対応する
  initializeTypeMap($map) {
    const steps = $map.data('steps');
    if (!steps) return;

    $map.width(parseInt(steps, 10) * 175);
  }
});
