var pug = require("!../../../../../../../../../shared/node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Object, name, raty, raty_teacher, rubrics) {const nl2br = require('../../../../utils/nl2br').default;
pug_html = pug_html + "\u003Cth\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
if (raty_teacher > 0)
{
pug_html = pug_html + "\u003Ctd class=\"asm-selector asm-raty-teacher\"\u003E" + (pug.escape(null == (pug_interp = raty_teacher) ? "" : pug_interp)) + (pug.escape(null == (pug_interp = '.') ? "" : pug_interp)) + (pug.escape(null == (pug_interp = rubrics[raty_teacher]) ? "" : pug_interp)) + "\u003Cdiv class=\"asm-teacher-sign\"\u003E\u003Ci class=\"fa fa-lg fa-user\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
}
pug_html = pug_html + "\u003Ctd class=\"asm-selector\"\u003E";
if (raty > 0)
{
pug_html = pug_html + ((pug.escape(null == (pug_interp = raty) ? "" : pug_interp)) + (pug.escape(null == (pug_interp = '.') ? "" : pug_interp)) + (pug.escape(null == (pug_interp = rubrics[raty]) ? "" : pug_interp)));
}
else
{
pug_html = pug_html + (pug.escape(null == (pug_interp = '-') ? "" : pug_interp));
}
pug_html = pug_html + "\u003C\u002Ftd\u003E";
// iterate Object.keys(rubrics).sort().reverse()
;(function(){
  var $$obj = Object.keys(rubrics).sort().reverse();
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var raty = $$obj[pug_index0];
name = rubrics[raty]
if (raty_teacher == raty)
{
pug_html = pug_html + "\u003Ctd" + (" class=\"asm-rubric asm-raty-teacher\""+pug.attr("data-raty", raty, true, true)) + "\u003E" + (null == (pug_interp = nl2br(name)) ? "" : pug_interp) + "\u003Cdiv class=\"asm-teacher-sign\"\u003E\u003Ci class=\"fa fa-lg fa-user\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
}
else
{
pug_html = pug_html + "\u003Ctd" + (" class=\"asm-rubric\""+pug.attr("data-raty", raty, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var raty = $$obj[pug_index0];
name = rubrics[raty]
if (raty_teacher == raty)
{
pug_html = pug_html + "\u003Ctd" + (" class=\"asm-rubric asm-raty-teacher\""+pug.attr("data-raty", raty, true, true)) + "\u003E" + (null == (pug_interp = nl2br(name)) ? "" : pug_interp) + "\u003Cdiv class=\"asm-teacher-sign\"\u003E\u003Ci class=\"fa fa-lg fa-user\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Ftd\u003E";
}
else
{
pug_html = pug_html + "\u003Ctd" + (" class=\"asm-rubric\""+pug.attr("data-raty", raty, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Ftd\u003E";
}
    }
  }
}).call(this);
}.call(this,"Object" in locals_for_with?locals_for_with.Object:typeof Object!=="undefined"?Object:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"raty" in locals_for_with?locals_for_with.raty:typeof raty!=="undefined"?raty:undefined,"raty_teacher" in locals_for_with?locals_for_with.raty_teacher:typeof raty_teacher!=="undefined"?raty_teacher:undefined,"rubrics" in locals_for_with?locals_for_with.rubrics:typeof rubrics!=="undefined"?rubrics:undefined));;return pug_html;};
module.exports = template;