import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils  from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.exam_groups.edit'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableRemoveRows,
    {
      behaviorClass: Behaviors.DataTableResizer,
      offsetBottom : 20
    }
  ],

  ui: {
    uploadField: '#js-upload'
  },

  onRender() {
    this.bindFileInput();
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'asc'], [5, 'asc'], [4, 'asc'], [7, 'asc']]
    });
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['csv']
    });
  },

  columns() {
    return [
      {
        class: 'student_code',
        data : 'student_code',
        width: 100
      },
      {
        class: 'display_code',
        data : 'student_display_code',
        width: 100
      },
      {
        class: 'student_name',
        data : 'student_name'
      },
      {
        class: 'curriculum_year',
        data : 'curriculum_year',
        width: 85
      },
      {
        class: 'exam_day',
        data : 'exam_day',
        width: 70
      },
      {
        class: 'exam_day_group_ja',
        data : 'exam_day_group_ja'
      },
      {
        class: 'exam_day_group_en',
        data : 'exam_day_group_en'
      },
      {
        class: 'item',
        data : 'item'
      }
    ];
  }
}));
