// URLのアンカー指定でページの位置を指定する際に
// ヘッダー固定によるズレの対処とアニメーションを挟むため処理を追加する
import $ from 'jquery';

export default {
  headerMargin: 50 + 4,

  ready() {
    this.bindLinks();
    this.moveCurrent();
  },

  // 各アンカーリンクの処理を差し替える
  bindLinks() {
    $(document).on('click', "a[href^='#']:not([data-toggle])", (e) => {
      const $link = $(e.currentTarget);
      const href = $link.attr('href');
      const margin = parseInt($link.data('anchor') || 0, 10);
      this.scrollAnchor(href, margin);
    });
  },

  // 現在のアンカーへ移動する
  moveCurrent() {
    const $link = $(`a[href='${location.hash}']`);
    if ($link[0]) {
      $link.click();
    } else {
      const margin = parseInt($(location.hash).data('anchor') || 0, 10);
      this.scrollAnchor(location.hash, margin);
    }
  },

  // スクロール処理
  scrollAnchor(anchor, margin) {
    if (anchor === '#' || anchor === '') return;
    const $anchor = $(anchor);
    if (!$anchor[0]) return;

    const position = $anchor.offset().top - (this.headerMargin - margin);
    $('body,html').animate({ scrollTop: position }, 200, 'swing');
  }
};
