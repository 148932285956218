import { View } from 'backbone.marionette';
import Utils    from '../../../../utils';
import Mixins   from '../../../mixins';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  // 継承先で上書きするためここで Utils.I18n.bind で行わない
  i18nScope: 'views.port.base.index',

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableRemoveRows,
    Behaviors.DataTableResizer
  ],

  ui: {
    uploadField: '.js-upload',
    btnGroup   : '.btn-group'
  },

  onRender() {
    this.t = Utils.I18n.bind(this.i18nScope);
    this.initDataTables({
      columnDefs: [{ targets: '_all', sortable: false }],
      order     : []
    });

    this.ui.btnGroup.on('click', '[disabled]', () => {
      return false;
    });

    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['csv']
    });
  }
}));
