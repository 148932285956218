import _ from 'underscore';
import { AppRouter }  from 'backbone.marionette';
import RestController from '../controllers/rest';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function generateActions(View, appRoutes) {
  const actions = {};
  _.each(appRoutes, (actionName) => {
    const Klass = View[capitalizeFirstLetter(actionName)];
    actions[actionName] = function action() {
      if (Klass) {
        (new Klass()).render();
      }
    };
  });
  return actions;
}

// 与えられたパラメータに従って Router クラスを作成して返す
export default function generateRouter(View, appRoutes) {
  const actions = generateActions(View, appRoutes);
  const Controller = RestController.extend(actions);
  const Router = AppRouter.extend({
    appRoutes: appRoutes,
    initialize() {
      this.controller = new Controller();
    }
  });
  return Router;
}
