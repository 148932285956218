import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.ips.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc']]
    });
  },

  columns() {
    return [
      {
        data   : 'id',
        class  : 'id',
        visible: false
      },
      {
        data : 'address',
        class: 'address'
      },
      {
        data    : 'remarks',
        class   : 'remarks',
        sortable: false
      },
      {
        data    : 'edit_path',
        class   : 'edit text-center',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        data    : 'destroy_path',
        class   : 'destroy text-center',
        sortable: false,
        width   : 50,
        render  : (val) => {
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
