import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabus_charge_items.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc'], [2, 'asc'], [1, 'asc']]
    });
  },

  columns() {
    return [
      {
        class: 'fiscal_year',
        data : 'fiscal_year',
        width: 80
      },
      {
        class: 'open_terms',
        data : 'open_terms',
        width: 80
      },
      {
        class: 'subject_name',
        data : 'subject_name',
        width: 200
      },
      {
        class   : 'variable_code',
        data    : 'variable_code',
        sortable: false,
        width   : 80
      },
      {
        class   : 'belong',
        data    : 'belong',
        width   : 250,
        sortable: false
      },
      {
        class: 'status text-center',
        data : 'status',
        width: 40
      },
      {
        class: 'input_start_at',
        data : 'input_start_at',
        width: 80
      },
      {
        class: 'input_end_at',
        data : 'input_end_at',
        width: 80
      },
      {
        class: 'inputted_at',
        data : 'inputted_at',
        width: 42
      },
      {
        class   : 'text-center',
        data    : 'show_path',
        sortable: false,
        width   : 30,
        render  : (val) => {
          return Utils.Render.showButton(this, val, val);
        }
      }
    ];
  }
}));
