import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    closeBtn: '.js-close-btn'
  },

  onRender() {
    // 認証連携時に別タブで開かれていた場合に閉じるボタンを表示する
    // つまりwindow.closeが動かない画面遷移の場合に閉じるボタンを表示しない
    if (window.opener) this.ui.closeBtn.removeClass('hidden');
  }
});
