import RadarChart from '../../../shared/radar_chart';

export default RadarChart.extend({
  chartOptions: {
    layout: {
      padding: 0
    },
    scale: {
      ticks: {
        max     : 100,
        min     : 0,
        stepSize: 20
      }
    }
  }
});
