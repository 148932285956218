import $ from 'jquery';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  onRender() {
    this._scrollToError();
  },

  _scrollToError() {
    const $errors = this.$('.has-error');

    if ($errors.length === 0) return;

    const errorOffsetTop = $errors.first().offset().top;
    const headerHeight = $('.asm-header').height();

    $(window).scrollTop(errorOffsetTop - headerHeight);
  }
});
