import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.admin.delayed_jobs.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      order  : [[3, 'asc']],
      columns: this.columns()
    });
  },

  columns() {
    return [
      {
        class   : 'id',
        data    : 'id',
        sortable: false
      },
      {
        class: 'queue',
        data : 'queue'
      },
      {
        class: 'status text-center',
        data : 'status',
        width: 80
      },
      {
        class: 'created_at',
        data : 'created_at',
        width: 130
      },
      {
        class: 'run_at',
        data : 'run_at',
        width: 130
      },
      {
        class: 'failed_at',
        data : 'failed_at',
        width: 130
      },
      {
        class: 'attempts text-right',
        data : 'attempts',
        width: 50
      },
      {
        class   : 'details text-center',
        data    : 'show_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.showButton(this, val);
        }
      },
      {
        class   : 'queue text-center',
        data    : 'queue_path',
        sortable: false,
        width   : 55,
        render  : (val, type, row) => {
          if (!val) return '';
          return Utils.Render.remoteLinkTemplate({
            linkClass: 'btn btn-xs btn-warning',
            iconClass: 'fa-edit',
            text     : this.t('.queue'),
            confirm  : this.t('.confirm.queue', { job: row.id }),
            method   : 'post',
            href     : val
          });
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 55,
        render  : (val, type, row) => {
          if (!val) return '';
          return Utils.Render.remoteLinkTemplate({
            linkClass: 'btn btn-xs btn-danger',
            iconClass: 'fa-times',
            text     : this.t('.destroy'),
            confirm  : this.t('.confirm.delete', { job: row.id }),
            method   : 'delete',
            href     : val
          });
        }
      }
    ];
  }
}));
