import $ from 'jquery';

const checkboxSelector = 'input[type="checkbox"]:not(.hidden)';
const keyEventTarget = 'span.checkbox-icon';
const clickEventTarget = 'span.checkbox-icon:not(label > span.checkbox-icon)';
const spaceKeyCode = 32;

export default {
  bind(checkboxArea = 'body') {
    $(checkboxArea).find(checkboxSelector).after('<span class="checkbox-icon" tabindex="0">');
    $(document).off('click.checkbox');
    $(document).off('keydown.checkbox');
    $(document).on('click.checkbox', clickEventTarget, this.onClick);
    $(document).on('keydown.checkbox', keyEventTarget, this.onKeydown);
  },

  onClick(e) {
    $(e.target).prev(checkboxSelector).trigger('click');
  },

  // スペースキーでチェックを入れる
  onKeydown(e) {
    if (e.keyCode === spaceKeyCode) {
      $(e.target).trigger('click');
      return false;
    }
    return true;
  }
};
