var pug = require("!../../../../../../../../../shared/node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (I18n, hasRatyTeacher) {const languages = I18n.t('frontend.backbone.templates.student.assessments');
if (hasRatyTeacher)
{
pug_html = pug_html + "\u003Cdiv class=\"assessment\"\u003E\u003Cdiv class=\"asm-teacher-sign\"\u003E\u003Ci class=\"fa fa-lg fa-user\"\u003E\u003C\u002Fi\u003E" + (pug.escape(null == (pug_interp = languages.raty_teacher) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ctable class=\"table table-bordered table-striped asm-assessment-ratings asm-responsive-table\"\u003E\u003Cthead\u003E\u003Ctr\u003E\u003Cth\u003E" + (pug.escape(null == (pug_interp = languages.study_target) ? "" : pug_interp)) + "\u003C\u002Fth\u003E";
if (hasRatyTeacher)
{
pug_html = pug_html + "\u003Cth class=\"assessment\"\u003E\u003C\u002Fth\u003E";
}
pug_html = pug_html + "\u003Cth class=\"assessment\"\u003E" + (pug.escape(null == (pug_interp = languages.assessment) ? "" : pug_interp)) + "\u003C\u002Fth\u003E\u003C\u002Ftr\u003E\u003C\u002Fthead\u003E\u003Ctbody\u003E\u003C\u002Ftbody\u003E\u003C\u002Ftable\u003E";}.call(this,"I18n" in locals_for_with?locals_for_with.I18n:typeof I18n!=="undefined"?I18n:undefined,"hasRatyTeacher" in locals_for_with?locals_for_with.hasRatyTeacher:typeof hasRatyTeacher!=="undefined"?hasRatyTeacher:undefined));;return pug_html;};
module.exports = template;