import _ from 'underscore';

export default {
  bind($el, options = {}) {
    const languages = I18n.t('frontend.utils.file_input');
    const maxSizeMB = options.maxSizeMB || 200;

    // デフォルト設定
    const fiOptions = _.defaults(options, {
      elErrorContainer      : '.fileinput-error-container',
      maxFileSize           : maxSizeMB * 1024,
      showPreview           : false,
      maxFileCount          : 1,
      browseClass           : 'btn btn-info fileinput-browse-button',
      browseLabel           : ` ${languages.browseLabel}`,
      removeIcon            : '<i class="fa fa-times"></i>',
      removeClass           : 'btn btn-warning',
      removeLabel           : ` ${languages.removeLabel}`,
      uploadClass           : 'btn btn-success fileinput-upload-button',
      uploadIcon            : '<i class="fa fa-upload"></i>',
      uploadLabel           : ` ${languages.uploadLabel}`,
      allowedFileExtensions : [],
      allowedFileTypes      : [],
      msgValidationErrorIcon: '<i class="fa fa-exclamation-triangle"></i>',
      msgNoFilesSelected    : languages.msgNoFilesSelected,
      msgSizeTooLarge       : languages.msgSizeTooLarge.replace('{size}', `${maxSizeMB}MB`),
      msgFileTypes          : languages.msgFileTypes,
      msgPlaceholder        : '',
      layoutTemplates       : {
        caption: '<div class="file-caption form-control {class}" tabindex="500">\n' +
        '  <span class="file-caption-icon"></span>\n' +
        '  <input class="file-caption-name" onkeydown="return false;" ' +
        'onpaste="return false;" style="cursor: default;"readonly>\n' +
        '</div>'
      },

      // ファイル名の日本語対応
      slugCallback: (text) => {
        return text.split(/(\\|\/)/g).pop();
      }
    });

    // 拡張子のエラーメッセージはオプションから生成する
    const msg = I18n.t('frontend.utils.file_input.msgValidationError', {
      extensions: fiOptions.allowedFileExtensions.join(',')
    });
    // ファイルタイプのエラーメッセージはオプションから生成する
    const msgInvalidFileType = I18n.t('frontend.utils.file_input.msgInvalidFileType', {
      types: Object.values(fiOptions.msgFileTypes).join(', ')
    });

    $el.fileinput(_.defaults(fiOptions, {
      msgInvalidFileExtension: ` ${msg}`,
      msgInvalidFileType     : ` ${msgInvalidFileType}`
    }));
    this.bindForm($el);
  },

  bindForm($el) {
    const $form = $el.parents('form');
    $form.on('submit', () => {
      $form.block();
    });
  }
};
