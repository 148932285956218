import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/import_history';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  },

  show() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/import_histories'    : 'index',
    ':tenant/manage/import_histories/:id': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
