import RadarChartView from '../../../views/shared/radar_chart';

export default RadarChartView.extend({
  chartOptions: {
    layout: {
      padding: {
        top: 5
      }
    },
    scale: {
      ticks: {
        max     : 5,
        min     : 0,
        stepSize: 1
      }
    },
    legend: {
      position: 'right'
    }
  }
});
