import { View } from 'backbone.marionette';
import Utils    from '../../../../utils';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.Mapbar,
    Behaviors.Accordion,
    {
      behaviorClass: Behaviors.StickyTableResizer,
      offsetBottom : 10
    }
  ],

  ui: {
    uploadField: '#js-upload'
  },

  onRender() {
    this.bindFileInput();
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['xlsx']
    });
  }
});
