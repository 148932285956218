import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.report_previews.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    {
      behaviorClass: Behaviors.DataTableResizer,
      offsetBottom : 40
    }
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[2, 'desc'], [0, 'asc']]
    });
  },

  columns() {
    return [
      {
        data : 'display_code',
        class: 'code',
        width: 80
      }, {
        data : 'name',
        class: 'name'
      }, {
        data : 'grade',
        class: 'grade text-right',
        width: 35
      }, {
        data : 'belong',
        class: 'belong'
      }, {
        data : 'status',
        class: 'status text-center',
        width: 50
      }, {
        data : 'remark_grades_count',
        class: 'remark_grades_count text-right',
        width: 65
      }, {
        data : 'remark_pdf_count',
        class: 'remark_pdf_count text-right',
        width: 65
      },
      {
        class   : 'preview text-center',
        data    : 'preview_path',
        sortable: false,
        width   : 50,
        render  : (val) => {
          return Utils.Render.showButton(this, val, val);
        }
      }
    ];
  }
}));
