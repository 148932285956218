import { View } from 'backbone.marionette';
import Utils  from '../../../../utils';
import QuestionsView from './questions';
import PublishSettingsView from './publish_settings';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    uploadField: '#js-upload'
  },

  onRender() {
    this.bindFileInput();

    (new QuestionsView()).render();
    (new PublishSettingsView()).render();
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['xlsx']
    });
  }
});
