import { View } from 'backbone.marionette';

// accepts_nested_attributes_forによる編集フィールド群のレンダリングが前提
// layouts/_rubric_edit_field.html.slim を前提とする
export default View.extend({
  template: false,

  ui: {
    ratyCell : 'td.raty',
    ratyField: 'input:radio',
    zeroField: 'input[value=0]'
  },

  events: {
    'touchclick @ui.ratyCell': 'onClickCell',
    'change @ui.ratyField'   : 'onChange'
  },

  onClickCell(e) {
    const $cell = this.$(e.currentTarget);
    const $radio = $cell.find('input:radio');

    $radio.prop('checked', true).trigger('change');
  },

  onChange(e) {
    const $radio = this.$(e.currentTarget);
    const $cell = $radio.parents('td.raty');
    const checked = $radio.prop('checked');

    // 他セルを非選択状態に戻す
    this.ui.zeroField.prop('checked', !checked);
    this.ui.ratyCell.find('i').toggleClass('rated', false);
    if (checked) $cell.find('i').toggleClass('rated', true);
  }
});
