import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    inputField: 'input',
    table     : 'table'
  },

  events: {
    'change @ui.inputField': 'onChange'
  },

  onChange(e) {
    const field = this.$(e.currentTarget);
    const form = field.parents('form');
    this.ui.table.block();
    form.submit();
  }
});
