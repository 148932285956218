import d3 from 'd3';

// チャートの座標点にマウスカーソルを当てたときに表示されるツールチップの文字列を加工できるようにしたUtilクラスである
// ライブラリのツールチップデザインのまま、座標値をツールチップ表示を気軽にできるようにすることが目的
//
// --- 本クラスを利用する事例 -------------------------------------------------------------------------
// # デザインは既存のツールチップのままで良いが値だけ加工したい
// # 「%」などの固定の加工であれば問題ないが、v値以外の別の値やv依存の文言など動的なものは表示できず
// # しかし座標自体を文字にすると当然ながらチャートにならないため対処方法がない
// chart
//  .tooltip
//  .valueFormatter((v, _i, _p) => { return `${v}%: ${vTooltipText}`; }) # 参照手段がない
// -------------------------------------------------------------------------------------------------
//
// NVD3.jsの用意するツールチップは使える情報が基本的に限られている
// あくまでFormatterでしかないため座標以外の情報が必要な場合は本機能を使う
//
// --- 使用例 ---------------------------------------------------------------------------------------
// chart.xAxis.tickFormat((v) => { return I18n.l('date.formats.default', v); });
// chart.yAxis.tickFormat((v) => { return `${v}%`; });
// chart.tooltipGenerator(
//   new Utils.Nvd3Tooltip()
//     .headerFormatter((d) => { return I18n.l('date.formats.default', d); })# 変更なしでも再定義が必要
//     .ValueFormatter((d, _i, _p, datum) => { return `${d}%: ${datum.data.tooltipText}`; })
//     .toGenerator()
// )
// ------------------------------------------------------------------------------------------------
//
// 注意点1
//   NVD3はtooltipGeneratorを何も使用しない時には自動で軸のtickFormatを参照してくれる
//   しかし本処理はそのルールから逸れた拡張であるためチャートの種類差なども考慮して再定義を要求している
// 注意点2
//   ツールチップデザインごと変更したい場合は pug を使って生成したHTMLをtooltipGeneratorに渡すなど
//   本クラスではなく根本的に自作して行うこと
// 注意点3
//   グラフの種類によって datum.data や datum.point など求める渡した値がある位置が異なるので注意すること
//   グラフの種類によって x軸の値が header に行くのか key に行くのか異なるため注意すること
//   interactiveLayer（useInteractiveGuideline）の場合は datum ではなく p から取る等異なるため注意すること
export default class {
  constructor() {
    // デフォルトの挙動だけでなく引数仕様の情報共有の意味もあるので未使用引数を一時的に許可
    /* eslint-disable no-unused-vars */
    this._headerFormatter = (d, _datum) => { return d; };
    this._keyFormatter = (d, _i, _datum) => { return d; };
    this._valueFormatter = (d, _i, _p, _datum) => { return d; };
    /* eslint-enable no-unused-vars */
  }

  headerFormatter(formatter) {
    this._headerFormatter = formatter;
    return this;
  }

  keyFormatter(formatter) {
    this._keyFormatter = formatter;
    return this;
  }

  valueFormatter(formatter) {
    this._valueFormatter = formatter;
    return this;
  }

  toGenerator() {
    // 基本形はライブラリの実装で見た目は変えない
    // https://github.com/novus/nvd3/blob/v1.8.6/src/tooltip.js
    return (datum) => {
      if (datum === null) {
        return '';
      }

      const table = d3.select(document.createElement('table'));

      table.selectAll('thead')
           .data([datum])
           .enter().append('thead')
           .append('tr')
           .append('td')
           .attr('colspan', 3)
           .append('strong')
           .classed('x-value', true)
           .html(this._headerFormatter(datum.value, datum));  // datum追加

      const tbodyEnter = table.selectAll('tbody')
                              .data([datum])
                              .enter().append('tbody');
      const trowEnter = tbodyEnter.selectAll('tr')
                                  .data((p) => { return p.series; })
                                  .enter()
                                  .append('tr')
                                  .classed('highlight', (p) => { return p.highlight; });
      trowEnter.append('td')
               .classed('legend-color-guide', true)
               .append('div')
               .style('background-color', (p) => { return p.color; });
      trowEnter.append('td')
               .classed('key', true)
               .classed('total', (p) => { return !!p.total; })
               .html((p, i) => { return this._keyFormatter(p.key, i, datum); });  // datum追加
      trowEnter.append('td')
               .classed('value', true)
               .html((p, i) => { return this._valueFormatter(p.value, i, p, datum); }); // datum追加

      return table.node().outerHTML;
    };
  }
}
