import { View } from 'backbone.marionette';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import Mixins from '../../../mixins';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.surveys.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc'], [1, 'desc'], [2, 'asc']]
    });
  },

  columns() {
    return [
      {
        class: 'fiscal_year text-right',
        data : 'fiscal_year',
        width: 55
      },
      {
        class: 'taken_term',
        data : 'taken_term',
        width: 65
      },
      {
        class: 'manage_name',
        data : 'manage_name'
      },
      {
        class   : 'subjects_type text-right',
        data    : 'subjects_type',
        sortable: false,
        width   : 65
      },
      {
        class   : 'subjects_count text-right',
        data    : 'subjects_count',
        sortable: false,
        width   : 55
      },
      {
        class   : 'records_count text-right',
        data    : 'records_count',
        sortable: false,
        width   : 55
      },
      {
        class   : 'answer_rate text-right',
        data    : 'answer_rate',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return `${val}%`;
        }
      },
      {
        class   : 'details text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (val, _type, row) => {
          if (row.answer_rate > 0) return '';
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
