import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/generic';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {},

  edit() {},

  show() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/generics'           : 'index',
    ':tenant/manage/generics/new'       : 'new',
    ':tenant/manage/generics/:id/edit'  : 'edit',
    ':tenant/manage/generics/:id'       : 'show',
    ':tenant/manage/generics/:id/import': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
