import _ from 'underscore';
import { View } from 'backbone.marionette';
import Mixins from '../../../../mixins';
import Utils from '../../../../../utils';
import Behaviors from '../../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.mentor.search.basics.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.RemindNotifyMessage
  ],

  ui: {
    fuzzyAnsweredSelectBox: '.js-search-by-answered'
  },

  events: {
    'change @ui.fuzzyAnsweredSelectBox': 'reload'
  },

  onRender() {
    const options =
      _.extend({ columns: this.columns() }, this.dataTableOptions);

    this.initDataTables(options);
  },

  columns() {
    return _([
      ...this._basicColumns(),
      ...this._customColumns(),
      this._showButtonColumn()
    ]).compact();
  },

  _customColumns() {
    return [{
      data : 'last_login_at',
      class: 'last-login-at'
    }];
  },

  _basicColumns(thatColumns = []) {
    const thisColumns = [{
      data : 'display_code',
      class: 'code',
      width: 80
    }, {
      data : 'name',
      class: 'name'
    }, {
      data : 'grade',
      class: 'grade text-right',
      width: 35
    }, {
      data : 'belong',
      class: 'belong'
    }, {
      data : 'status',
      class: 'status text-center',
      width: 50
    }];

    // 引数で外部から与えられたカラム定義とマージする。
    _(thisColumns).map((thisColumn) => {
      const thatColumn = _(thatColumns).findWhere({ data: thisColumn.data });
      if (thatColumn) _(thisColumn).extend(thatColumn);

      return thisColumn;
    });

    return thisColumns;
  },

  _showButtonColumn() {
    return {
      class   : 'details text-center',
      data    : 'show_path',
      sortable: false,
      width   : 80,
      render  : (val) => {
        return Utils.Render.showButton(this, val, val);
      }
    };
  }
}));
