import $ from 'jquery';
import 'bootstrap-notify';

// flash-type => notify設定への対応表
const configs = {
  notice: {
    icon : 'fa fa-exclamation-circle',
    type : 'info',
    delay: 5000
  },
  warning: {
    icon : 'fa fa-exclamation-triangle',
    type : 'warning',
    delay: 5000
  },
  alert: {
    icon : 'fa fa-exclamation-triangle',
    type : 'danger',
    delay: 5000
  },
  error: {
    icon : 'fa fa-exclamation-triangle',
    type : 'danger',
    delay: 5000
  },
  devalert: {
    icon : 'fa fa-exclamation-triangle',
    type : 'danger',
    delay: 0
  },
  defaults: {
    icon : '',
    type : 'default',
    delay: 5000
  }
};

export default {
  notify(configType, message) {
    const { icon, type, delay } = configs[configType] || this.configs.defaults;
    $.notify({ icon, message }, { type, delay, z_index: 10000 });
  }
};
