import { View } from 'backbone.marionette';
import d3 from 'd3';
import nv from 'nvd3';
import Utils from '../../../../utils';

export default View.extend({
  template: false,
  t       : Utils.I18n.bind('views.mentor.lecture_surveys.pie_chart'),

  ui: {
    chart: 'svg'
  },

  onRender() {
    const data = this.ui.chart.data('chart-data');
    this.renderChart(data);
  },

  renderChart(data) {
    // d3.attr('height', num)ではIE11やEdgeで効かないためCSSでsvg自体を調整する
    this.ui.chart.css('height', '250px');

    const key = this.ui.chart.data('key');
    nv.addGraph(() => {
      const chart = nv.models.pieChart()
                      .x((d) => { return d.label; })
                      .y((d) => { return d.value; })
                      .showLabels(true)
                      .labelType('percent')
                      .valueFormat(d3.format('01d'))
                      .noData('');

      chart.tooltip
           .valueFormatter((d) => { return this.t(`.${key}`, { d: d }); });

      d3.select(this.ui.chart.get(0))
        .datum(data)
        .call(chart);

      nv.utils.windowResize(chart.update);

      return chart;
    });
  }
});
