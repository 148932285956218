import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/student/assessment';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  },

  show() {},

  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/summaries/:summary_id/assessments'         : 'index',
    ':tenant/students/:student_id/subjects/:subject_id/assessments'          : 'index',
    ':tenant/students/:student_id/summaries/:summary_id/assessments/:id/edit': 'edit',
    ':tenant/students/:student_id/subjects/:subject_id/assessments/:id/edit' : 'edit',
    ':tenant/students/:student_id/summaries/:summary_id/assessments/:id'     : 'show',
    ':tenant/students/:student_id/subjects/:subject_id/assessments/:id'      : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
