import { View } from 'backbone.marionette';
import _        from 'underscore';
import $        from 'jquery';
import Utils    from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.student.activities.edit'),

  ui: {
    inputBody  : '#activity_body',
    activityTag: '.js-activity-tag'
  },

  events: {
    'cocoon:after-insert'         : 'onCocoonAfterInsert',
    'click .js-remove-attachment' : 'onClickRemoveAttachment',
    'click .js-recover-attachment': 'onClickRecoverAttachment',
    'change @ui.activityTag'      : 'onChangeActivityTag'
  },

  onRender() {
    this.$('.js-datepicker')
      .datepicker({ language: I18n.locale, autoclose: true });

    // なぜか最初から FileInput がデフォルト設定で適用されてしまうので解除する。
    this.$('input[type=file]').fileinput('destroy');
    this.bindFileInput();
  },

  onCocoonAfterInsert() {
    this.bindFileInput();
  },

  bindFileInput() {
    Utils.FileInput.bind(this.$('input[type=file]'), {
      allowedFileExtensions: [],
      showUpload           : false
    });
  },

  onClickRemoveAttachment(e) {
    e.preventDefault();

    const $removeButton = this.$(e.currentTarget);
    const $recoverButton = $removeButton.siblings('.js-recover-attachment');
    const $attachmentLink = $removeButton.siblings('.js-attachment');
    const $hiddenInput = $removeButton.closest('.js-attachment-fields')
                                      .find('input[name$="[_destroy]"]');

    $removeButton.addClass('hide');
    $recoverButton.removeClass('hide');
    $attachmentLink.css({ 'text-decoration': 'line-through' });
    $hiddenInput.val(true);
  },

  onClickRecoverAttachment(e) {
    e.preventDefault();

    const $recoverButton = this.$(e.currentTarget);
    const $removeButton = $recoverButton.siblings('.js-remove-attachment');
    const $attachmentLink = $recoverButton.siblings('.js-attachment');
    const $hiddenInput = $recoverButton.closest('.js-attachment-fields')
                                       .find('input[name$="[_destroy]"]');

    $removeButton.removeClass('hide');
    $recoverButton.addClass('hide');
    $attachmentLink.css({ 'text-decoration': 'none' });
    $hiddenInput.val(false);
  },

  // 未記入ならタグに応じてテンプレートを本文に挿入する
  onChangeActivityTag(e) {
    const $checkbox = this.$(e.currentTarget);
    const template =  this._matchableText($checkbox.data('template'));
    const templates = _.map(this.ui.activityTag, (tag) => {
      return this._matchableText(this.$(tag).data('template'));
    });
    const body = this._matchableText(this.ui.inputBody.val());

    if ($checkbox.prop('checked')) {
      // ONにした時に、本文が未記入（空や他タグのテンプレートと一致）の場合は、そのタグのテンプレートに書き換え
      if ((body === '') || templates.includes(body)) {
        this.ui.inputBody.val($checkbox.data('template'));
      }
    } else if (body === template) {
      // OFFにした時に、本文が現在のテンプレートと一致した場合は、他のチェック済みテンプレートまたは空に差し替え
      const checkedTemplates = _.compact(_.map(this.ui.activityTag, (tag) => {
        if (!this.$(tag).prop('checked')) return null;
        return this.$(tag).data('template');
      }));
      this.ui.inputBody.val(checkedTemplates.pop() || '');
    }
  },

  // 判定の際には改行コードのブレが邪魔になるので削る
  _matchableText(text) {
    return $.trim((text || '').replace(/\r\n|\r|\n/g, '')) || '';
  }
});
