// シラバスの開講年度を変更すると、年度に紐づくテンプレートの基本/配当項目のみ画面上へ表示するための処理
// app/views/manage/syllabuses/_search_syllabus_basic.html.slim と対を成す
import $ from 'jquery';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  fiscalYearData: {},

  ui: {
    fiscalYear        : 'select.js-search-basic-fiscal_year',
    fiscalYearData    : '.js-fiscal_year_data',
    dynamicFieldsPlace: '.js-collapse-syllabus-basic-condition-place'
  },

  events: {
    'change @ui.fiscalYear' : 'onChangeFiscalYear',
    'reset .js-form-ransack': 'onClickReset' // app/backbone/mixins/datatables.jsに依存
  },

  onRender() {
    this.fiscalYearData = this._getFiscalYearData();
    this._changeDynamicFields();
  },

  onChangeFiscalYear() {
    this._changeDynamicFields();
  },

  onClickReset() {
    // リセット処理後に開講年度が変更されるまで待機する
    setTimeout(() => {
      this._changeDynamicFields();
    }, 200);
  },

  _changeDynamicFields() {
    const year = this.ui.fiscalYear.val();
    const conditions =  year ? this.fiscalYearData[year]
                             : this._sortAndFlattenData(this.fiscalYearData);
    this._createDynamicFields(conditions);
  },

  // fiscalYearData は以下のようなデータ構造を想定
  // { 2023 => [["base,dividend", "備考", "備考", []], ["base", "DP1", "DP1", ["◯"]]], 2022=> ... }
  _getFiscalYearData() {
    return this.ui.fiscalYearData.data('fiscalYearData');
  },

  _createDynamicFields(conditions) {
    this.ui.dynamicFieldsPlace.empty();

    $.each(conditions, (index, [settingTypes, nameJa, name, phrases]) => {
      const $formGroup = $('<div>').addClass('form-group');

      $('<input>', {
        type : 'hidden',
        name : `basic_text[${index}][setting_types]`,
        value: settingTypes
      }).appendTo($formGroup);

      $('<input>', {
        type : 'hidden',
        name : `basic_text[${index}][template_basic_name_ja]`,
        value: nameJa
      }).appendTo($formGroup);

      if (phrases.length === 0) {
        this._appendWithInputField($formGroup, index, settingTypes, nameJa, name);
      } else {
        this._appendWithSelectField($formGroup, index, settingTypes, nameJa, name, phrases);
      }
    });
  },

  _appendWithInputField($formGroup, index, settingTypes, nameJa, name) {
    $('<input>', {
      type       : 'text',
      name       : `basic_text[${index}][text]`,
      class      : 'form-control',
      placeholder: name
    }).appendTo($formGroup);

    this.ui.dynamicFieldsPlace.append($formGroup);
  },

  _appendWithSelectField($formGroup, index, settingTypes, nameJa, name, phrases) {
    const $select = $('<select>', {
      name : `basic_text[${index}][text]`,
      class: 'form-control select optional',
      title: name
    });
    $select.append($('<option>').val(null).text(''));
    $.each(phrases, (_i, phrase) => {
      $select.append($('<option>').val(phrase).text(phrase));
    });
    $select.appendTo($formGroup);
    this.ui.dynamicFieldsPlace.append($formGroup);
    $select.selectpicker();
  },

  _sortAndFlattenData(data) {
    // オブジェクトのキーを取得し、数値に変換後、降順でソート
    const sortedKeys = Object.keys(data).map(Number).sort((a, b) => { return b - a; });

    const result = [];

    // ソートされたキーに基づいてフラットな配列へデータ追加
    sortedKeys.forEach(key => {
      result.push(...data[key]);
    });

    return result;
  }

});
