import IntroDashboard from './introjs/dashboard';

export default {
  ready() {},

  introDashboard() {
    if (window.gon.target_own) {
      this.intro = new IntroDashboard();
      this.intro.play();
    }
  }
};
