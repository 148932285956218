import { View } from 'backbone.marionette';
import RecordSummaryView from './record_summary';
import GpaRankingProcessView from './gpa_ranking_process';
import GpaRankingView from './gpa_ranking';
import GenericResult from './generic_result';
import GenericProcess from '../generic_charts/process_chart';
import CurriculumResult from './curriculum_result';
import CurriculumProcess from './curriculum_process';
import MindResult from './mind_result';
import MindProcess from '../mind_charts/process_chart';
import IntroJs from '../../../../extension/introjs';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    (new RecordSummaryView()).render().load();
    (new GpaRankingProcessView()).render().load();
    (new GpaRankingView()).render().load();
    (new GenericResult()).render();
    (new GenericProcess()).render();
    (new CurriculumResult()).render();
    (new CurriculumProcess()).render();
    (new MindResult()).render();
    (new MindProcess()).render();
    IntroJs.introDashboard();
  }
});
