import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils  from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.document_students.edit'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableRemoveRows,
    {
      behaviorClass: Behaviors.DataTableResizer,
      offsetBottom : 20
    }
  ],

  ui: {
    uploadField: '#js-upload'
  },

  onRender() {
    this.bindFileInput();
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'asc']]
    });
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['zip']
    });
  },

  columns() {
    return [
      {
        class: 'code',
        data : 'student_code',
        width: 100
      },
      {
        class: 'display_code',
        data : 'student_display_code',
        width: 100
      },
      {
        class   : 'name',
        data    : 'student_name',
        sortable: false
      },
      {
        class   : 'registrant',
        data    : 'registrant_name',
        sortable: false
      },
      {
        class: 'status',
        data : 'document_status',
        width: 50
      },
      {
        class   : 'file',
        data    : 'download_path',
        sortable: false,
        render  : (val, _type, row) => {
          if (!val) return '';

          const url = `${val}?open=true`;
          return Utils.Render.link('fa-external-link', row.file_name, url, url);
        }
      },
      {
        class   : 'dl',
        data    : 'download_path',
        sortable: false,
        width   : 40,
        render  : (val) => {
          if (!val) return '';
          return Utils.Render.link('fa-download', 'DL', val);
        }
      }
    ];
  }
}));
