import _ from 'underscore';
import { View } from 'backbone.marionette';
import InputRateAverageView from '../dashboards/input_rate_average';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    histories: '.js-input-rate-averages'
  },

  onRender() {
    _(this.ui.histories).each((element) => {
      (new InputRateAverageView({ el: this.$(element) })).render();
    });
  }
});
