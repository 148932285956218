import _ from 'underscore';
import { View } from 'backbone.marionette';
import Utils from '../../../../../../../utils';

export default View.extend({
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabuses.import.csvs.index.import_csvs'),
  ui      : {
    form  : 'form',
    upload: '.js-upload'
  },
  events: {
    'submit @ui.form': 'onSubmit'
  },

  onRender() {
    Utils.FileInput.bind(this.ui.upload, {
      allowedFileExtensions: ['csv']
    });
  },

  onSubmit() {
    // Utils.FileInput.bind で block されるが、
    // 別タブで submit するのですぐに unblock する。
    this.ui.form.unblock();
    // defer しないと POST リクエストに失敗する。
    _.defer(() => {
      this.ui.upload.fileinput('clear');
    });
  }
});
