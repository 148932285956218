import $ from 'jquery';

const countText = (textarea) => {
  return textarea.value.replace(/\s/gm, '').length;
};

const onTextareaKeyup = (event) => {
  const target = event.target;
  const count = countText(target);
  $(target).next().find('.js-utils-text-count').text(count);
};

// textarea の下部に文字数カウンターを追加する
// `limit` は 上限数の表示
const textCounter = ($textarea, limit = null) => {
  $(document).off('keyup.utilsTextCounter');
  $(document).on('keyup.utilsTextCounter', 'textarea', $textarea, onTextareaKeyup);
  $('.js-utils-text-counter').remove();

  $textarea.each((_index, textarea) => {
    const $text = $(textarea);

    let limitTag = '';
    if (limit !== null) {
      limitTag = `<span>/ ${limit}</span>`;
      $text.attr('maxlength', limit);
    }

    const counterTag = `
      <div class="js-utils-text-counter" style="text-align: right;">
        <span class="js-utils-text-count" style="font-size: 1.2em; font-weight: bold;">
          ${countText(textarea)}
        </span>
        ${limitTag}
      </div>
    `;

    $text.after(counterTag);
  });
};

export default textCounter;
