import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.lr.students.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  ui: {
    by_fuzzy_answered: '#q_by_fuzzy_answered'
  },

  events: {
    'change @ui.by_fuzzy_answered': 'reload'
  },

  onRender() {
    this.initDataTables({
      columns: this.columns()
    });
  },

  columns() {
    const cols = this._basicColumns();
    cols.push(...this._customColumns());
    cols.push(this._showButtonColumn());
    return cols;
  },

  _customColumns() {
    return [{
      data : 'last_login_at',
      class: 'last-login-at'
    }];
  },

  _basicColumns() {
    return [{
      data : 'display_code',
      class: 'code',
      width: 80
    }, {
      data    : 'name',
      class   : 'name',
      sortable: false
    }, {
      data : 'grade',
      class: 'grade text-right',
      width: 35
    }, {
      data : 'belong',
      class: 'belong'
    }, {
      data : 'status',
      class: 'status text-center',
      width: 50
    }];
  },

  _showButtonColumn() {
    return {
      class   : 'details text-center',
      data    : 'show_path',
      sortable: false,
      width   : 80,
      render  : (val) => {
        return Utils.Render.showButton(this, val, val);
      }
    };
  }
}));
