import tooltipTemplate from '../../../../templates/mentor/analyses/tooltip.pug';
import ScatterChart from './scatter_chart.js';

export default ScatterChart.extend({
  el: '#js-gp-gpa-chart',

  // バブル考慮でメモリ最大値（ForceX,ForceY）が +0.5
  //
  initAxis(chart, maxScale) {
    chart.showLegend(false);

    chart.xAxis.options({
      axisLabel : this.t('.gp'),
      tickValues: this.buildTickValues(maxScale.x)
    });
    chart.yAxis.options({
      axisLabel : this.t('.gpa'),
      tickValues: this.buildTickValues(maxScale.y)
    });
  },

  initClickEvent(chart) {
    chart.scatter.dispatch.on('elementClick', (e) => {
      const gp = e.point.x;
      const gpa = e.point.y;
      this.trigger('elementClick', { gp: gp, gpa: gpa });
    });
  },

  initTooltip(chart) {
    chart.tooltip.contentGenerator((obj) => {
      return tooltipTemplate({
        x    : `${this.t('.gp')} : ${obj.point.x}`,
        y    : `${this.t('.gpa')} : ${obj.point.y}`,
        size : `${this.t('.size')} : ${obj.point.size}`,
        color: obj.point.color,
        key  : obj.series[0].key
      });
    });
  }
});
