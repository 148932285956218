import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';

const Controller = RestController.extend({
  edit() {},

  show() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/plan_dream/edit': 'edit',
    ':tenant/students/:student_id/plan_dream'     : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
