import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#publish_settings',
  template: false,

  ui: {
    selectField: 'select'
  },

  events: {
    'change @ui.selectField': 'onChangeSelect'
  },

  onChangeSelect(e) {
    const $el = this.$(e.currentTarget);
    $el.parents('td').addClass('changed').attr('level', $el.val());
  }
});
