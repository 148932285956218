import { View } from 'backbone.marionette';
import Mixins from '../../../../mixins';
import MindView from './minds/index';
import MindCommentView from './mind_comments/index';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  onRender() {
    new MindView({ el: '.js-mind-panel' }).render();
    new MindCommentView({ el: '.js-mind-comment-panel' }).render();
  }
}));
