const utilsWindow = {};

export default {
  eventName: 'beforeunload.onWindowClosed',

  // スマホ・タブレットでタブウィンドウを常に最前面へ表示する処理
  open(url, name, options = '') {
    const previousWindow = utilsWindow[name];

    if (previousWindow) {
      previousWindow.close();
      /*
       * 既にポップアップウィンドウが開いており、それを上書きして新しいウィンドウを開こうとする場合に
       * (Chrome の場合)「このサイトを離れますか？」というダイアログが表示される。
       * そのダイアログで「キャンセル」を選んだ場合は何も実行しない。
       */
      if (previousWindow.closed) utilsWindow[name] = window.open(url, name, options);
    } else {
      utilsWindow[name] = window.open(url, name, options);
    }

    return utilsWindow[name];
  }
};
