import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    coverRadioBtn: 'input[name="syllabus_attachment[cover]"]',
    coverTextArea: '.js-cover-text-area'
  },

  events: {
    'change @ui.coverRadioBtn': 'onChangeCoverRadioBtn'
  },

  onRender() {
    this._changeRadio();
  },

  onChangeCoverRadioBtn() {
    this._changeRadio();
  },

  _changeRadio() {
    if (this.ui.coverRadioBtn.filter(':checked').val() === 'with_cover') {
      this.ui.coverTextArea.show();
    } else {
      this.ui.coverTextArea.hide();
    }
  }
});
