import $ from 'jquery';
import { View } from 'backbone.marionette';
import Utils    from '../../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    copiedSyncRegion: '.js-copied-sync-region'
  },

  regions: {
    copiedSync: '@ui.copiedSyncRegion'
  },

  onRender() {
    const url = this.ui.copiedSyncRegion.data('path');
    const intervalID = setInterval(() => {
      $.getJSON(url).then((json) => {
        if (json.status === 'suceeded' || json.status === 'failed') clearInterval(intervalID);

        const childView = new View({ el: $('<div>').html(json.html) });
        this.showChildView('copiedSync', childView);

        if (json.status === 'suceeded') this._reloadOpener();
      });
    }, 1000);
  },

  _reloadOpener() {
    Utils.Opener.get('SyllabusLectureDatatable').reload();
  }
});
