import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.report_mentor_texts.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  ui: {
    textStatusField  : 'select.js-text-status',
    fuzzyContentField: '.js-fuzzy-content select'
  },

  events: {
    'change @ui.textStatusField'  : 'reload',
    'change @ui.fuzzyContentField': 'reload'
  },

  onRender() {
    this.initDataTables({
      rowId  : 'edit_path',
      columns: this.columns(),
      order  : [[8, 'desc']]
    });
    Utils.Opener.set('ReportMentorTextDatatable', this);
  },

  columns() {
    return [
      {
        data  : 'display_code',
        class : 'code',
        width : 120,
        render: (val, _, row) => {
          return Utils.nl2br(`${val}\n${row.name}`);
        }
      }, {
        data    : 'name',
        class   : 'name',
        visible : false,
        sortable: false
      }, {
        data    : 'grade',
        class   : 'grade text-right',
        sortable: false,
        width   : 30
      }, {
        data    : 'status',
        class   : 'status text-center',
        sortable: false,
        width   : 45
      }, {
        data    : 'report',
        class   : 'report',
        width   : 300,
        sortable: false,
        render  : (val, _, row) => {
          const zenkakuBLank = '\u3000';
          return Utils.nl2br(`${val}\n${zenkakuBLank}> ${row.content}`);
        }
      }, {
        data    : 'content',
        class   : 'content',
        visible : false,
        sortable: false
      }, {
        data    : 'text',
        class   : 'text',
        sortable: false
      }, {
        data    : 'writer',
        class   : 'writer',
        sortable: false,
        width   : 120,
        render  : (val) => {
          return Utils.nl2br(val);
        }
      }, {
        data : 'inputted_at',
        class: 'inputted_at text-right',
        width: 60
      },
      {
        class   : 'edit_path text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 50,
        render  : (val) => {
          return Utils.Render.editButton(this, val, val);
        }
      }
    ];
  },

  updateRow(row) {
    this.dataTable.row(`#${row.edit_path}`).data(row);
  }
}));
