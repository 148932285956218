import { View } from 'backbone.marionette';
import Utils    from '../../../../utils';
import Mixins   from '../../../mixins';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.port.base.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableRemoveRows,
    Behaviors.DataTableResizer
  ],

  ui: {
    statusField: 'select#q_email_status_eq',
    uploadField: '.js-upload',
    removeBtn  : '.js-remove-btn'
  },

  events: {
    'change @ui.statusField': 'onChangeStatus'
  },

  onRender() {
    this.initDataTables({
      columnDefs: [{ targets: '_all', sortable: false }],
      order     : []
    });

    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileExtensions: ['csv']
    });
  },

  onChangeStatus() {
    const isManually = this.ui.statusField.val() === '1';
    this.ui.removeBtn.toggleClass('disabled', isManually);
    this.reload();
  }
}));
