import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/generic_item';

const Controller =  RestController.extend({
  edit() {
    (new View.Edit()).render();
  },

  show() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/generics/:id/items/edit': 'edit',
    ':tenant/manage/generics/:id/items'     : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
