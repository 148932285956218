import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View from '../../views/student/lr_skill';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  },

  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/teaching/lr_skills'                  : 'index',
    ':tenant/students/:student_id/teaching/lr_skills/:schedule_id/edit': 'edit',
    ':tenant/students/:student_id/qualify/lr_skills'                   : 'index',
    ':tenant/students/:student_id/qualify/lr_skills/:schedule_id/edit' : 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
