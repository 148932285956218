import { View } from 'backbone.marionette';
import Mixins from '../../../../mixins';
import Utils from '../../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '#js-gp-gpa-data',
  template: false,

  ui: {
    gpField : '#q_by_summary_gp',
    gpaField: '#q_by_gpa'
  },

  mixins: [
    Mixins.DataTables
  ],

  onRender() {
    this.initDataTables({
      ordering  : false,
      scrollY   : 285,
      pageLength: 10,
      columns   : this.columns()
    });
  },

  search(params) {
    this.ui.gpField.val(params.gp);
    this.ui.gpaField.val(params.gpa);
    this.ui.search.submit();
  },

  columns() {
    return [
      { data: 'gp' },
      { data: 'gpa' },
      {
        data  : 'student_code',
        render: (val, _type, row) => {
          return Utils.Render.link('fa-external-link', val, row.student_path, '_blank');
        }
      },
      { data: 'student_name' }
    ];
  }
}));
