import _ from 'underscore';
import { View } from 'backbone.marionette';
import autosize from 'autosize';
import Sortable from 'sortablejs';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    ratyMaxButton  : '.js-raty-max',
    tyingCodeButton: '.js-tying-code',
    targetNode     : '.js-target-node',
    targetNodeList : '.js-target-node-list',
    planNode       : '.js-plan-node',
    planNodeList   : '.js-plan-node-list'
  },

  events: {
    'click @ui.ratyMaxButton'               : 'onClickRatyMaxButton',
    'click @ui.tyingCodeButton'             : 'onClickTyingCodeButton',
    'cocoon:after-insert @ui.targetNode'    : 'onAfterInsertTarget',
    'cocoon:after-remove @ui.targetNodeList': 'onAfterRemoveTarget',
    'cocoon:after-insert @ui.planNode'      : 'onAfterInsertPlan',
    'cocoon:after-remove @ui.planNodeList'  : 'onAfterRemovePlan'
  },

  behaviors: [
    Behaviors.ScrollToError,
    Behaviors.LocaleSwitch
  ],

  initialize() {
    this.bindUIElements();
    this._setTargetNo();
    this._setPlanNo();
    this._applySortable();
  },

  onAfterInsertTarget() {
    this.render();
    this._setTargetNo();
  },

  onAfterRemoveTarget() {
    this._setTargetNo();
  },

  onAfterInsertPlan() {
    this.render();
    autosize(this.ui.planNode.parents('table').find('textarea'));
    this._setPlanNo();
  },

  onAfterRemovePlan() {
    this._setPlanNo();
  },

  onClickRatyMaxButton(e) {
    const target = this.$(e.target).nextAll('.js-raty-max-area').first();
    target.toggle();
  },

  onClickTyingCodeButton(e) {
    const target = this.$(e.target).nextAll('.js-tying-code-area').first();
    target.toggle();
  },

  _setTargetNo() {
    this._setNo(this.ui.targetNode);
  },

  _setPlanNo() {
    _(this.ui.planNodeList).each((element) => {
      this._setNo(this.$(element));
    });
  },

  _setNo($element) {
    const $rows = $element.parents('table').find('tr.nested-fields:visible');

    _($rows).each((row, i) => {
      const $row = this.$(row);
      $row.find('.js-hidden-no').val(i + 1);
      $row.find('.js-no').html(`${i + 1}`);
    });
  },

  _applySortable() {
    // 到達目標は必ず最大 1 つ。
    if (this.ui.targetNodeList.length > 0) {
      Sortable.create(this.ui.targetNodeList.get(0), {
        handle  : 'th',
        onUpdate: (e) => {
          this._setNo(this.$(e.item));
        }
      });
    }
    // 授業計画は複数存在する可能性がある。
    _(this.ui.planNodeList).each((element) => {
      Sortable.create(element, {
        handle  : 'th',
        onUpdate: (e) => {
          this._setNo(this.$(e.item));
        }
      });
    });
  }
});
