import cocktail   from './mixins/cocktail';
import colorIcons from './mixins/color_icons';
import DataTables from './mixins/datatables';
import SearchByRadioFilter from './mixins/search_by_radio_filter';

export default {
  cocktail,
  colorIcons,
  DataTables,
  SearchByRadioFilter
};
