import _ from 'underscore';
import d3 from 'd3';
import nv from 'nvd3';
import { View } from 'backbone.marionette';

export default View.extend({
  template: false,

  ui: {
    svg: 'svg'
  },

  colors: [
    'rgb(31, 119, 180)',
    'gray'
  ],

  onRender() {
    const data = this.$el.data('chart-data');
    if (!data) return;

    const xNames = data.x_names;

    _.each(data.lines, (line, i) => {
      _.extend(line, { color: this.colors[i] });
    });

    nv.addGraph(() => {
      const chart = nv.models.lineChart()
                             .forceY([0, data.max_scale])
                             .margin({ top: 0, left: 15, right: 15, bottom: 20 })
                             .useInteractiveGuideline(true);


      chart.yAxis
           .showMaxMin(false)
           .tickValues([...Array(data.max_scale + 1)].map((_i, scale) => { return scale; }));
      chart.xAxis
           .showMaxMin(false)
           .tickValues([...Array(data.x_names.length)].map((_i, scale) => { return scale; }))
           .tickFormat((v) => { return xNames[v]; });

      d3.select(this.ui.svg.get(0))
        .datum(data.lines)
        .transition()
        .duration(500)
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }
});
