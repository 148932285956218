// ツールチップ用タグをRails側でレンダリングして、それをCell上に出力する場合
// そのままではツールチップが動かないので、このBehaviorを適用すること
import _ from 'underscore';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    dataTables: '.js-dataTables'
  },

  onRender() {
    // Cell上のボタンなのでロードの度にtooltipを貼り直す必要がある
    this.view.dataTable.on('draw.dt.bindLoad', _.bind(this.bindLoad, this));
  },

  bindLoad() {
    // 見た目はCell上だがDatatableの上に出す必要があるため内部処理的にbody直下に描画する
    // ※）Cellではツールチップを出す隙間がなく、また出しところでスクロールにより隠れてしまうため
    this.ui.dataTables.find('[data-toggle="tooltip"]').tooltip({
      container: 'body'
    });
  }
});
