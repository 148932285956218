// 言語データの参照用モジュール
//   1. 参照箇所の分散に対応すべくScopeを設定で定義
//   2. RailsのI18n.tと似せたドット指定でScopeを省略して参照できる
//------------------------------------------------------------------------
// import Utils from '../../../utils';
// export default View.extend({
//   el: 'main',
//   t : Utils.I18n.bind('views.mentor.search_basics.index'),
//
//   render() {
//      $('body').html(this.t('.title'));
//   }
// }));
//------------------------------------------------------------------------
import _ from 'underscore';

export default {
  bind(scope) {
    return _.bind(this.t, {
      scope: scope || 'defaults'
    });
  },

  t(key, options) {
    return I18n.t(`frontend.backbone.${this.scope}${key}`, options || {});
  }
};
