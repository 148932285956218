/* @flow */
import _ from 'underscore';
import Url from 'url';

export default {
  buildUrl(url, query = {}) {
    const urlObj = Url.parse(url, true);

    urlObj.search = undefined;
    urlObj.query = _.extend({}, urlObj.query, query);

    return Url.format(urlObj);
  }
};
