import _ from 'underscore';
import { View } from 'backbone.marionette';
import Record from '../../../models/html';
import InputRateAverageItemView from './input_rate_average_item';

export default View.extend({
  el      : '.js-input-rate-averages',
  template: false,

  ui: {
    tabs : 'a[data-toggle="tab"]',
    notes: '[data-toggle="popover"]'
  },

  events: {
    'click @ui.tabs': 'onClickTab'
  },

  initialize() {
    this.tabs = {};
    this.bindUIElements();
    this.initPopover();
    this.ui.tabs.each((i, tab) => {
      this.tabs[tab.hash] =
        new InputRateAverageItemView({ el: tab.hash, model: new Record() }).render();
    });
    this.loadFirstTab();
  },

  initPopover() {
    this.ui.notes.popover({
      trigger  : 'hover',
      placement: 'top',
      html     : true,
      container: this.el
    });
  },

  loadFirstTab() {
    const firstTab = _.values(this.tabs)[0];
    if (firstTab) {
      firstTab.trigger('loadItem');
    }
  },

  onClickTab(e) {
    const id = this.$(e.target).attr('href');
    const $renderArea = this.$(id);
    if ($renderArea.html() === '') {
      this.tabs[id].trigger('loadItem');
    }
  }
});
