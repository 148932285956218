import { View } from 'backbone.marionette';
import ShowRating from '../../_layouts/show_rating';

export default View.extend({
  el      : '#questions',
  template: false,

  ui: {
    ratingFields: '.js-rating-field'
  },

  onRender() {
    this.bindRating();
  },

  bindRating() {
    this.ui.ratingFields.each((_index, el) => {
      (new ShowRating({ el: `#${el.id}` })).render();
    });
  }
});
