import { View } from 'backbone.marionette';
import Utils from '../../../../../utils';

export default View.extend({
  el      : '.js-resource-table',
  template: false,
  t       : Utils.I18n.bind('views.mentor.analyses.show'),

  ui: {
    targetName: '.js-target-name',
    average   : '.js-average',
    deviation : '.js-deviation'
  },

  renderTable(data) {
    data[0].values.forEach((value) => {
      this.ui.targetName.append(`<td>${value.x}</td>`);
      this.ui.average.append(`<td>${value.y}</td>`);
      this.ui.deviation.append(`<td>${value.yErr[1]}</td>`);
    });
  }
});
