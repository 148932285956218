import { View } from 'backbone.marionette';
import $ from 'jquery';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    selectField      : 'select',
    authorityFormArea: '.js-authority-form-area'
  },

  events: {
    'change @ui.selectField': 'onChangeSelect'
  },

  onRender() {
    this.ui.authorityFormArea.block();
    const url = this.ui.authorityFormArea.data('url');
    $.ajax({ type: 'GET', url: url, dataType: 'JSON', context: this })
    .done((data) => {
      this.ui.authorityFormArea.html(data.html);
      this.ui.authorityFormArea.css('height', 'auto');
      this.bindUIElements();
    })
    .always(() => { this.ui.authorityFormArea.unblock(); });
  },

  onChangeSelect(e) {
    const $el = this.$(e.currentTarget);
    $el.parents('td')
       .addClass('changed')
       .attr('status', $el.val());
  }
});
