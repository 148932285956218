// position: sticky を使ってヘッダ固定を行う際の高さ調整Behavior
// position: sticky は高さが必須だが固定値だとレスポンシブ対応が困難なのでこれを使う
// ・該当Tableを .asm-sticky-table-wrapper.js-sticky-table-wrapperクラスで囲う
// ・該当Tableに position: sticky を適用しヘッダ固定CSSを定義する
// ・該当Tableを含むView.jsにて、このBehaviorを適用する
// --- 例 --------------------------------------------------------------------
// import Behaviors from '../../../behaviors';
// export default View.extend({
//   behaviors: [
//     Behaviors.StickyTableResizer
//   ]
// })
// ---------------------------------------------------------------------------
import $ from 'jquery';
import _ from 'underscore';
import { Behavior } from 'backbone.marionette';
import Utils from '../../utils';

export default Behavior.extend({
  // 一画面に複数のDatatableを配置する場合は別イベントとするべくKeyを変えること
  defaults: {
    eventKey    : 'stickyTable',
    minHeight   : 300,
    offsetBottom: 0,
    // 上部には検索などの部品から画面下限まで表示させるのが基本
    offsetTop   : ((_this) => {
      return _this.$el.find('.js-sticky-table-wrapper:visible').offset().top;
    })
  },

  initialize(_options, view) {
    // view側からリサイズしたいときはイベントを発行する
    view.on('resizeStickyTable', _.bind(this.resizeStickyTable, this));
  },

  onRender() {
    this.unbindStickyTableResizeHandler();
    this.bindStickyTableResizeHandler();
    this.resizeStickyTable();
  },

  resizeStickyTable() {
    const $wrapper = this.$el.find('.js-sticky-table-wrapper:visible');
    if (!$wrapper[0]) return;

    // 計算した高さを指定（Sticky固定には明確な高さが必要）
    $wrapper.height(this.computeHeight());
  },

  // 二重スクロールを避けるため表示スペースに収まる高さを計算する
  computeHeight() {
    let height = $(window).height() - this.options.offsetTop(this) - this.options.offsetBottom;
    if (this.options.minHeight > height) {
      height = this.options.minHeight;
    }
    return height;
  },

  // 画面の幅が変わるようなタイミングでは再調整を行う
  bindStickyTableResizeHandler() {
    let timeoutId = null;
    const eventKey = this.options.eventKey;
    const lazyResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(_.bind(this.resizeStickyTable, this), 200);
    };

    // sidebar開閉処理のイベント
    $(document).on(`shown.bs.collapse.${eventKey}`, 'nav.asm-sidebar', lazyResize);
    $(document).on(`hidden.bs.collapse.${eventKey}`, 'nav.asm-sidebar', lazyResize);

    // ブラウザのリサイズ系イベント
    $(window).on(this.eventStickyTableResize(), lazyResize);
  },

  unbindStickyTableResizeHandler() {
    const eventKey = this.options.eventKey;
    $(document).off(`shown.bs.collapse.${eventKey}`, 'nav.asm-sidebar');
    $(document).off(`hidden.bs.collapse.${eventKey}`, 'nav.asm-sidebar');
    $(window).off(this.eventStickyTableResize());
  },

  // スマホ・タブレットの場合は画面回転時にリサイズするようにイベントを登録する
  eventStickyTableResize() {
    const eventKey = this.options.eventKey;
    const eventName = Utils.Device.isPC() ? 'resize' : 'orientationchange';
    return `${eventName}.stickyTableResizer.${eventKey}`;
  }
});
