import $ from 'jquery';
import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    commentPhraseBtn: '.js-phrase-comment .btn-default'
  },

  events: {
    'touchclick @ui.commentPhraseBtn': 'onClickCommentPhrase'
  },

  onClickCommentPhrase(e) {
    const $btn = $(e.currentTarget);
    const params = { comment: { text: $btn.data('phrase') } };

    this.$el.block();
    $.post(this._getPhraseData($btn, 'path'), params)
      .done((data) => { this._addPhrasedComment($btn, data); })
      .always(() => { this.$el.unblock(); });
  },

  _getPhraseData($btn, name) {
    return $btn.parents('.js-phrase-comment').data(name);
  },

  _addPhrasedComment($btn, data) {
    const $commentsArea = $(`#${this._getPhraseData($btn, 'area')}`);
    $commentsArea.append(data.html);
  }
});
