import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    localeBtn   : '.js-locale-btn',
    localeFields: '.js-locale-field',
    formInsert  : '.js-form-insert'
  },

  events: {
    'cocoon:before-insert @ui.formInsert': 'onBeforeInsertForm',
    'cocoon:after-insert @ui.formInsert' : 'onAfterInsertForm',
    'change @ui.localeBtn'               : 'onChangeLocale'
  },

  onRender() {
    this.bindUIElements();
  },

  onBeforeInsertForm(_, form) {
    if (this.ui.localeBtn[1].checked) {
      form.find('.js-locale-field').toggleClass('hidden');
    }
  },

  onAfterInsertForm() {
    this.bindUIElements();
  },

  onChangeLocale() {
    this.ui.localeFields.toggleClass('hidden');
  }
});
