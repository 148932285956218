import $ from 'jquery';
import Utils from '../utils';

export default {
  ready() {
    this.bindDatatable();
    this.bindAjax();
  },

  // dataTablesのエラーが出たら下記エラーハンドリングまで到達しないので通知化
  bindDatatable() {
    $.fn.dataTable.ext.errMode = (settings, techNote, message) => {
      if (window.gon.env === 'development') {
        Utils.Notify.notify('alert', `DataTablesError: ${message}`);
      }
    };
  },

  // Ajax 通信時のエラーハンドリングを行う。
  // validateエラーのbaseメッセージも表示対象とする
  // ※）base以外は特定のカラムのメッセージなのでModal表示は不適切
  bindAjax() {
    $(document).ajaxError((event, xhr) => {
      if (xhr.statusText === 'abort') return; // jQuery側で強制切断した結果は弾く
      const responseJson = xhr.responseJson || $.parseJSON(xhr.responseText);
      let message = responseJson.error_message;
      if (!message && responseJson.errors) {
        const errors = responseJson.errors.base || [];
        message = errors.join('<br/>');
      }
      Utils.Modal.alert(message);
    });
  }
};
