import { Behavior } from 'backbone.marionette';

export default Behavior.extend({
  ui: {
    dropapp: 'button.dropdown-toggle'
  },

  events: {
    'touchclick @ui.dropapp': 'changeDropArrow'
  },

  // 下部のCell上のDropdownはスクロールで隠れてしまうため上向きに展開する必要がある
  changeDropArrow(e) {
    const $parent = this.$(e.currentTarget).parent('.btn-group');
    const $menu = $parent.find('ul');
    const ulBottom = $parent.offset().top + $parent.height() + $menu.height();
    const drawlimit = this.$('.dataTables_info').offset().top - 10;
    $parent.toggleClass('dropup', drawlimit < ulBottom);
  }
});
