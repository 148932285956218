import Index from './plans/index';
import Form from './plans/form';
import Show  from './plans/show';

export default {
  Index: Index,
  Edit : Form,
  New  : Form,
  Show : Show
};
