import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    processType : '.js-process-type',
    optionForBar: '.js-option-for-bar'
  },

  events: {
    'change @ui.processType': 'onChangeOptionForBar'
  },

  onRender() {
    this.onChangeOptionForBar();
  },

  onChangeOptionForBar() {
    const type = this.ui.processType.val();
    this.ui.optionForBar.toggleClass('hidden', type === 'line');
  }
});
