import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/student/achieve';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/curriculum/achieves': 'index',
    ':tenant/students/:student_id/teaching/achieves'  : 'index',
    ':tenant/students/:student_id/qualify/achieves'   : 'index'
  },

  initialize() {
    this.controller = new Controller();
  }
});
