import { View } from 'backbone.marionette';
import GptChart from './gpt_chart';
import ProcessChart from '../analyses/process_chart';
import ResultChart from './result_chart';
import Utils from '../../../../utils';

export default View.extend({
  template: false,

  ui: {
    tooltip  : '.js-analysis-tooltip',
    sheetTabs: '.js-sheet-tabs',
    firstTab : '.js-sheet-tabs a:first'
  },

  events: {
    'shown.bs.tab @ui.sheetTabs': 'shownSheetTab'
  },

  onRender() {
    // タブに応じて表示するツールチップ内容が変わるため枠だけ生成する
    this.ui.tooltip.tooltip({
      placement: 'left',
      html     : true,
      whiteList: Utils.TooltipWhiteList.setTable({ td: ['colspan'] })
    });

    this.ui.firstTab.tab('show');
  },

  shownSheetTab(e) {
    const sheetId = e.target.href.substring(e.target.href.indexOf('#'));
    const contentEL = this.$el.find(sheetId);
    const gptEL = contentEL.find('.js-analysis-gpt-chart');
    const processEL = contentEL.find('.js-analysis-process-chart');
    const resultEL = contentEL.find('.js-analysis-result-chart');

    // nvd3はタブ裏で描画できないのでタブ表示タイミングで描画する
    if (!gptEL.data('rendered')) {
      (new GptChart({ el: gptEL })).render();
      gptEL.data('rendered', true);
    }
    if (!processEL.data('rendered')) {
      (new ProcessChart({ el: processEL })).render();
      processEL.data('rendered', true);
    }
    if (!resultEL.data('rendered')) {
      (new ResultChart({ el: resultEL })).render();
      resultEL.data('rendered', true);
    }

    // ツールチップ内容の差し替え
    this.ui.tooltip.attr('data-original-title', contentEL.find('.js-analysis-tooltip-body').html());
  }
});
