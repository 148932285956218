import $         from 'jquery';
import _         from 'underscore';
import { View }  from 'backbone.marionette';
import Mixins    from '../../../mixins';
import Utils     from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.lr.reviews.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  ui: {
    kartableBy: '#kartable_by'
  },

  events: {
    'change @ui.kartableBy': 'reload'
  },

  onRender() {
    this.initDataTables({
      columns   : this.columns(),
      order     : [[0, 'asc']],
      pageLength: 10, // 学生ベースなので少なめにする(成績が各20個でも1ページ200件)
      language  : _.extend(
        I18n.t('frontend.utils.datatables.language'),
        this.t('.language') // 言語を学生ベースにする
      ),
      rowGroup: {
        dataSrc    : 'group',
        startRender: (rows, path) => {
          const displayCode = rows.data().pluck('display_code')[0];
          const info = rows.data().pluck('info')[0];
          const link = $('<a>').html(displayCode)
                               .attr('href', path)
                               .attr('target', path);
          const group = `[${link.prop('outerHTML')}] ${info}`;

          return this.renderGroup(group, rows);
        }
      }
    });

    this.dataTable.on('draw.dt.children', _.bind(this.drawChildren, this));
  },

  // child 枠を利用して has_many 構造であるカルテデータを行の見た目で展開する
  drawChildren() {
    const childRenders = this.getChildRenders();

    // 全ての学生行に適用する
    _.each(this.dataTable.rows()[0], (index) => {
      const row = this.dataTable.row(index);
      const $tmp = $('<div>');

      // カルテデータの件数分生成する
      _.each(row.data().lr_reviews, (lrReview) => {
        const $tr = $('<tr>');
        _.each(childRenders, (childRender, i) => {
          // columns の render は child 枠には効かないため手動で適用する
          const cell = childRender.call(this, lrReview[i], lrReview);
          $tr.append($('<td>').append(cell));
        });
        $tmp.append($tr);
      });

      // 描画
      row.child($tmp.children()).show();
    });
  },

  getChildRenders() {
    // スマホサイズでのレイアウトが崩れないように空白文字入れ
    const defaultRender = (value) => { return value || '　'; };

    return _.chain(this.columns())
      .filter((column) => { return column.data === null; })
      .map((column) => { return column.render || defaultRender; })
      .value();
  }
}));
