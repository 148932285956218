import $ from 'jquery';
import { View } from 'backbone.marionette';
import textCounter from '../../../../utils/text_counter';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    textCounter($('textarea'), 2000);
  }
});
