import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View           from '../../views/student/report_text';


const Controller = RestController.extend({
  edit() {
    (new View.Edit()).render();
  },
  show() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/students/:student_id/reports/:id/text/:report_text_id/edit': 'edit',
    ':tenant/students/:student_id/reports/:id/text/:report_text_id'     : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
