import { View } from 'backbone.marionette';
import $ from 'jquery';
import _ from 'underscore';
import RunnerView from './runner';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.analysis.analyses.index'),

  ui: {
    searchForm   : '#js-analyses-form form',
    resetButton  : '.js-reset-btn',
    selectpick   : '#js-analyses-form select',
    analysesArea : '#js-analyses-area',
    analysisPanel: '.js-analysis-panel'
  },

  events: {
    'submit @ui.searchForm': 'onSearch',
    'click @ui.resetButton': 'onReset'
  },

  onRender() {
    this.runnerView = (new RunnerView()).render();
    this.ui.selectpick.selectpicker();
  },

  onSearch(e) {
    e.preventDefault();
    const url = this.ui.analysesArea.data('url');
    const data = Utils.Form.serializeObject(this.ui.searchForm);

    if (!data['q[curriculum_year_in][]'] || !data['q[belong_id_in][]']) {
      Utils.Modal.info(this.t('.not_specified'));
      return;
    }

    this.ui.analysesArea.block();
    $.ajax({ url, data })
     .done((response) => {
       this.ui.analysesArea.html(response.html);
       this.bindUIElements();
       _(this.ui.analysisPanel).each((el) => {
         (new this.AnalysisPanel({ el: el })).render();
       });
     })
     .always(() => {
       this.ui.analysesArea.unblock();
     });
  },

  onReset(e) {
    e.preventDefault();
    this.ui.searchForm.get(0).reset();
    this.ui.selectpick.selectpicker('refresh');
    this.ui.analysesArea.html('');
  }
});
