import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/student/target_comment';

const Controller = RestController.extend({
  new(tenant, userCode, targetableType, targetableID) {
    (new View.New({ targetableType, targetableID })).render();
  }
});

const baseUrl = ':tenant/students/:student_id/targets/:targetable_type/:targetable_id';
const routes = {};
routes[`${baseUrl}/target_comments/new`] = 'new';

export default AppRouter.extend({
  appRoutes: routes,

  initialize() {
    this.controller = new Controller();
  }
});
