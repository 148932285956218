import { View } from 'backbone.marionette';
import ProcessChart from '../analyses/process_chart';
import ResultChart from '../analyses/result_chart';
import Utils from '../../../../utils';

export default View.extend({
  template: false,

  ui: {
    tooltip     : '.js-analysis-tooltip',
    tooltipBody : '.js-analysis-tooltip-body',
    processChart: '.js-analysis-process-chart',
    resultChart : '.js-analysis-result-chart'
  },

  onRender() {
    this.ui.tooltip.tooltip({
      title    : this.ui.tooltipBody.html(),
      placement: 'left',
      html     : true,
      whiteList: Utils.TooltipWhiteList.setTable({ td: ['colspan'] })
    });
    (new ProcessChart({ el: this.ui.processChart })).render();
    (new ResultChart({ el: this.ui.resultChart })).render();
  }
});
