// このモジュールを使うと .js-unload-alert クラスを持つ form 要素を
// 編集後に保存しないまま画面遷移しようとすると警告を出すようにする
// ただし type = submit 属性を持つボタン・リンクで遷移する場合は警告しない

import $ from 'jquery';
import _ from 'underscore';

export default {
  eventName: 'beforeunload.unload-alert',

  ready() {
    const $form = $('form.js-unload-alert');
    if (!$form.get(0)) return;

    // 編集したら警告を出す
    $form.on('change', (event) => {
      // イベントが .js-unload-alert-disable クラスを持つ要素で発生した場合、このイベントを無視する
      if ($(event.target).hasClass('js-unload-alert-disable')) {
        return;
      }

      _.bind(this.assign, this)(event);
    });

    // validateエラー時は最初から警告を出すようにする
    if ($form.is('.js-validated')) this.assign();

    // submit の場合は警告を出さない
    $('[type=submit]').on('click', _.bind(this.unassign, this));
  },

  // 複数のイベントが登録されないように作り直す形で設定する
  assign() {
    $(window).off(this.eventName);
    $(window).on(this.eventName, () => {
      return I18n.t('frontend.utils.unload_alert.message');
    });
  },

  unassign() {
    $(window).off(this.eventName);
  }
};
