import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/document';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {},

  edit() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/documents'         : 'index',
    ':tenant/manage/documents/new'     : 'new',
    ':tenant/manage/documents/:id/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
