import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/authority_children';

const Controller = RestController.extend({
  edit() {
    (new View.Edit()).render();
  },

  update() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/authority_children/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
