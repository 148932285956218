import tooltipTemplate from '../../../../templates/mentor/analyses/tooltip.pug';
import ScatterChart from './scatter_chart.js';

export default ScatterChart.extend({
  el: '#js-raty-gp-chart',

  initAxis(chart, maxScale) {
    chart.xAxis.options({
      axisLabel : this.t('.raty'),
      tickValues: this.buildTickValues(maxScale.x)
    });
    chart.yAxis.options({
      axisLabel : this.t('.gp'),
      tickValues: this.buildTickValues(maxScale.y)
    });
  },

  initClickEvent(chart) {
    chart.scatter.dispatch.on('elementClick', (e) => {
      const no = e.series.key.replace('No.', '');
      const raty = e.point.x;
      const gp = e.point.y;
      this.trigger('elementClick', { no: no, raty: raty, gp: gp });
    });
  },

  initTooltip(chart) {
    chart.tooltip.contentGenerator((obj) => {
      return tooltipTemplate({
        x    : `${this.t('.raty')} : ${obj.point.x}`,
        y    : `${this.t('.gp')} : ${obj.point.y}`,
        size : `${this.t('.size')} : ${obj.point.size}`,
        color: obj.point.color,
        key  : obj.series[0].key
      });
    });
  }
});
