import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.documents.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      columns : this.columns(),
      order   : [[0, 'asc'], [2, 'asc']],
      rowGroup: {
        dataSrc: 'section'
      }
    });
  },

  columns() {
    return [
      {
        class  : 'section',
        data   : 'section',
        visible: false
      },
      {
        class: 'name',
        data : 'name'
      },
      {
        class: 'sort_no',
        data : 'sort_no',
        width: 45
      },
      {
        class   : 'count',
        data    : 'count',
        width   : 75,
        sortable: false
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (val, _type, row) => {
          if (row.count !== '0') return '';

          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
