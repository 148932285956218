// Jquery.autosizeの適用
// テキストエリアは入力に応じて自動的に高さを変える
import $ from 'jquery';
import autosize from 'autosize';

export default {
  ready() {
    autosize($('textarea'));

    // タブ切り替え時のレイアウト崩れ防止
    $('ul.js-autosize-tabs').on('shown.bs.tab', () => {
      autosize.update($('textarea'));
    });

    // 初回描画時に正常に拡縮されない場合はあるため
    // 高さを再計算する
    setTimeout(() => {
      autosize.update($('textarea'));
    }, 1000);
  }
};
