import $ from 'jquery';
import _ from 'underscore';
import { View } from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    runner: '#js-runner'
  },

  onRender() {
    this.opener = Utils.Opener.get('CopyTransferDatatable');
    this.refreshOpener();
    this.delayReloadRunnerBy(this.ui.runner.data('status'));
  },

  // JOB待ちまたはJOB実行中ならば5秒後に読み込み
  delayReloadRunnerBy(status) {
    if (status === 'waiting' || status === 'running') {
      setTimeout(_.bind(this.loadRunner, this), 5000);
    }
  },

  loadRunner() {
    const url = this.ui.runner.data('url');

    $.ajax({ url })
     .done((data) => {
       this.ui.runner.html(data.html);
       this.refreshOpener();
       this.delayReloadRunnerBy(data.status);
     });
  },

  refreshOpener() {
    if (this.opener) {
      const $row = this.$el.find('.js-row');
      this.opener.updateRow($row.data('row'));
      this.opener.refreshUntreated($row.data('untreated'));
    }
  }
});
