import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View from '../../views/print/report';

const Controller = RestController.extend({
  show() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/print/:locale/report_templates/:report_template_id/students/:student_id/report': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
