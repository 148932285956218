import { View } from 'backbone.marionette';
import ChartsView from './charts';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    (new ChartsView()).render();
  }
});
