import { CollectionView } from 'backbone.marionette';
import Utils from '../../../../utils';
import Collection from '../../../collections/exam_charts';
import emptyTemplate from '../../../templates/student/exam_charts/empty.pug';
import PanelView from './panel';

export default CollectionView.extend({
  el      : '.js-exam-chart-panels',
  template: false,
  t       : Utils.I18n.bind('views.student.exam_charts.charts'),

  collection        : new Collection(),
  childView         : PanelView,
  childViewContainer: '.panel-body',
  collectionEvents  : {
    sync: 'onSync'
  },

  onRender() {
    this.$el.block();
    this.collection.fetch();
  },

  onSync() {
    this.$el.unblock();
    if (this.collection.length === 0) {
      this.$el.html(emptyTemplate({ message: this.t('.noData') }));
    }
  }
});
