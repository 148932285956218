import { View } from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    row         : '.js-row',
    errorMessage: '.js-error',
    tab         : '.js-tab'
  },

  onRender() {
    this._updateOpener();
  },

  _updateOpener() {
    const opener = Utils.Opener.get('SyllabusLectureDatatable');
    opener.updateRow(this.ui.row.data('row'));
    opener.updateError(this.ui.errorMessage.html(), this.ui.tab.html());
  }
});
