import { View } from 'backbone.marionette';
import $ from 'jquery';
import Utils from '../../../../../utils';

export default View.extend({
  el      : '.js-raty-gpa-table',
  template: false,
  t       : Utils.I18n.bind('views.mentor.analyses.show'),

  ui: {
    targetName : '.js-target-name',
    correlation: '.js-correlation',
    judge      : '.js-judge'
  },

  onRender() {
    const url = this.el.dataset.url;
    $.get(url).done((data) => {
      data[0].values.forEach((correlation) => {
        this.ui.targetName.append(`<td>${correlation.target || '-'}</td>`);
        this.ui.correlation.append(`<td>${correlation.value || '-'}</td>`);
        this.ui.judge.append(`<td>${correlation.judge || '-'}</td>`);
      });
    });
  }
});
