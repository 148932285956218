import BasicView           from '../basics/index';

const View = BasicView.extend({
  _customColumns() {
    return [{
      class: 'fiscal_year',
      data : 'fiscal_year',
      width: 35
    }, {
      class: 'taken_term',
      data : 'taken_term',
      width: 35
    }, {
      class: 'last-updated-at',
      data : 'last_updated_at',
      width: 75
    }, {
      class: 'stage',
      data : 'stage',
      width: 70
    }];
  }
});

export default View;
