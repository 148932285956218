import { View } from 'backbone.marionette';
import jade from 'jade';
import Hammer from 'hammerjs';
import _ from 'underscore';
import Behaviors from '../../../behaviors';
import Record from '../../../models/html';

export default View.extend({
  el      : '.asm-map-area',
  template: jade.compile('!= html'),

  behaviors: [
    Behaviors.Mapbar,
    Behaviors.Accordion,
    Behaviors.StickyTableResizer
  ],

  model      : new Record(),
  modelEvents: {
    change: 'render',
    sync  : 'onSync'
  },

  ui: {
    tooltips: 'div[data-toggle="tooltip"]',
    cell    : '.js-cell'
  },

  events: {
    'dblclick @ui.cell': 'dblclickCell'
  },

  toggleSelectorsByResult: {
    not_taken : '.not_taken',
    rejected  : '.reject',
    registered: '.register',
    passed    : '.pass'
  },

  onRender() {
    this.bindUIElements();
    this.delegateEvents(this.events);
    this.ui.tooltips.tooltip();

    // スマホはDBクリックの代わりに長押しとする
    this.ui.cell.each((_index, el) => {
      const hammer = new Hammer(el);
      hammer.get('press').set({ time: 1000 });
      hammer.on('press', _.bind(this.pressCell, this));
    });
  },

  load(mapId) {
    this.$el.block();
    this.model.fetch({
      url: this.$el.data('url').replace('/:id.json', `/${mapId}.json`)
    });
  },

  onSync() {
    this.$el.unblock();
  },

  editCell(id) {
    const studentId = gon.target_entity_id;
    const url = `/${gon.tenant}/students/${studentId}/subjects/${id}/assessments`;
    location.href = url;
  },

  dblclickCell(e) {
    this.editCell(this.$(e.currentTarget).data('id'));
  },

  // hammerEventは new Hammer に定義した要素ではなく配下の操作された要素が取れるため
  // IDを保持したCellを参照するためには辿る必要がある
  pressCell(hammerEvent) {
    const $el = this.$(hammerEvent.target);
    const $cell = $el.hasClass('.js-cell') ? $el : $el.parents('.js-cell');
    this.editCell($cell.data('id'));
  },

  toggleCellsByResult(name, show) {
    const selector = this.toggleSelectorsByResult[name];
    const $cells = this.ui.cell.find(selector);
    $cells.toggleClass('js-shown-by-result', show);
    this.toggleCells();
  },

  toggleCells() {
    const $shownCell = this.ui.cell.find('.js-shown-by-result');
    $shownCell.fadeIn();
    this.ui.cell.find('.js-map-cell').not($shownCell).fadeOut();
  }
});
