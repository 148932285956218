import { Collection } from 'backbone';
import $ from 'jquery';
import Record from '../models/exam_chart';

export default Collection.extend({
  model: Record,

  url() {
    return $('.js-exam-chart-panels').data('url');
  }
});
