import _ from 'underscore';
import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.mentor.lecture_answerings.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    {
      behaviorClass: Behaviors.DataTableResizer,
      offsetBottom : 40
    },
    Behaviors.RemindNotify
  ],

  events: {
    'click @ui.remindBtn': 'onClickRemindBtn'
  },

  onRender() {
    const options = {
      columns: this.columns(),
      order  : [[0, 'desc']]
    };
    this.initDataTables(_.extend(options, this.dataTableOptions));
  },

  columns() {
    return [{
      class: 'stage',
      data : 'stage',
      width: 70
    }, {
      class: 'inputted-at',
      data : 'inputted_at',
      width: 75
    }, {
      class: 'taken-term',
      data : 'taken_term',
      width: 60
    }, {
      data  : 'display_code',
      class : 'code',
      width : 80,
      render: (val, _type, row) => {
        return Utils.Render.link('fa-external-link', val, row.student_path, '_blank');
      }
    }, {
      data : 'name',
      class: 'name'
    }, {
      data : 'grade',
      class: 'grade text-right',
      width: 35
    }, {
      data : 'belong',
      class: 'belong'
    }, {
      data : 'status',
      class: 'status text-center',
      width: 50
    }];
  }
}));
