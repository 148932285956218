import { View } from 'backbone.marionette';
import d3 from 'd3';
import nv from 'nvd3';
import Utils from '../../../../utils';

export default View.extend({
  template: false,
  t       : Utils.I18n.bind('views.analysis.analyses.process_chart'),

  ui: {
    svg: 'svg'
  },

  onRender() {
    const data = this.$el.data('chart-data');
    if (!data) return;

    nv.addGraph(() => {
      const chart = nv.models.multiBarChart()
                             .noData(this.t('.no_data'))
                             .reduceXTicks(false)
                             .forceY([0, data.max_scale])
                             .showControls(false)
                             .stacked(false)
                             .margin({ top: 0, left: 10, right: 0, bottom: 20 });

      chart.yAxis
           .showMaxMin(false)
           .tickValues([...Array(data.max_scale + 1)].map((_i, scale) => { return scale; }))
           .tickFormat((d) => { return parseInt(d, 10); });

      chart.xAxis
           .showMaxMin(false);

      chart.tooltip
           .contentGenerator(this.generateTooltip);

      // 重ねて表示している灰色の比較対象バーを細くして中央配置にする
      chart.dispatch.on('renderEnd', () => {
        const chartEl = this.$el;
        const others = chartEl.find("rect.nv-bar.positive[style='fill: gray; stroke: gray;']");
        const baseBarWidth = others.attr('width');
        const newBarWidth = baseBarWidth / 5;
        const diffX = (baseBarWidth - newBarWidth) / 2;

        others.attr('width', newBarWidth);
        others.each((_index, el) => {
          const other = chartEl.find(el);
          other.attr('x', parseFloat(other.attr('x')) + diffX);
        });
      });

      d3.select(this.ui.svg.get(0))
        .datum(data.bars)
        .transition()
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  },

  // 本機能は入力値と入力率を無理やり同じチャートに乗せており、ツールチップでは入力率のみ本来の％表示をしたいため
  // 全項目を単純変換する valueFormatter が使えない
  // そのため既存の実装を参考にデザインをそのままに、ツールチップに描画する部分を差し替える
  // https://github.com/novus/nvd3/blob/447cce8c180a92fcabd571cca410760deb3a2c1d/src/tooltip.js#L60
  generateTooltip(d) {
    const table = d3.select(document.createElement('table'));

    table.selectAll('thead')
         .data([d])
         .enter().append('thead')
         .append('tr')
         .append('td')
         .attr('colspan', 3)
         .append('strong')
         .classed('x-value', true)
         .html(d.value);

    const tbodyEnter = table.selectAll('tbody')
                            .data([d])
                            .enter().append('tbody');
    const trowEnter = tbodyEnter.selectAll('tr')
                                .data((p) => { return p.series; })
                                .enter()
                                .append('tr')
                                .classed('highlight', (p) => { return p.highlight; });
    trowEnter.append('td')
             .classed('legend-color-guide', true)
             .append('div')
             .style('background-color', (p) => { return p.color; });
    trowEnter.append('td')
             .classed('key', true)
             .classed('total', (p) => { return !!p.total; })
             .html((p) => { return p.key; });
    trowEnter.append('td')
             .classed('value', true)
             .html(d.data.tooltip); // ここをtooltipキー参照にしてデータ側で出し分ける

    return table.node().outerHTML;
  }
});
