import $ from 'jquery';
import _ from 'underscore';

// 以下の理由により、アイコンを変更する
// 1. フォントパスエラーの影響でルーブリックの星が Fontawesome なので揃える
// 2. glyphicon の星より Fontawesome の星のほうが丸みがあって良い
export default {
  ready() {
    _.extend($.fn.rating.defaults, {
      filledStar : '<i class="fa fa-star"></i>',
      emptyStar  : '<i class="fa fa-star-o"></i>',
      clearButton: '<i class="fa fa-minus-circle"></i>'
    });
  }
};
