import $ from 'jquery';
import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    pdfRegion: '.js-pdf-region'
  },

  onRender() {
    const url = this.ui.pdfRegion.data('showPath');
    const timer = setInterval(() => {
      $.getJSON(url).done(json => {
        this.ui.pdfRegion.html(json.html);
        if (json.status === 'completed') {
          clearInterval(timer);
          this.ui.pdfRegion.unblock();
        }
      });
    }, 3000);
  }
});
