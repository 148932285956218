import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.mentor.spoofings.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc']]
    });
  },

  columns() {
    return [
      {
        class: 'type text-center',
        data : 'type',
        width: 45
      },
      {
        class   : 'display_code',
        data    : 'display_code',
        sortable: false
      },
      {
        class   : 'name',
        data    : 'name',
        sortable: false
      },
      {
        class   : 'usage text-center',
        data    : 'usage',
        sortable: false,
        width   : 55
      },
      {
        class   : 'authority',
        data    : 'authority',
        sortable: false
      },
      {
        class   : 'login text-center',
        data    : 'login_path',
        sortable: false,
        width   : 80,
        render  : (val, type, row) => {
          return Utils.Render.linkTemplate({
            linkClass: 'btn btn-xs btn-danger',
            iconClass: 'fa-share',
            text     : this.t('.login'),
            confirm  : this.t('.confirm.login', { code: row.display_code, name: row.name }),
            method   : 'post',
            href     : val
          });
        }
      }
    ];
  }
}));
