/*
 * RailsのRESTfulRoutingの仕様上、複数の意味を持つURLが存在する
 * その挙動を意識しなくて済むように分岐処理を差し込むクラス
 */
import $ from 'jquery';
import _ from 'underscore';
import { Object as MarionetteObject } from 'backbone.marionette';

export default MarionetteObject.extend({
  ignoreMethods: _.allKeys(new MarionetteObject()).concat(['constructor']),

  initialize() {
    this._branchError();
    this._branchIndex();
    this._branchShow();
  },

  // Railsの例外はURLが変わらずエラー画面だけがレンダリングされている
  // エラー画面が表示されていれば、そのURLのActionは実行しない
  _branchError() {
    // 継承元で宣言した関数のみを対象とする
    const methods = _.difference(_.allKeys(this), this.ignoreMethods);
    const actions = _.filter(methods, (name) => {
      return (name.indexOf('_') !== 0) && _.isFunction(this[name]);
    });

    _.each(actions, (name) => {
      const def = _.bind(this[name], this);
      this[name] = (...args) => {
        if ($('.rambulance-error')[0]) return;
        def(...args);
      };
    });
  },

  // createのvalidateエラー時はindexのURLだがnewがレンダリングされている
  // bodyタグへアクション名を出力していることを前提にcreate時はnew処理を呼ぶ
  _branchIndex() {
    if (!this.index) return;

    const defIndex = _.bind(this.index, this);
    this.index = () => {
      if ($('main').hasClass('create')) {
        if (this.new) this.new();
        return;
      }
      defIndex();
    };
  },

  // updateのvalidateエラー時はshowのURLだがeditがレンダリングされている
  // bodyタグへアクション名を出力していることを前提にupdate時はedit処理を呼ぶ
  _branchShow() {
    if (!this.show) return;

    const defShow  = _.bind(this.show, this);
    this.show = () => {
      if ($('main').hasClass('update')) {
        if (this.edit) this.edit();
        return;
      }
      defShow();
    };
  }
});
