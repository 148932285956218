import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View from '../../views/port/charge_student';

const Controller = RestController.extend({
  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/port/charge_students/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
