import { AppRouter }  from 'backbone.marionette';
import RestController from '../../../../controllers/rest';
import View from '../../../../views/manage/syllabus';

const Controller = RestController.extend({
  new() {
    (new View.Export.Pdf.New()).render();
  },

  show() {
    (new View.Export.Pdf.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/syllabuses/export/pdf/new'         : 'new',
    ':tenant/manage/syllabuses/export/pdf'             : 'show',
    ':tenant/manage/syllabuses/:syllabus_id/export/pdf': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
