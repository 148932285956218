import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/report_template';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {
    (new View.New()).render();
  },

  edit() {
    (new View.Edit()).render();
  },

  show() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/report_templates'                : 'index',
    ':tenant/manage/report_templates/:id/edit'       : 'edit',
    ':tenant/manage/report_templates/:id'            : 'show',
    ':tenant/manage/reports/:report_id/templates/new': 'new',
    ':tenant/manage/reports/:report_id/templates/add': 'new'
  },

  initialize() {
    this.controller = new Controller();
  }
});
