import $ from 'jquery';
import Utils from '../../utils';

export default class Dashboard {
  constructor() {
    this.lang = I18n.t('frontend.extension.introjs.dashboard');
  }

  play() {
    if (this._isMobile()) {
      this._playMobile();
    } else {
      this._playPC();
    }
  }

  _playMobile() {
    this._play([
      [this.lang.welcome],
      [this.lang.header_toggle, '.asm-header-toggle', 'left'],
      [this.lang.open_menu],
      [this.lang.current,      '.asm-menu-item__student-home'],
      [this.lang.input_group,  '.asm-menu-group__input .header'],
      [this.lang.review_group, '.asm-menu-group__result .header', 'top'],
      [this.lang.goodbye,      '.asm-menu-item__assessment']
    ]);
  }

  _playPC() {
    this._play([
      [this.lang.welcome],
      [this.lang.menu,         '.asm-sidebar-menu',            'right'],
      [this.lang.current,      '.asm-menu-item__student-home', 'right'],
      [this.lang.input_group,  '.asm-menu-group__input',       'right'],
      [this.lang.review_group, '.asm-menu-group__result',      'right'],
      [this.lang.to_help,      '.asm-help-button',             'left'],
      [this.lang.goodbye,      '.asm-menu-item__assessment',   'right']
    ]);
  }

  _play(stepArrays) {
    if (!gon.dashboard_intro_shown) {
      this._start(stepArrays);
    }
  }

  _start(stepArrays) {
    this.intro = Utils.Introjs.play(stepArrays);
    this.currentStep = 1;
    this.intro.onafterchange(() => { this._onStep(); });
    this.intro.oncomplete(() => { this._onComplete(); });
    this.intro.onexit(() => { this._onComplete(); });
  }

  _onStep() {
    this.currentStep += 1;
    if (this.currentStep === 3 && this._isMobile()) {
      $('#js-header-toggle').click();
      $('#page').hide();
    }
  }

  _onComplete() {
    $('#page').show();
    $.ajax({ method: 'PUT', data: { intro: { shown: true } } });
  }

  _isMobile() {
    return $('.asm-sidebar-menu').is(':hidden');
  }
}
