import { View } from 'backbone.marionette';
import InputRateAverageView from './input_rate_average';

export default View.extend({
  template: false,

  onRender() {
    (new InputRateAverageView()).render();
  }
});
