import { Application } from 'backbone.marionette';
import Backbone from 'backbone';

import AdminRouter     from './routers/admin';
import AnalysisRouter  from './routers/analysis';
import ConfigRouter    from './routers/config';
import ManageRouter    from './routers/manage';
import PortRouter      from './routers/port';
import MentorRouter    from './routers/mentor';
import StudentRouter   from './routers/student';
import OpenRouter      from './routers/open';
import PrintRouter     from './routers/print';
import SsoRouter       from './routers/sso';
import LrRouter        from './routers/lr';

export default Application.extend({
  onStart() {
    this.adminRouter     = new AdminRouter();
    this.analysisRouter  = new AnalysisRouter();
    this.configRouter    = new ConfigRouter();
    this.manageRouter    = new ManageRouter();
    this.PortRouter      = new PortRouter();
    this.mentorRouter    = new MentorRouter();
    this.studentRouter   = new StudentRouter();
    this.openRouter      = new OpenRouter();
    this.printRouter     = new PrintRouter();
    this.ssoRouter       = new SsoRouter();
    this.lrRouter        = new LrRouter();
    this._startHistory();
  },

  _startHistory() {
    if (Backbone.History.started) return;
    Backbone.history.start({ pushState: true });
  }
});
