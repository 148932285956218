import _ from 'underscore';
import { View } from 'backbone.marionette';
import recordTemplate from '../../../templates/student/assessments/record.pug';
import RatingsView from './ratings';
import RubricsView from './rubrics';

export default View.extend({
  tagName  : 'div',
  className: 'panel panel-default asm-record',
  template : recordTemplate,

  ui: {
    formArea: '.asm-assessment-forms'
  },

  regions: {
    each: '.each-region'
  },

  attributes() {
    return { id: `record-${this.model.get('id')}` };
  },

  onRender() {
    this.$el.find('[data-toggle="tooltip"]').tooltip();

    if (!this.model.get('assessable')) {
      this.ui.formArea.hide();
      return;
    }
    this.showAssessmentsView();
  },

  // 入力状況の更新でフォーム再構成は無駄なので初回のみとする
  showAssessmentsView() {
    if (this.getChildView('each')) return;
    this.showChildView('each', this.buildAssessmentsView());
  },

  buildAssessmentsView() {
    const collection = this.model.get('assessment_items');
    const rubricLabels = this.model.get('rubric_labels');

    if (_.isEmpty(rubricLabels)) {
      return new RatingsView({
        collection: collection
      });
    }

    return new RubricsView({
      labels    : rubricLabels,
      collection: collection
    });
  }
});
