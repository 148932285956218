import { View } from 'backbone.marionette';
import PieChart from './pie_chart';
import BarChart from './bar_chart';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  ui: {
    tabs    : 'ul#schedules',
    firstTab: 'ul#schedules a:first'
  },

  onRender() {
    Utils.Opener.set('LectureSurvey', this);

    this.ui.tabs.on('shown.bs.tab', (e) => {
      const id = e.target.href.substring(e.target.href.indexOf('#'));
      this.$(`.tab-pane${id}`).find('.js-pie-chart').each((_index, el) => {
        (new PieChart({ el: el })).render();
      });
      this.$(`.tab-pane${id}`).find('.js-bar-chart').each((_index, el) => {
        (new BarChart({ el: el })).render();
      });
    });

    this.getActiveTab().tab('show');
  },

  getActiveTab() {
    if (location.hash) {
      const anchorTab = this.ui.tabs.find(location.hash);
      if (anchorTab.length) {
        return  this.ui.tabs.find(location.hash);
      }
    }

    return this.ui.firstTab;
  }
}));
