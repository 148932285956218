import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/mind';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {
    (new View.New()).render();
  },

  edit() {
    (new View.Edit()).render();
  },

  show() {
    (new View.Show()).render();
  },

  import() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/minds'           : 'index',
    ':tenant/manage/minds/new'       : 'new',
    ':tenant/manage/minds/:id/edit'  : 'edit',
    ':tenant/manage/minds/:id'       : 'show',
    ':tenant/manage/minds/:id/import': 'import'
  },

  initialize() {
    this.controller = new Controller();
  }
});
