import { View } from 'backbone.marionette';
import Mixins from '../../../../mixins';
import GenericView from './generics/index';
import GenericCommentView from './generic_comments/index';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  onRender() {
    new GenericView({ el: '.js-generic-panel' }).render();
    new GenericCommentView({ el: '.js-generic-comment-panel' }).render();
  }
}));
