import RadarChart from '../../shared/radar_chart';

export default RadarChart.extend({
  el: '.js-generic-result-chart',

  chartOptions: {
    layout: {
      padding: 0
    },
    scale: {
      ticks: {
        max     : 5,
        min     : 0,
        stepSize: 1
      }
    }
  }
});
