import View from '../../../student/achieves/process_chart';
import Mixins from '../../../../mixins';

export default Mixins.cocktail(View.extend({
  chartConfig: {
    stacked     : true,
    showControls: false,
    duration    : 0
  },

  mixins: [
    Mixins.colorIcons
  ],

  onRender() {
    const data = this.$el.data('chart-data');
    if (!data) return;

    this.renderChart(data);
    this.colorIcons(this.$('.js-axis-ability > i'));
  }
}));
