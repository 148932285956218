import $ from 'jquery';
import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  t: Utils.I18n.bind('views.manage.syllabus_contents.show'),

  ui: {
    copyUrlToClipboard: '.js-copy-url-to-clipboard',
    rubricForm        : 'form.js-rubric-form',
    rubricStatus      : 'select.js-rubric-status',
    rubricEditBtn     : '.js-rubric-edit'
  },

  events: {
    'click @ui.copyUrlToClipboard': 'onClickCopyUrlToClipboard',
    'change @ui.rubricStatus'     : 'onChangeStatus'
  },

  initialize() {
    this._disableHistoryBack();
  },

  onRender() {
    this.ui.rubricStatus.selectpicker();
    this._chnageStatus();
  },

  onClickCopyUrlToClipboard(e) {
    e.preventDefault();
    Utils.Notify.notify('notice', this.t('.copied_url_to_clipboard'));
  },

  onChangeStatus() {
    this.ui.rubricForm.submit();
    this._chnageStatus();
  },

  _chnageStatus() {
    if (this._isRubricStatus()) {
      this.ui.rubricEditBtn.show();
    } else {
      this.ui.rubricEditBtn.hide();
    }
  },

  _isRubricStatus() {
    if (this.ui.rubricStatus.selectpicker('val') === 'rubric_enable') return true;
    return false;
  },

  // シラバス編集後のブラウザバック抑止
  _disableHistoryBack() {
    history.pushState('dummy', null, null);
    $(window).on('popstate', (e) => {
      alert(this.t('.invalid_browser_back')); // eslint-disable-line
      if (!e.originalEvent.state) {
        history.pushState('dummy', null, null);
        return;
      }
    });
  }
}));
