import { View } from 'backbone.marionette';
import d3 from 'd3';
import nv from 'nvd3';

export default View.extend({
  template: false,

  ui: {
    chart: 'svg'
  },

  chartConfig: {
    stacked     : true,
    showControls: false
  },

  onRender() {
    const data = this.$el.data('chart-data');
    if (!data) return;

    const useLegend = data.bars.length <= 10;

    nv.addGraph(() => {
      const chart = nv.models.multiBarChart()
                             .reduceXTicks(false)
                             .options(this.chartConfig)
                             .wrapLabels(true)
                             .useInteractiveGuideline(true)
                             .showLegend(useLegend)
                             .margin({ top: useLegend ? 0 : 20, left: 35, right: 15, bottom: 33 });

      chart.xAxis
           .showMaxMin(false)
           .tickFormat((v) => { return v; });

      d3.select(this.ui.chart.get(0))
        .datum(data.bars)
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }
});
