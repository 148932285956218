import generateRouter from '../generate_router';
import View from '../../views/manage/lr_skill';

const appRoutes = {
  ':tenant/manage/lr_skills'         : 'index',
  ':tenant/manage/lr_skills/new'     : 'new',
  ':tenant/manage/lr_skills/:id/edit': 'edit',
  ':tenant/manage/lr_skills/:id'     : 'show'
};

export default generateRouter(View, appRoutes);
