import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/exam_item';

const Controller =  RestController.extend({
  // インポート失敗時に編集画面に戻らず再投入できるようにshowで編集画面を描画する
  show() {
    (new View.Edit()).render();
  },

  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/exams/:id/items'     : 'show',
    ':tenant/manage/exams/:id/items/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
