import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    datepicker: '.js-datepicker'
  },

  onRender() {
    this.ui.datepicker.datepicker({ language: I18n.locale, autoclose: true });
  }
});
