import FormBasic from './_form_basic';
import FormDetails from './_form_details';
import Tabs from './_tabs';

export default {
  el      : '#page',
  template: false,

  render() {
    new Tabs({ el: this.el }).render();
    new FormBasic({ el: '.js-form-base', settingType: 'base' }).render();
    new FormBasic({ el: '.js-form-dividend', settingType: 'dividend' }).render();
    new FormDetails({ el: '.js-form-details' }).render();
  }
};
