import $ from 'jquery';
import { View } from 'backbone.marionette';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    region: '.js-csv-region'
  },

  regions: {
    viewArea: '@ui.region'
  },

  onRender() {
    this.replaceRegionAsync();
    const intervalID = setInterval(() => {
      this.replaceRegionAsync().then((json) => {
        if (json.status !== 'creating') clearInterval(intervalID);
      });
    }, 1000);
  },

  replaceRegionAsync() {
    const url = this.ui.region.data('path');

    return $.getJSON(url).then((json) => {
      const childView = new View({ el: $('<div>').html(json.html) });
      this.showChildView('viewArea', childView);

      return json;
    });
  }
});
