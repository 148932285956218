import { View } from 'backbone.marionette';
import Mixins from '../../../../mixins';
import PlanView from './plans/index';
import PlanCommentView from './plan_comments/index';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  onRender() {
    new PlanView({ el: '.js-plan-panel' }).render();
    new PlanCommentView({ el: '.js-plan-comment-panel' }).render();
  }
}));
