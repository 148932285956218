import { View }       from 'backbone.marionette';
import Behaviors      from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.CommentPhrase
  ],

  ui: {
    showArea    : '.js-show-area',
    deleteButton: '.js-delete-button'
  },

  events: {
    'ajax:success @ui.deleteButton': 'onAjaxSuccessDelete'
  },

  onAjaxSuccessDelete() {
    this.ui.showArea.html('');
  }
});
