import _ from 'underscore';
import { View } from 'backbone.marionette';
import Sortable from 'sortablejs';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.SortableRubric,
    Behaviors.LocaleSwitch
  ],

  ui: {
    node      : '.js-node',
    nodeList  : '.js-node-list',
    nodeInsert: '.js-rubric-node-insert',
    rubricNode: '.js-rubric-node'
  },

  events: {
    'cocoon:after-insert @ui.node'      : 'onAfterInsertNode',
    'cocoon:after-remove @ui.nodeList'  : 'onAfterRemoveNode',
    'cocoon:after-insert @ui.nodeInsert': 'onAfterInsert'
  },

  initialize() {
    this.bindUIElements();
    this._setNos();
    this._applySortable();
  },

  onAfterInsertNode() {
    this.render();
    this._setNos();
  },

  onAfterRemoveNode() {
    this._setNos();
  },

  onAfterInsert() {
    this.bindUIElements();
  },

  _setNos() {
    this._setSerialNo();
    this._setItemNo();
  },

  // 設問とサブタイトルはそれぞれでナンバリングする
  _setItemNo() {
    _.each(['question', 'subtitle'], (name) => {
      const $rows = this.ui.nodeList.find(`tr.js-${name}-fields:visible`);

      _($rows).each((row, i) => {
        const $row = this.$(row);
        $row.find('.js-hidden-no').val(i + 1);
        $row.find('.js-no').html(`${i + 1}`);
      });
    });
  },

  _setSerialNo() {
    const $rows = this.ui.nodeList.find('tr.nested-fields:visible');

    _($rows).each((row, i) => {
      const $row = this.$(row);
      $row.find('.js-hidden-serial-no').val(i + 1);
    });
  },

  _applySortable() {
    if (this.ui.nodeList.length > 0) {
      Sortable.create(this.ui.nodeList.get(0), {
        handle  : 'th.no',
        onUpdate: () => {
          this._setNos();
        }
      });
    }
  }
});
