import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.step_schedules.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc'], [1, 'desc']]
    });
  },

  columns() {
    return [
      {
        class: 'fiscal_year',
        data : 'fiscal_year',
        width: 100
      },
      {
        class: 'taken_term',
        data : 'taken_term',
        width: 100
      },
      {
        class: 'start_at',
        data : 'start_at'
      },
      {
        class: 'end_at',
        data : 'end_at'
      },
      {
        class   : 'in_time text-center',
        data    : 'in_time',
        sortable: false,
        width   : 100
      },
      {
        class   : 'records_count',
        data    : 'records_count',
        sortable: false,
        width   : 120
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 50,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (val, _type, row) => {
          if (row.records_count !== '0') return '';
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
