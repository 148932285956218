import { View } from 'backbone.marionette';
import AttachmentView from '../../shared/report/attachment';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    (new AttachmentView()).render();
  }
});
