import { View } from 'backbone.marionette';
import ShowRating  from '../../_layouts/show_rating';
import Behaviors from '../../../behaviors';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.CommentPhrase
  ],

  ui: {
    ratingFields: '.js-rating-field'
  },

  onRender() {
    this.buildRating();
    Utils.Opener.set('MindAnswerComments', this);
  },

  buildRating() {
    this.ui.ratingFields.each((_index, el) => {
      (new ShowRating({ el: `#${el.id}` })).render();
    });
  }
});
