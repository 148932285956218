import { View } from 'backbone.marionette';
import _ from 'underscore';
import d3 from 'd3';
import ResultChart from '../mind_charts/result_chart';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';

export default Mixins.cocktail(View.extend({
  el      : '.js-mind-result-area',
  template: false,

  onRender() {
    const url = this.$el.data('url');
    if (!url) return;

    this.$el.block();
    d3.json(url, (error, data) => {
      this.$el.unblock();

      if (error !== null) Utils.Modal.alert(error);
      if (_(data).isEmpty()) return;

      this.$el.html(data.html);
      (new ResultChart()).render();
    });
  }
}));
