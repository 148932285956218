import { View } from 'backbone.marionette';
import $  from 'jquery';
import d3 from 'd3';
import nv from 'nvd3';
import Utils from '../../../../../utils';

export default View.extend({
  template: false,
  t       : Utils.I18n.bind('views.mentor.analyses.show'),

  ui: {
    chart: 'svg'
  },

  chartConfig: {
    pointRange: [100, 3000], // point の見かけ上の最小サイズと最大サイズ
    color     : d3.scale.category20().range()
  },

  onRender() {
    const url = this.ui.chart.data('url');
    this.$el.block();
    $.get(url)
      .done((response) => { this.renderChart(response); })
      .always(() => { this.$el.unblock(); });
  },

  renderChart(response) {
    nv.addGraph(() => {
      this.changeMaxScale(response.max_scale);

      const chart = nv.models.scatterChart()
        .options(this.chartConfig)
        .noData(this.t('.noData'));

      this.initAxis(chart, response.max_scale);
      this.initClickEvent(chart);
      this.initTooltip(chart);

      // バブル配慮で実際の範囲より大きめに描画しているので最大最小値は非表示
      chart.yAxis.showMaxMin(false);
      chart.xAxis.showMaxMin(false);

      d3.select(this.ui.chart.get(0))
        .datum(response.data)
        .call(chart);
      nv.utils.windowResize(chart.update);
      return chart;
    });
  },

  // データに応じて目盛りの最大値を変更する
  // バブルが大きくなった時のことを考慮して余裕をもたせる
  changeMaxScale(maxScale) {
    this.chartConfig.forceX =  [0, maxScale.x + 0.5];
    this.chartConfig.forceY =  [0, maxScale.y + 0.5];
  },

  // 0 ~ maxScale までの軸値の配列
  // ただしGPA最大値が4.5の際には[0,1,2,3,4,4.5]となるべきなので単純にRange整数群ではなく最後に自身を加える
  buildTickValues(scale) {
    return [...Array(Math.ceil(scale)).keys(), scale];
  }
});
