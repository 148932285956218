// Cell上に input[type='checkbox'] を描画していることが前提
// checkbox のスタイルをフォントアイコンに変更する
import { Behavior } from 'backbone.marionette';
import Utils from '../../utils';

export default Behavior.extend({
  ui: {
    dataTables: '.js-dataTables'
  },

  onRender() {
    // Cell上のチェックボックスなのでロードの度にイベントを貼り直す必要がある
    this.view.dataTable.on('draw.dt.bindLoad', this.bindLoad);
  },

  bindLoad(e) {
    Utils.Checkbox.bind(e.target);
  }
});
