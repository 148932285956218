import { AppRouter }  from 'backbone.marionette';
import RestController from '../controllers/rest';
import View from '../views/sso';

const Controller = RestController.extend({
  logout() {
    (new View.Logout()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/sso/logout': 'logout'
  },

  initialize() {
    this.controller = new Controller();
  }
});
