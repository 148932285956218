import { View } from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  events: {
    'click .js-remove-attachment' : 'onClickRemoveAttachment',
    'click .js-recover-attachment': 'onClickRecoverAttachment'
  },

  ui: {
    unloadAlert      : '.js-unload-alert',
    uploadField      : '.js-upload',
    attachment       : '.js-attachment',
    attachmentImage  : '.js-attachment-image',
    removeAttachment : '.js-remove-attachment',
    recoverAttachment: '.js-recover-attachment',
    fileDestroy      : '.js-file-destroy'
  },

  onRender() {
    this.bindFileInput();
  },

  bindFileInput() {
    Utils.FileInput.bind(this.ui.uploadField, {
      allowedFileTypes: ['image'],
      showUpload      : false,
      maxSizeMB       : this.ui.uploadField.data('maxSizeMb'),
      elErrorContainer: '.fileinput-error-container'
    });
  },

  onClickRemoveAttachment() {
    this.ui.removeAttachment.addClass('hide');
    this.ui.recoverAttachment.removeClass('hide');
    this.ui.attachment.css({ 'text-decoration': 'line-through' });
    this.ui.attachmentImage.hide();
    this.ui.fileDestroy.val(true);
    // hidden 属性を変更しただけでは form を変更したことにならないため
    // 強制的に change イベントを実行する。
    this.ui.unloadAlert.trigger('change');
  },

  onClickRecoverAttachment() {
    this.ui.removeAttachment.removeClass('hide');
    this.ui.recoverAttachment.addClass('hide');
    this.ui.attachment.css({ 'text-decoration': 'none' });
    this.ui.attachmentImage.show();
    this.ui.fileDestroy.removeAttr('value');
  }
});
