import _ from 'underscore';
import d3 from 'd3';
import nv from 'nvd3';
import { View } from 'backbone.marionette';
import Utils from '../../../utils';

export default View.extend({
  ui: {
    svg: 'svg'
  },

  onRender() {
    const url = this.ui.svg.data('url');
    if (!url) return;

    d3.json(url, (error, data) => {
      if (error !== null) Utils.Modal.alert(error);
      if (_(data).isEmpty()) return;

      this.renderChart(data);
    });
  },

  renderChart(data) {
    nv.addGraph(() => {
      const chart = nv.models.multiBarChart()
                             .reduceXTicks(false)
                             .forceY([0, data.max_scale])
                             .showControls(false)
                             .stacked(false)
                             .margin({ top: 0, left: 15, right: 10, bottom: 20 });

      chart.yAxis
           .showMaxMin(false)
           .tickValues([...Array(data.max_scale + 1)].map((_i, scale) => { return scale; }))
           .tickFormat((d) => { return parseInt(d, 10); });

      chart.xAxis
           .showMaxMin(false);

      chart.tooltip.valueFormatter((d) => { return Math.round(d * 100) / 100; });

      // 重ねて表示している灰色の比較対象バーを細くして中央配置にする
      chart.dispatch.on('renderEnd', () => {
        const chartEl = this.$el;
        const others = chartEl.find("rect.nv-bar.positive[style='fill: gray; stroke: gray;']");
        const baseBarWidth = others.attr('width');
        const newBarWidth = baseBarWidth / 10;
        const diffX = (baseBarWidth - newBarWidth) / 2;

        others.attr('width', newBarWidth);
        others.each((_index, el) => {
          const other = chartEl.find(el);
          other.attr('x', parseFloat(other.attr('x')) + diffX);
        });
      });

      d3.select(this.ui.svg.get(0))
        .datum(data.bars)
        .transition()
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }
});
