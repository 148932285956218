import $ from 'jquery';
import { View } from 'backbone.marionette';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import Mixins from '../../../mixins';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.syllabus_schedules.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer
  ],

  ui: {
    select2      : '.js-select2',
    editAll      : '.js-edit-all',
    submitExpired: '.js-submit-expired',
    fiscalYear   : '.js-fiscal_year'
  },

  events: {
    'click @ui.editAll'      : 'onClickEditAll',
    'click @ui.submitExpired': 'onClickSubmitExpired'
  },

  onRender() {
    Utils.Select2.ajax(this.ui.select2, { allowClear: true });
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc'], [2, 'asc'], [1, 'asc']]
    });
  },

  onReset() {
    this.ui.select2.val(null).trigger('change');
  },

  onClickEditAll(e) {
    e.preventDefault();

    const _this = this;
    this.reload(() => {
      if (_this.ui.fiscalYear.val() === '') {
        Utils.Modal.alert(this.t('.alert.required_fiscal_year'));
        return;
      }

      if (this.dataTable.data().count() === 0) {
        Utils.Modal.alert(this.t('.alert.no_syllabus'));
        return;
      }

      const $button = this.$(e.currentTarget);
      const url = Utils.Url.buildUrl($button.data('path'), this.getSearchParams());

      window.location.href = url;
    });
  },

  onClickSubmitExpired(e) {
    e.preventDefault();

    const $button = this.$(e.currentTarget);
    this.reload(() => {
      const url = $button.data('url');

      $.ajax({ url })
        .then(({ total_count: count }) => {
          if (count === 0) {
            Utils.Modal.alert(this.t('.alert.no_syllabus'));
            return;
          }

          Utils.Modal.confirm(
            this.t('.confirm.submit_expired', { count }),
            (flg) => {
              if (!flg) return;

              $.ajax({
                url,
                method: 'PATCH'
              })
                .then(this.reload.bind(this));
            }
          );
        });
    });
  },

  columns() {
    return [
      {
        class: 'fiscal_year',
        data : 'fiscal_year',
        width: 80
      },
      {
        class: 'open_terms',
        data : 'open_terms',
        width: 80
      },
      {
        class: 'subject',
        data : 'subject',
        width: 200
      },
      {
        class   : 'variable_code',
        data    : 'variable_code',
        sortable: false,
        width   : 80
      },
      {
        class   : 'editors',
        data    : 'editors',
        sortable: false
      },
      {
        class   : 'instructors',
        data    : 'instructors',
        sortable: false
      },
      {
        class: 'input_start_at',
        data : 'input_start_at',
        width: 40
      },
      {
        class: 'input_end_at',
        data : 'input_end_at',
        width: 40
      },
      {
        class   : 'text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 40,
        render  : val => {
          return Utils.Render.editButton(this, val);
        }
      }
    ];
  }
}));
