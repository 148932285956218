import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/port/past_record_summary';

const Controller = RestController.extend({
  edit() {
    (new View.Edit()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/port/past_record_summaries/edit': 'edit'
  },

  initialize() {
    this.controller = new Controller();
  }
});
