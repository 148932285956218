import { View } from 'backbone.marionette';
import d3 from 'd3';
import nv from 'nvd3';
import Utils from '../../../../utils';

export default View.extend({
  template: false,

  ui: {
    chart: 'svg'
  },

  chartConfig: {
    stacked     : true,
    showControls: false
  },

  onRender() {
    const data = this.ui.chart.data('chart-data');
    this.renderChart(data);
  },

  renderChart(data) {
    // d3.attr('height', num)ではIE11やEdgeで効かないためCSSでsvg自体を調整する
    this.ui.chart.css('height', '250px');

    nv.addGraph(() => {
      const chart = nv.models.multiBarChart()
                             .reduceXTicks(false)
                             .options(this.chartConfig)
                             .forceY([0, 100])
                             .wrapLabels(true)
                             .showLegend(true)
                             .stacked(false)
                             .margin({ top: 40, left: 35, right: 15, bottom: 33 });

      chart.xAxis
           .showMaxMin(false)
           .tickFormat((v) => { return v; });

      chart.yAxis
           .showMaxMin(false)
           .tickValues([0, 25, 50, 75, 100])
           .tickFormat((v) => { return `${v}%`; });

      // ツールチップ上の表示は個別に加工したいので計算上の値ではなくRailsから追加で渡される値を使う
      chart.tooltip.contentGenerator(
        new Utils.Nvd3Tooltip()
          .valueFormatter((d, _i, _p, datum) => { return datum.data.y_name; })
          .toGenerator()
      );

      d3.select(this.ui.chart.get(0))
        .datum(data)
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }
});
