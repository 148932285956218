import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';
import DuplicationBtnComponent from '../../shared/duplication/open_modal_for_input_next_year';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.generics.index'),

  ui: {
    duplication: '.js-duplication'
  },

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    new DuplicationBtnComponent({ el: this.ui.duplication }).render();
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'desc']]
    });
  },

  columns() {
    return [
      {
        class: 'curriculum_year text-right',
        data : 'curriculum_year',
        width: 55
      },
      {
        class: 'entry_term',
        data : 'entry_term',
        width: 55
      },
      {
        class: 'belong',
        data : 'belong'
      },
      {
        class: 'mentor_evaluation',
        data : 'mentor_evaluation',
        width: 65
      },
      {
        class   : 'targets text-right',
        data    : 'responded_targets_count',
        sortable: false,
        width   : 60
      },
      {
        class   : 'details text-center',
        data    : 'show_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.showButton(this, val);
        }
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (val, _type, row) => {
          if (row.responded_targets_count.split('/')[0] !== '0') return '';
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
