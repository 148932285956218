import _ from 'underscore';
import d3 from 'd3';
import nv from 'nvd3';
import { View } from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  template: false,

  ui: {
    svg: 'svg'
  },

  onRender() {
    const chartData = this.$el.data('chart-data');

    if (chartData.type === 'line') {
      this.renderLineChart(chartData);
    } else if (chartData.type === 'bar') {
      this.renderBarChart(chartData);
    }
  },

  renderBarChart(chartData) {
    nv.addGraph(() => {
      const angle = chartData.angle;
      const data = chartData.data;

      const names = _.pluck(data[0].values, 'x');
      const marginRight = this.calculateMarginRight(angle, names);
      const marginBottom = this.calculateMarginBottom(angle, names);

      const chart =
        nv.models.multiBarChart()
                 .reduceXTicks(false)
                 .forceY([0, 100])
                 .showControls(false)
                 .stacked(false)
                 .margin({ top: 0, left: 52, right: marginRight, bottom: marginBottom });

      chart.yAxis
        .showMaxMin(false)
        .tickValues([0, 25, 50, 75, 100])
        .tickFormat((v) => { return `${v}%`; });

      chart.xAxis
        .showMaxMin(false)
        .rotateLabels(angle);

      // ツールチップへの素点表示
      chart.tooltip.contentGenerator(
        new Utils.Nvd3Tooltip()
          .valueFormatter((d, _i, _p, datum) => { return `${d}% (${datum.data.score})`; })
          .toGenerator()
      );

      d3.select(this.ui.svg.get(0))
        .datum(data)
        .transition()
        .duration(500)
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  },

  calculateMarginRight(angle, names) {
    const defaultMargin = 85;
    if (angle > 0) {
      const rightmostLabelLength = names.slice(-1)[0].length;
      const fontSize = 12;
      const maxNameWidth = fontSize * rightmostLabelLength;
      const margin = (Math.cos(Math.PI / (180 / Math.abs(angle))) * maxNameWidth);
      if (margin > defaultMargin) {
        return margin;
      }
    }
    return defaultMargin;
  },

  calculateMarginBottom(angle, names) {
    const defaultMargin = 25;
    if (angle > 0) {
      const fontSize = 12;
      const maxNameLength = Math.max(...names.map(name => { return name.length; }));
      const maxNameWidth = fontSize * maxNameLength;

      const heightToRotateAxis = 11;
      const variableMargin = (fontSize / 2) * (angle / 90);
      const marginFromString = 8;
      const adjustmentMargin = heightToRotateAxis + variableMargin + marginFromString;

      const oppositeLength = (Math.sin(Math.PI / (180 / Math.abs(angle))) * maxNameWidth);
      const margin = oppositeLength + adjustmentMargin;
      if (margin > defaultMargin) {
        return margin;
      }
    }
    return defaultMargin;
  },

  renderLineChart(chartData) {
    const data = chartData.data;
    const values = _.pluck(data, 'values');

    // 試験日名称置換用のデータ
    // { '2016/04/01' => '2016/04/01', '2015/04/01' => '入学時' }
    const xNames =
      _.reduce(_.flatten(values), (hash, v) => {
        return _.extend(hash, { [v.x]: v.x_name });
      }, {});

    // 計算用値: 試験項目毎の試験日リスト
    // [['2016/04/01','2016/07/01'],['2016/04/01','2016/07/01', '2017/04/01']]
    const xValues = _.map(values, (v) => { return _.pluck(v, 'x'); });

    // X軸の一致判定(全体のuniq = 積集合)
    // （ツールチップをx軸単位にする useInteractiveGuideline 設定はx軸の一致が条件）
    const xAxis = _.uniq(_.flatten(xValues));
    const sameX  = _.isEqual(xAxis, _.intersection.apply(null, xValues));

    nv.addGraph(() => {
      const chart = nv.models.lineChart()
                             .forceY([0, 100])
                             .x((d) => { return new Date(d.x); })
                             .margin({ top: 0, left: 52, right: 85, bottom: 50 })
                             .useInteractiveGuideline(sameX);

      chart.yAxis
           .showMaxMin(false)
           .tickValues([0, 25, 50, 75, 100])
           .tickFormat((v) => { return `${v}%`; });
      chart.xAxis
           .showMaxMin(false)
           .rotateLabels(30)
           .fontSize('0.8em')
           .tickValues(_.map(xAxis, (x) => { return new Date(x); }))
           .tickFormat((v) => { return xNames[I18n.l('date.formats.default', v)]; });

      // ツールチップへの素点表示
      // ただしX座標が同じで一括ツールチップを出す場合と、そうでない場合は取得できるデータが異なるため注意すること
      if (sameX) {
        chart.interactiveLayer.tooltip.contentGenerator(
          new Utils.Nvd3Tooltip()
          .headerFormatter((d) => { return xNames[I18n.l('date.formats.default', d)]; })
          .valueFormatter((d, _i, p) => { return `${d}% (${p.data.score})`; })
          .toGenerator()
        );
      } else {
        chart.tooltip.contentGenerator(
          new Utils.Nvd3Tooltip()
          .headerFormatter((d) => { return xNames[I18n.l('date.formats.default', d)]; })
          .valueFormatter((d, _i, _p, datum) => { return `${d}% (${datum.point.score})`; })
          .toGenerator()
        );
      }

      d3.select(this.ui.svg.get(0))
        .datum(data)
        .transition()
        .duration(500)
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }
});
