import { Object as MarionetteObject } from 'backbone.marionette';
import Basic from './search/basic';
import Assessment from './search/assessment';
import Mind from './search/mind';
import Plan from './search/plan';
import Generic from './search/generic';
import Activity from './search/activity';

export default MarionetteObject.extend({
  initialize() {
    this.Basic = new Basic();
    this.Assessment = new Assessment();
    this.Mind = new Mind();
    this.Plan = new Plan();
    this.Generic = new Generic();
    this.Activity = new Activity();
  }
});
