import { Model } from 'backbone';

// 複雑な描画でpug化できない場合用のModel
// rubyでレンダリングした後jsonで通信する
export default Model.extend({
  defaults: {
    id  : null,
    html: ''
  }
});
