import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/authority';

const Controller = RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {},

  edit() {},

  show() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/authorities'         : 'index',
    ':tenant/manage/authorities/new'     : 'new',
    ':tenant/manage/authorities/:id/edit': 'edit',
    ':tenant/manage/authorities/:id'     : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
