import _ from 'underscore';
import { View } from 'backbone.marionette';
import autosize from 'autosize';
import Mixins from '../../../mixins';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    {
      behaviorClass: Behaviors.DataTableResizer,
      offsetBottom : 40
    }
  ],

  ui: {
    form        : 'form',
    formAction  : '.js-action',
    conditionTab: 'a[href="#condition"]',
    directTab   : 'a[href="#direct"]',
    usersTab    : 'a[href="#users"]'
  },

  events: {
    'shown.bs.tab @ui.conditionTab': 'showFormAction',
    'shown.bs.tab @ui.directTab'   : 'showFormAction',
    'shown.bs.tab @ui.usersTab'    : 'shownUsersTab'
  },

  onRender() {
    this.initDataTables({
      columns: this.columns()
    });
  },

  showFormAction() {
    this.ui.formAction.removeClass('hidden');
    autosize.update(this.$('textarea'));
  },

  shownUsersTab() {
    this.ui.formAction.addClass('hidden');
    this.trigger('resizeDataTable');
    this.reload();
  },

  // 現在の入力状況を検索条件として通信データに追加する
  buildParams(data) {
    const lpData = data || {};
    _.each(this.ui.form.serializeArray(), (x) => {
      if (!_.isEmpty(x.value)) lpData[x.name] = x.value;
    });
    return lpData;
  },

  columns() {
    return [
      {
        class: 'code',
        data : 'code',
        width: 100
      },
      {
        class: 'name',
        data : 'name'
      },
      {
        class   : 'informations',
        data    : 'informations',
        sortable: false
      }
    ];
  }
}));
