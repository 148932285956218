import _ from 'underscore';
import BasicView from '../basics/index';
import Utils from '../../../../../utils';

export default BasicView.extend({
  dataTableOptions: { order: [[6, 'desc']] },

  ui: _({}).extend(BasicView.prototype.ui, {
    date        : '.js-datepicker',
    select2     : '.js-select2',
    searchForm  : 'form',
    searchButton: 'button[type="submit"]',
    resetButton : '.js-reset-btn'
  }),

  events: _({}).extend(BasicView.prototype.events, {
    'click @ui.resetButton': 'onClickReset'
  }),

  onRender() {
    BasicView.prototype.onRender.call(this);
    this.ui.date.datepicker({ language: I18n.locale, autoclose: true });
    this.bindSelect2();
  },

  bindSelect2() {
    if (this.ui.select2.data('select2')) this.ui.select2.select2('destroy');

    Utils.Select2.bind(this.ui.select2, {
      width      : '100%',
      placeholder: `<${this.t('.tags')}>`
    });
  },

  onClickReset() {
    this.bindSelect2();
  },

  _basicColumns() {
    return BasicView.prototype._basicColumns.call(this, [
      {
        data : 'display_code',
        width: 80
      }, {
        data : 'name',
        width: 80
      }, {
        data : 'grade',
        width: 30
      }, {
        data: 'belong'
      }, {
        data : 'status',
        width: 30
      }
    ]);
  },

  _customColumns() {
    return [{
      class: 'start_at',
      data : 'start_at',
      width: 55
    }, {
      class: 'end_at',
      data : 'end_at',
      width: 55
    }, {
      class   : 'title_with_tags',
      data    : 'title_with_tags',
      sortable: false
    }, {
      class   : 'body',
      data    : 'body',
      sortable: false
    }];
  },

  _showButtonColumn() {
    return {
      class   : 'details text-center',
      data    : 'show_path',
      sortable: false,
      width   : 60,
      render  : (val) => {
        return Utils.Render.showButton(this, val, val);
      }
    };
  }
});
