import BasicView           from '../basics/index';

const View = BasicView.extend({
  _customColumns() {
    return [{
      class   : 'schedule',
      data    : 'schedule',
      sortable: false
    }, {
      class: 'last-updated-at',
      data : 'last_updated_at',
      width: 75
    }, {
      class: 'answer-rate',
      data : 'answer_rate',
      width: 70
    }];
  }
});

export default View;
