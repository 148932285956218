import Popup from '../utils/popup';

export default {
  // datatable内のリンク等、ロードが関わるとjsイベントは貼り直しで面倒なので使わない
  // onclickでの利用を前提に外部から利用できるように名前空間を割り当てる
  // --- 例: slim --------------------------------------------------------------
  // = link_to(name, path, onclick: 'Popup.open(this);return false;')
  // ---------------------------------------------------------------------------
  //
  // またその画面はサイドバー等、余計な画面への遷移が発生しないようにレイアウトに注意すること
  // --- 例: controller --------------------------------------------------------
  // class MemosController < ApplicationController
  //   layout 'plain'
  // end
  // ---------------------------------------------------------------------------
  ready() {
    window.Popup = Popup;
  }
};
