import DataTableDropAllow   from './behaviors/datatable_drop_allow';
import DataTableResizer     from './behaviors/datatable_resizer';
import DataTableRemoveRows  from './behaviors/datatable_remove_rows';
import DataTableDeleteRow   from './behaviors/datatable_delete_row';
import DataTableCheckboxRow from './behaviors/datatable_checkbox_row';
import DataTableTooltipCell from './behaviors/datatable_tooltip_cell';
import RemindNotifyMessage  from './behaviors/remind_notify_message';
import RemindNotify         from './behaviors/remind_notify';
import LocaleSwitch         from './behaviors/locale_switch';
import Mapbar               from './behaviors/mapbar';
import Accordion            from './behaviors/accordion';
import CommentPhrase        from './behaviors/comment_phrase';
import ScrollToError        from './behaviors/scroll_to_error';
import SortableRubric       from './behaviors/sortable_rubric';
import StickyTableResizer   from './behaviors/sticky_table_resizer';
import SyllabusBasicFieldBuilder   from './behaviors/syllabus_basic_field_builder';

export default {
  DataTableDropAllow,
  DataTableResizer,
  DataTableRemoveRows,
  DataTableDeleteRow,
  DataTableCheckboxRow,
  DataTableTooltipCell,
  RemindNotifyMessage,
  RemindNotify,
  LocaleSwitch,
  Mapbar,
  Accordion,
  CommentPhrase,
  ScrollToError,
  SortableRubric,
  StickyTableResizer,
  SyllabusBasicFieldBuilder
};
