import _ from 'underscore';
import { View } from 'backbone.marionette';
import Sortable from 'sortablejs';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.SortableRubric,
    Behaviors.LocaleSwitch
  ],

  ui: {
    nodeList  : '.js-node-list',
    nodeInsert: '.js-rubric-node-insert'
  },

  events: {
    'cocoon:after-insert @ui.nodeList'  : 'onAfterInsertNode',
    'cocoon:after-remove @ui.nodeList'  : 'onAfterRemoveNode',
    'cocoon:after-insert @ui.nodeInsert': 'onAfterInsert'
  },

  initialize() {
    this.bindUIElements();
    this._setNos();
    this._applySortable();
  },

  onAfterInsertNode() {
    this.bindUIElements();
    this._setNos();
  },

  onAfterRemoveNode() {
    this._setNos();
  },

  onAfterInsert() {
    this.bindUIElements();
  },

  _setNos() {
    _(this.ui.nodeList).each((list) => {
      const $rows = this.$(list).find('> tr.nested-fields:visible');

      _($rows).each((row, i) => {
        const $row = this.$(row);
        $row.find('.js-hidden-no').val(i + 1);
      });
    });
  },

  _applySortable() {
    _(this.ui.nodeList).each((list) => {
      Sortable.create(list, {
        draggable: '.nested-fields',
        handle   : 'th.js-no',
        onUpdate : () => {
          this._setNos();
        }
      });
    });
  }
});
