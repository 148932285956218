import { View } from 'backbone.marionette';
import $ from 'jquery';
import _ from 'underscore';

export default View.extend({
  el      : '#page',
  template: false,

  ui: {
    progressForDuplicationArea: '.js-progress-for-duplication-area'
  },

  onRender() {
    const status = this.ui.progressForDuplicationArea.data('status');
    this.delayReloadDuplicationBy(status);
    this.loadDuplication();
  },

  // JOB待ちまたはJOB実行中ならば5秒後に読み込み
  delayReloadDuplicationBy(status) {
    if (status === 'waiting' || status === 'running') {
      setTimeout(_.bind(this.loadDuplication, this), 5000);
    }
  },

  loadDuplication() {
    const url = this.ui.progressForDuplicationArea.data('url');

    $.ajax({ type: 'GET', url: url, dataType: 'JSON' }).done((data) => {
      this.ui.progressForDuplicationArea.html(data.html);
      this.delayReloadDuplicationBy(data.status);
    });
  }
});
