import { View } from 'backbone.marionette';
import Behaviors from '../../../behaviors';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.CommentPhrase
  ],

  onRender() {
    Utils.Opener.set('LrReviewComments', this);
  }
});
