import $ from 'jquery';
import Window from '../utils/window';

export default {
  ready() {
    // スマホ・タブレットでタブウィンドウを常に最前面へ表示する処理を呼び出す
    $(document).on('click', '.js-open-window', (e) => {
      e.preventDefault();
      Window.open(e.currentTarget.href, e.currentTarget.target);
    });
  }
};
