import { AppRouter }  from 'backbone.marionette';
import RestController from '../../../controllers/rest';
import View from '../../../views/manage/syllabus_lecture';

const Controller = RestController.extend({
  show() {
    (new View.CopiedSync.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/syllabus_lectures/copied_sync/:id': 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
