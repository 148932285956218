import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.lr_skills.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow
  ],

  onRender() {
    this.initDataTables({
      columns: this.columns(),
      order  : [[0, 'asc']]
    });
  },

  columns() {
    return [
      {
        class: 'name',
        data : 'name'
      },
      {
        class   : 'title',
        data    : 'title',
        sortable: false
      },
      {
        class   : 'skill_sets_count text-right',
        data    : 'skill_sets_count',
        sortable: false
      },
      {
        class   : 'answers_count text-right',
        data    : 'answers_count',
        sortable: false
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (val, _type, row) => {
          if (row.skill_sets_count !== '0') return '';
          if (row.answers_count !== '0') return '';
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
