import { CollectionView } from 'backbone.marionette';
import Collection    from '../../../collections/assessments';
import RecordView     from './record';

export default CollectionView.extend({
  el      : '.asm-assessment-list',
  template: false,

  childView       : RecordView,
  collection      : new Collection(),
  collectionEvents: {
    sync: 'onSync'
  },

  onRender() {
    this.$el.block();
    this.collection.fetch();
  },

  onSync() {
    this.$el.unblock();
  }
});
