import { View } from 'backbone.marionette';
import jade from 'jade';
import Record from '../../../models/html';
import ChartView from './gpa_ranking_chart';

export default View.extend({
  el   : '#js-gpa-ranking',
  model: new Record(),

  template: jade.compile('!= html'),

  modelEvents: {
    change: 'render',
    sync  : 'onSync'
  },

  ui: {
    gpaRankingChart: '.js-gpa-ranking-chart'
  },

  load() {
    if (!this.$el[0]) return;
    this.$el.block();
    this.model.fetch({
      url: this.$el.data('url')
    });
  },

  onSync() {
    this.$el.unblock();
    this.bindUIElements();

    this.ui.gpaRankingChart.each((_i, el) => {
      (new ChartView({ el: el })).render();
    });
  },

  // 与えられた html 文字列を tbody に描画する
  // @note html 描画と列削除の操作によるちらつきを抑えるため show, hide を行う
  attachElContent(html) {
    const pbody = this.$el.find('.panel-body');
    pbody.hide();
    pbody.html(html);
    pbody.show();
    return this;
  }
});
