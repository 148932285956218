import generateRouter from '../generate_router';
import View  from '../../views/manage/step_schedule';

const appRoutes = {
  ':tenant/manage/assessment_schedules'           : 'index',
  ':tenant/manage/assessment_schedules/new'       : 'new',
  ':tenant/manage/assessment_schedules/:id(/edit)': 'edit',
  ':tenant/manage/lr_review_schedules'            : 'index',
  ':tenant/manage/lr_review_schedules/new'        : 'new',
  ':tenant/manage/lr_review_schedules/:id(/edit)' : 'edit'
};

export default generateRouter(View, appRoutes);
