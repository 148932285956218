import $ from 'jquery';
import _ from 'underscore';
import { Behavior } from 'backbone.marionette';
import Utils from '../../utils';

export default Behavior.extend({
  // 一画面に複数のDatatableを配置する場合は別イベントとするべくKeyを変えること
  defaults: {
    eventKey    : 'datatable',
    minHeight   : 300,
    offsetBottom: 0,

    // 上部には検索などの部品から画面下限まで表示させるのが基本
    offsetTop: ((_this) => { return _this.$el.find('.dataTables_wrapper').offset().top; })
  },

  initialize(_options, view) {
    // view側からリサイズしたいときはイベントを発行する
    view.on('resizeDataTable', _.bind(this.resizeDataTable, this));
  },

  onRender() {
    this.unbindDataTableResizeHandler();
    this.bindDataTableResizeHandler();
  },

  // ヘッダーの改行で高さが変わる可能性があるので幅の再計算を必ず先に行うこと
  resizeDataTable() {
    this.$el.find('.dataTables_scrollBody').css('height', this.computeDataTableBodyHeight());
    this.view.dataTable.columns.adjust();
  },

  // header,footer,info等、body以外の高さを除外する必要がある
  computeDataTableBodyHeight() {
    const wrap = this.$el.find('.dataTables_wrapper').height() || 0;
    const body = this.$el.find('.dataTables_scrollBody').height() || 0;
    const other = (wrap - body > 0) ? (wrap - body) : 0;
    return this.computeDataTableHeight() - other;
  },

  // datatable枠の高さを計算する
  computeDataTableHeight() {
    let height = $(window).height() - this.options.offsetTop(this) - this.options.offsetBottom;
    if (this.options.minHeight > height) {
      height = this.options.minHeight;
    }
    return height;
  },

  // 画面の幅が変わるようなタイミングでは再調整を行う
  bindDataTableResizeHandler() {
    let timeoutId = null;
    const eventKey = this.options.eventKey;
    const lazyReisze = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(_.bind(this.resizeDataTable, this), 200);
    };

    // datatablesロード後の描画イベント(即応)
    this.view.dataTable.on(`draw.dt.${eventKey}`, () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(_.bind(this.resizeDataTable, this), 0);
    });

    // sidebar開閉処理のイベント
    $(document).on(`shown.bs.collapse.${eventKey}`, 'nav.asm-sidebar', lazyReisze);
    $(document).on(`hidden.bs.collapse.${eventKey}`, 'nav.asm-sidebar', lazyReisze);

    // ブラウザのリサイズ系イベント
    $(window).on(this.eventDataTableResize(), lazyReisze);
  },

  unbindDataTableResizeHandler() {
    const eventKey = this.options.eventKey;
    this.view.dataTable.off(`draw.dt.${eventKey}`);
    $(document).off(`shown.bs.collapse.${eventKey}`, 'nav.asm-sidebar');
    $(document).off(`hidden.bs.collapse.${eventKey}`, 'nav.asm-sidebar');
    $(window).off(this.eventDataTableResize());
  },

  // スマホ・タブレットの場合は画面回転時にリサイズするようにイベントを登録する
  eventDataTableResize() {
    const eventKey = this.options.eventKey;
    const eventName = Utils.Device.isPC() ? 'resize' : 'orientationchange';
    return `${eventName}.dataTableResizer.${eventKey}`;
  }
});
