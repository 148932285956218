import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/mind_target';

const Controller =  RestController.extend({
  edit() {
    (new View.Edit()).render();
  },

  show() {}
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/mind_targets/:id/edit': 'edit',
    ':tenant/manage/mind_targets/:id'     : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
