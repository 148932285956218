import { Object as MarionetteObject } from 'backbone.marionette';
import TermReview from './lr/term_review';
import EachReview from './lr/each_review';
import EachReviewComment from './lr/each_review_comment';
import SkillAnswer from './lr/skill_answer';
import Student     from './lr/student';

export default MarionetteObject.extend({
  initialize() {
    this.TermReview = new TermReview();
    this.EachReview = new EachReview();
    this.EachReviewComment = new EachReviewComment();
    this.SkillAnswer = new SkillAnswer();
    this.Student     = new Student();
  }
});
