import { View } from 'backbone.marionette';
import Utils  from '../../../../utils';

export default View.extend({
  el      : '#logo',
  template: false,

  ui: {
    logoOffRadio   : '.js-logo-used[value=off]',
    logoOnRadio    : '.js-logo-used[value=on]',
    logoOffArea    : '#js-report-template-logo-off',
    logoOnArea     : '#js-report-template-logo-on',
    uploadLogoField: '#js-logo-upload',
    logoBox        : '#js-logo-box'
  },

  events: {
    'change @ui.logoOffRadio'      : 'onChangeLogoRadioBtn',
    'change @ui.logoOnRadio'       : 'onChangeLogoRadioBtn',
    'change @ui.uploadLogoField'   : 'onChangeLogoField',
    'fileclear @ui.uploadLogoField': 'onFileClearLogoField'
  },

  onRender() {
    Utils.FileInput.bind(this.ui.uploadLogoField, {
      allowedFileTypes: ['image'],
      showUpload      : false,
      maxSizeMB       : 1,
      elErrorContainer: '.fileinput-logo-error-container'
    });
  },

  onChangeLogoRadioBtn() {
    this.ui.logoOffArea.toggleClass('hidden', !this.ui.logoOffRadio.prop('checked'));
    this.ui.logoOnArea.toggleClass('hidden', !this.ui.logoOnRadio.prop('checked'));
  },

  onChangeLogoField(e) {
    const file = e.target.files[0];
    const blobUrl = window.URL.createObjectURL(file);
    this.ui.logoBox.attr('src', blobUrl);
  },

  onFileClearLogoField() {
    this.ui.logoBox.attr('src', null);
  }
});
