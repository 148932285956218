import d3 from 'd3';
import nv from 'nvd3';
import { View } from 'backbone.marionette';

export default View.extend({
  template: false,

  ui: {
    svg: 'svg'
  },

  chartConfig: {
    duration: 500
  },

  onRender() {
    const chartData = this.$el.data('chart-data');

    nv.addGraph(() => {
      const chart =
        nv.models.discreteBarChart()
                 .showValues(true)
                 .options(this.chartConfig)
                 .valueFormat((v) => { return `${parseInt(v, 10)}人`; })
                 .margin({ top: 5, left: 30, right: 0, bottom: 15 });

      chart.yAxis
        .showMaxMin(false)
        .tickFormat((v) => { return parseInt(v, 10); });

      chart.xAxis
        .showMaxMin(false);

      d3.select(this.ui.svg.get(0))
        .datum(chartData)
        .transition()
        .call(chart);

      nv.utils.windowResize(chart.update);
      return chart;
    });
  }
});
