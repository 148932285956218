import { View } from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,

  onRender() {
    this._reloadParent();
  },

  _reloadParent() {
    Utils.Opener.get('SyllabusDatatable').reload();
  }
});
