import $ from 'jquery';
import { View } from 'backbone.marionette';
import Mixins from '../../../mixins';
import Utils from '../../../../utils';
import Behaviors from '../../../behaviors';

export default Mixins.cocktail(View.extend({
  el      : '#page',
  template: false,
  t       : Utils.I18n.bind('views.manage.report_templates.index'),

  mixins: [
    Mixins.DataTables
  ],

  behaviors: [
    Behaviors.DataTableResizer,
    Behaviors.DataTableDeleteRow,
    Behaviors.DataTableTooltipCell
  ],

  onRender() {
    this.initDataTables({
      columns : this.columns(),
      rowGroup: {
        dataSrc    : 'group',
        startRender: (rows, group) => {
          const editReportPath = rows.data().pluck('edit_report_path')[0];
          const editReportBtn = Utils.Render.configButton(this, editReportPath);
          const newTemplatePath = rows.data().pluck('new_template_path')[0];
          const newTemplateBtn = Utils.Render.newButton(this, newTemplatePath);
          const groupedWrittenTextsCount = rows.data().pluck('grouped_written_texts_count')[0];

          return $('<tr>')
            .append($('<td>').append(group))
            .append($('<td>').append(editReportBtn))
            .append($('<td>').append(newTemplateBtn))
            .append($('<td>').append(groupedWrittenTextsCount))
            .append($('<td>').attr('colspan', 4));
        }
      }
    });
  },

  columns() {
    return [
      {
        class : 'report',
        data  : 'report',
        render: () => {
          return '';
        }
      },
      {
        class   : 'edit text-center',
        data    : 'edit_report_path',
        sortable: false,
        width   : 55,
        render  : (_val, _type, row) => {
          if (!row.warning) return '';

          return $('<i class="fa fa-warning"></i>')
            .attr('data-toggle', 'tooltip')
            .attr('title', row.warning)
            .attr('style', 'color:orange')
            .prop('outerHTML');
        }
      },
      {
        class  : 'add text-center',
        data   : 'new_template_path',
        visible: false,
        width  : 55
      },
      {
        class   : 'curriculum_year',
        data    : 'curriculum_year',
        sortable: false,
        width   : 130
      },
      {
        class  : 'grouped_written_texts_count',
        data   : 'grouped_written_texts_count',
        visible: false
      },
      {
        class   : 'written_texts_count',
        data    : 'written_texts_count',
        sortable: false,
        width   : 80
      },
      {
        class   : 'status',
        data    : 'status',
        sortable: false,
        width   : 80
      },
      {
        class   : 'previews text-center',
        data    : 'previews_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.showButton(this, val);
        }
      },
      {
        class   : 'edit text-center',
        data    : 'edit_path',
        sortable: false,
        width   : 55,
        render  : (val) => {
          return Utils.Render.editButton(this, val);
        }
      },
      {
        class   : 'destroy text-center',
        data    : 'destroy_path',
        sortable: false,
        width   : 50,
        render  : (val) => {
          return Utils.Render.deleteButton(this, val);
        }
      }
    ];
  }
}));
