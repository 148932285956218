import { View } from 'backbone.marionette';
import Behaviors from '../../../behaviors';

export default View.extend({
  el      : '#page',
  template: false,

  behaviors: [
    Behaviors.LocaleSwitch
  ]
});
