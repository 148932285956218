import { View }  from 'backbone.marionette';
import Utils from '../../../../utils';

export default View.extend({
  el      : '#page',
  template: false,
  ui      : {
    form    : 'form',
    guidance: '.js-guidance',
    belong  : '.js-belong'
  },

  events: {
    'change @ui.guidance': 'onChange',
    'change @ui.belong'  : 'onChange'
  },

  onRender() {
    this.onChange();
    this.ui.guidance.prop('disabled', false);
    this.ui.guidance.selectpicker('refresh');
    this.ui.belong.prop('disabled', false);
    this.ui.belong.selectpicker('refresh');

    // 評価終了時に親画面の評価一覧を再読込する
    Utils.Opener.get('GenericTargetDatatable').reload();
  },

  onChange() {
    const url = this.buildUrl();
    if (window.location.pathname === url) return;

    this.$el.block();
    window.location.href = url;
  },

  buildUrl() {
    let url = this.ui.form.data('url');
    url = url.replace(':belong_id', this.ui.belong.selectpicker('val'));
    url = url.replace(':guidance', this.ui.guidance.selectpicker('val'));
    return url;
  }
});
