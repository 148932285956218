import { View } from 'backbone.marionette';
import Utils  from '../../../../utils';

export default View.extend({
  el      : '#cover',
  template: false,

  ui: {
    coverOffRadio      : '.js-cover-used[value=off]',
    coverOnRadio       : '.js-cover-used[value=on]',
    coverOffArea       : '#js-report-template-cover-off',
    coverOnArea        : '#js-report-template-cover-on',
    uploadCoverField   : '#js-cover-upload',
    infoBox            : '#js-info-box',
    coverBox           : '#js-cover-box',
    coverInfoAlign     : '.js-cover-info-align',
    coverInfoY         : '.js-cover-info-y',
    coverInfoPadding   : '.js-cover-info-padding',
    coverInfoFontSize  : '.js-cover-info-font-size',
    coverInfoRootBelong: '.js-cover-info-root-belong'
  },

  events: {
    'change @ui.coverOffRadio'      : 'onChangeCoverRadioBtn',
    'change @ui.coverOnRadio'       : 'onChangeCoverRadioBtn',
    'change @ui.uploadCoverField'   : 'onChangeCoverField',
    'fileclear @ui.uploadCoverField': 'onFileClearCoverField',
    'change @ui.coverInfoAlign'     : 'reflectCoverInfoAlign',
    'change @ui.coverInfoY'         : 'reflectCoverInfoY',
    'keyup @ui.coverInfoY'          : 'reflectCoverInfoY',
    'change @ui.coverInfoPadding'   : 'reflectCoverInfoPadding',
    'keyup @ui.coverInfoPadding'    : 'reflectCoverInfoPadding',
    'change @ui.coverInfoFontSize'  : 'reflectCoverInfoFontSize',
    'keyup @ui.coverInfoFontSize'   : 'reflectCoverInfoFontSize',
    'change @ui.coverInfoRootBelong': 'reflectCoverInfoRootBelong'
  },

  onRender() {
    Utils.FileInput.bind(this.ui.uploadCoverField, {
      allowedFileTypes: ['image'],
      showUpload      : false,
      maxSizeMB       : 1,
      elErrorContainer: '.fileinput-cover-error-container'
    });
    this._reflectInfoBox();
  },

  onChangeCoverRadioBtn() {
    this.ui.coverOffArea.toggleClass('hidden', !this.ui.coverOffRadio.prop('checked'));
    this.ui.coverOnArea.toggleClass('hidden', !this.ui.coverOnRadio.prop('checked'));
    this._reflectInfoBox();
  },

  onChangeCoverField(e) {
    const file = e.target.files[0];
    const blobUrl = window.URL.createObjectURL(file);
    this.ui.coverBox.attr('src', blobUrl);
  },

  onFileClearCoverField() {
    this.ui.coverBox.attr('src', null);
  },

  reflectCoverInfoAlign() {
    this.ui.infoBox.css({ 'text-align': this.ui.coverInfoAlign.val() });
  },

  reflectCoverInfoY() {
    const y = (isNaN(this.ui.coverInfoY.val())) ? 0 : this.ui.coverInfoY.val();
    this.ui.infoBox.css({ top: `${y}mm` });
  },

  reflectCoverInfoPadding() {
    const padding = (isNaN(this.ui.coverInfoPadding.val())) ? 0 : this.ui.coverInfoPadding.val();
    this.ui.infoBox.css({ 'padding-left': `${padding}mm`, 'padding-right': `${padding}mm` });
  },

  reflectCoverInfoFontSize() {
    const fontSize = (isNaN(this.ui.coverInfoFontSize.val())) ? 0 : this.ui.coverInfoFontSize.val();
    this.ui.infoBox.css({ 'font-size': `${fontSize}pt` });
  },

  reflectCoverInfoRootBelong() {
    this.ui.infoBox
        .find('span.belong.root')
        .removeClass('on')
        .removeClass('off')
        .addClass(this.ui.coverInfoRootBelong.val());
  },

  _reflectInfoBox() {
    this.reflectCoverInfoAlign();
    this.reflectCoverInfoY();
    this.reflectCoverInfoPadding();
    this.reflectCoverInfoFontSize();
    this.reflectCoverInfoRootBelong();
  }
});
