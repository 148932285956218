// Utils.I18n で 「t」関数を定義していることが前提
import linkTemplate from './render/link.pug';
import remoteLinkTemplate from './render/remote_link.pug';
import popupLinkTemplate from './render/popup_link.pug';

export default {
  linkTemplate      : linkTemplate,
  remoteLinkTemplate: remoteLinkTemplate,
  popupLinkTemplate : popupLinkTemplate,

  linkButton(text, url, target, btnClass, iconClass) {
    let linkClass = btnClass;
    if (target && target !== '_self') { linkClass += ' js-open-window'; }
    return linkTemplate({
      linkClass: `btn btn-xs ${linkClass}`,
      iconClass: iconClass,
      text     : text,
      href     : url,
      target   : target
    });
  },

  newButton(view, url, target) {
    return this.linkButton(view.t('.new'), url, target, 'btn-primary', 'fa-plus-circle');
  },

  showButton(view, url, target) {
    return this.linkButton(view.t('.show'), url, target, 'btn-info', 'fa-share');
  },

  editButton(view, url, target) {
    return this.linkButton(view.t('.edit'), url, target, 'btn-primary', 'fa-edit');
  },

  deleteButton(view, url) {
    return remoteLinkTemplate({
      linkClass: 'btn btn-xs btn-danger',
      iconClass: 'fa-times',
      text     : view.t('.destroy'),
      confirm  : view.t('.confirm.delete'),
      method   : 'delete',
      href     : url
    });
  },

  link(icon, text, url, target) {
    let linkClass = '';
    if (target && target !== '_self') { linkClass += 'js-open-window'; }
    return linkTemplate({
      linkClass: linkClass,
      iconClass: icon,
      text     : text,
      href     : url,
      target   : target
    });
  },

  popupButton(view, text, url, target) {
    return popupLinkTemplate({
      linkClass: 'btn btn-xs btn-primary',
      iconClass: 'fa-external-link',
      text     : view.t('.popup'),
      href     : url,
      target   : target
    });
  },

  configButton(view, url, target) {
    let linkClass = 'btn btn-xs btn-primary ';
    if (target && target !== '_self') { linkClass += 'js-open-window'; }
    return linkTemplate({
      linkClass: linkClass,
      iconClass: 'fa-cog',
      text     : view.t('.config'),
      href     : url,
      target   : target
    });
  }
};
