import { View } from 'backbone.marionette';

export default View.extend({
  template: false,
  ui      : {
    selectTemplate: 'select.js-select-syllabus-template',
    download      : '.js-download'
  },
  events: {
    'change @ui.selectTemplate': 'onChangeSelectTemplate'
  },

  onChangeSelectTemplate() {
    const disabled = !this.ui.selectTemplate.val();

    this.ui.download.prop('disabled', disabled);
  }
});
