import { AppRouter }  from 'backbone.marionette';
import RestController from '../../controllers/rest';
import View  from '../../views/manage/syllabus_template';

const Controller =  RestController.extend({
  index() {
    (new View.Index()).render();
  },

  new() {
    (new View.New()).render();
  },

  edit() {
    (new View.Edit()).render();
  },

  show() {
    (new View.Show()).render();
  }
});

export default AppRouter.extend({
  appRoutes: {
    ':tenant/manage/syllabus_templates'         : 'index',
    ':tenant/manage/syllabus_templates/new'     : 'new',
    ':tenant/manage/syllabus_templates/:id/edit': 'edit',
    ':tenant/manage/syllabus_templates/:id'     : 'show'
  },

  initialize() {
    this.controller = new Controller();
  }
});
