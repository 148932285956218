import { Object as MarionetteObject } from 'backbone.marionette';
import Dashboard               from './mentor/dashboard';
import InputRateAverageHistory from './mentor/input_rate_average_history';
import Comment                 from './mentor/comment';
import Spoofing                from  './mentor/spoofing';
import Search                  from './mentor/search';
import Lecture                 from './mentor/lecture';
import LectureAnswering        from './mentor/lecture_answering';
import LectureSurvey           from './mentor/lecture_survey';
import LectureFeedback         from './mentor/lecture_feedback';
import Analysis                from './mentor/analysis';
import GenericTarget           from './mentor/generic_target';
import GenericEvaluation       from './mentor/generic_evaluation';
import GenericEvaluationImport from './mentor/generic_evaluation_import';

export default MarionetteObject.extend({
  initialize() {
    this.Dashboard               = new Dashboard();
    this.InputRateAverageHistory = new InputRateAverageHistory();
    this.Comment                 = new Comment();
    this.Spoofing                = new Spoofing();
    this.Search                  = new Search();
    this.Lecture                 = new Lecture();
    this.LectureAnswering        = new LectureAnswering();
    this.LectureSurvey           = new LectureSurvey();
    this.LectureFeedback         = new LectureFeedback();
    this.Analysis                = new Analysis();
    this.GenericTarget           = new GenericTarget();
    this.GenericEvaluation       = new GenericEvaluation();
    this.GenericEvaluationImport = new GenericEvaluationImport();
  }
});
