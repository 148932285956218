import $ from 'jquery';

const enterKeyCode = 13;

export default {
  ready() {
    /*
     * textarea 以外の input 要素で ENTER キー押下時に form が submit されないようにする。
     * 意図しない submit による問い合わせが頻出したため。
     * なお ENTER キーを有効なままにしたい場合は form 要素に js-enter-key-enabled クラスを追加する。
     */
    const selector = ':input:not(textarea):not([type=submit])';

    $(document).on('keypress', selector, (e) => {
      if (e.keyCode !== enterKeyCode) return;
      if ($(e.currentTarget).closest('.js-enter-key-enabled').length === 1) return;

      e.preventDefault();
    });
  }
};
