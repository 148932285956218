import { View } from 'backbone.marionette';
import ImportCsvsView from './index/import_csvs';
import ExportCsvFormatView from './index/export_csv_format';

export default View.extend({
  el      : '#page',
  template: false,
  ui      : {
    importCsvs     : '.js-import-csvs-region',
    exportCsvFormat: '.js-export-csv-format-region'
  },

  onRender() {
    (new ImportCsvsView({ el: this.ui.importCsvs })).render();
    (new ExportCsvFormatView({ el: this.ui.exportCsvFormat })).render();
  }
});
