import { View } from 'backbone.marionette';
import $  from 'jquery';
import d3 from 'd3';
import nv from 'nvd3';
import Utils from '../../../../../utils';

export default View.extend({
  el      : '.asm-average-and-deviation',
  template: false,
  t       : Utils.I18n.bind('views.mentor.analyses.show'),

  ui: {
    chart: 'svg'
  },

  chartConfig: {
    forceY      : [-2, 7],
    showControls: false,
    showLegend  : false,
    barColor    : d3.scale.category20().range(),
    yErr        : (d) => { return d.yErr || [0, 0]; }
  },

  onRender() {
    const url = this.ui.chart.data('url');
    this.$el.block();
    $.get(url)
      .done((data) => { this.renderChart(data); })
      .done((data) => { this.trigger('load:complete', data); })
      .always(() => { this.$el.unblock(); });
  },

  renderChart(data) {
    // d3.attr('height', num)ではIE11やEdgeで効かないためCSSでsvg自体を調整する
    this.ui.chart.css('height', (data[0].values.length * 40) + 100);

    nv.addGraph(() => {
      const chart = nv.models.multiBarHorizontalChart()
        .options(this.chartConfig)
        .noData(this.t('.noData'));

      this.initAxis(chart);

      d3.select(this.ui.chart.get(0))
        .datum(data)
        .call(chart);
      nv.utils.windowResize(chart.update);
      return chart;
    });
  },

  initAxis(chart) {
    chart.yAxis.options({
      axisLabel : this.t('.raty'),
      showMaxMin: false,
      tickValues: [0, 1, 2, 3, 4, 5],
      tickFormat: d3.format('d')
    });
    chart.xAxis.options({
      axisLabel: this.t('.target')
    });
  }
});
